import {
  MATERIAL_ADDONS_REQUEST,
  MATERIAL_ADDONS_SUCCESS,
  MATERIAL_ADDONS_FAILED,
  LABOR_ADDONS_REQUEST,
  LABOR_ADDONS_SUCCESS,
  LABOR_ADDONS_FAILED,
  DEFAULT_PRODUCT_STRUCTURE_REQUEST,
  DEFAULT_PRODUCT_STRUCTURE_SUCCESS,
  DEFAULT_PRODUCT_STRUCTURE_FAILED,
  KIT_CREATE,
  KIT_CREATE_SUCCESS,
  KIT_CREATE_FAILED,
  KIT_REQUEST,
  KIT_SUCCESS,
  KIT_FAILED,
  KIT_DELETE,
  KIT_DELETE_SUCCESS,
  KIT_DELETE_FAILED,
  KIT_EDIT_REQUEST,
  KIT_EDIT_SUCCESS,
  KIT_EDIT_FAILED,
  KIT_UPDATE_REQUEST,
  KIT_UPDATE_SUCCESS,
  KIT_UPDATE_FAILED,
  KIT_CREATED_REQUEST,
  KIT_CREATED_SUCCESS,
  KIT_CREATED_FAILED
} from "../../_utils/constants/Kits";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../config";
import { SESSION_EXPIRED } from "../../_utils/constants/Auth";

function* fetchMaterialAddons({ token, data, checkFlag }) {
  try {
    let checkflagValue = checkFlag ? "Yes" : "No"
    let subProductValue = data?.subProductTypeId ? data?.subProductTypeId : 0
    const response = yield call(axios.get, `${API_URL}/Product/api/Kit/GetKitMaterialAddOn?ProductStructureId=${data.ProductTypeId ? data.ProductTypeId : 0}&StoreId=${data.StoreId}&PrivateName=${data.PrivateName}&Manufacturer=${data.Manufacturer}&Style=${data.Style}&Color=${data.Color}&SubProductStructureId=${subProductValue}&IsFilter=${checkflagValue}&ProductId=${data.ProductId ? data.ProductId : 0}&KitId=${data.KitId ? data.KitId : 0}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: MATERIAL_ADDONS_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: MATERIAL_ADDONS_FAILED, payload: error.response });
    }
  }
}

function* fetchLaborAddons({ token, data }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Kit/GetKitLaborAddOn?ProductStructureId=${data.ProductTypeId ? data.ProductTypeId : 0}&ProductStructureIdForLabor=0&StoreId=${data.StoreId}${data.LaborCategory ? '&LaborCategoryId=' + data.LaborCategory : ' '}&LaborName=${data.LaborName}&SubProductStructureId=${data?.subProductTypeId ? data?.subProductTypeId : 0}&ProductId=${data.ProductId ? data.ProductId : 0}&KitId=${data.KitId ? data.KitId : 0}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: LABOR_ADDONS_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: LABOR_ADDONS_FAILED, payload: error.response });
    }
  }
}

function* fetchDefaultProductStructure({ token, data }) {

  try {
    const response = yield call(axios.get, `${API_URL}/product/api/ProductType/GetProductType?IsDefault=${data}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: DEFAULT_PRODUCT_STRUCTURE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: DEFAULT_PRODUCT_STRUCTURE_FAILED, payload: error.response });
    }
  }
}
function* fetchKit({ token, data }) {

  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Kit/GetKitWithAddOn?ProductStructureId=${data.ProductTypeId ? data.ProductTypeId : 0}&StoreId=${data.StoreId ? data.StoreId : 0}&SubProductStructureId=${data.subProductTypeId ? data.subProductTypeId : 0}&ProductId=${data.ProductId ? data.ProductId : 0}&KitId=${data.KitId ? data.KitId : 0}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: KIT_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: KIT_FAILED, payload: error.response });
    }
  }
}
function* fetchEditKit({ token, data }) {

  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Kit/GetKitWithAddOn?ProductStructureId=${data.ProductTypeId ? data.ProductTypeId : 0}&StoreId=${data.StoreId ? data.StoreId : 0}&SubProductStructureId=${data.subProductTypeId ? data.subProductTypeId : 0}&ProductId=${data.ProductId ? data.ProductId : 0}&KitId=${data.KitId ? data.KitId : 0}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: KIT_EDIT_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: KIT_EDIT_FAILED, payload: error.response });
    }
  }
}
function* fetchEditCreated({ token, data }) {

  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Kit/GetKitWithAddOn?ProductStructureId=${data.ProductTypeId ? data.ProductTypeId : 0}&StoreId=${data.StoreId ? data.StoreId : 0}&SubProductStructureId=${data.subProductTypeId ? data.subProductTypeId : 0}&ProductId=${data.ProductId ? data.ProductId : 0}&KitId=${data.KitId ? data.KitId : 0}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: KIT_CREATED_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: KIT_CREATED_FAILED, payload: error.response });
    }
  }
}
function* createKit(action) {

  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Kit/CreateKit`,
      action.data.data, {
      headers: {
        Authorization: `Bearer ${action.data.token}`
      },
    }
    );
    yield put({
      type: KIT_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: KIT_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteKite(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/Kit/RemoveKit?KitId=${encodeURIComponent(action.data.KitId)}`, {
      headers: {
        Authorization: `Bearer ${action.data.token}`
      }
    },
    );
    yield put({
      type: KIT_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: KIT_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

function* updateKit(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Product/api/Kit/UpdateKit`,
      action.data.data, {
      headers: {
        Authorization: `Bearer ${action.data.token}`
      },
    }
    );
    yield put({
      type: KIT_UPDATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: KIT_UPDATE_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootKits() {
  yield all([
    takeLatest(MATERIAL_ADDONS_REQUEST, fetchMaterialAddons),
    takeLatest(LABOR_ADDONS_REQUEST, fetchLaborAddons),
    takeLatest(DEFAULT_PRODUCT_STRUCTURE_REQUEST, fetchDefaultProductStructure),
    takeLatest(KIT_CREATE, createKit),
    takeLatest(KIT_REQUEST, fetchKit),
    takeLatest(KIT_EDIT_REQUEST, fetchEditKit),
    takeLatest(KIT_CREATED_REQUEST, fetchEditCreated),
    takeLatest(KIT_DELETE, deleteKite),
    takeLatest(KIT_UPDATE_REQUEST, updateKit)
  ])
}