import React, { useState, useEffect, useMemo, useRef } from "react";
import Title from "../../elements/Title";
import { Icon } from "react-icons-kit";
import { Link, useNavigate } from "react-router-dom";
import { ic_arrow_back } from "react-icons-kit/md";
import { connect } from "react-redux";
import MainWrapper from "../../MainWrapper";
import ExistingOrdersList from "./ExistingOrdersList";
import { STORE_INFO_REQUEST } from "../../../_utils/constants/admin/StoreInfo";
import { CUSTOMER_EXISTING_ORDER_LIST_REQUEST, SAVE_SELECTED_EXISTING_ORDER_REQUEST, PRINT_PDF_REQUEST } from "../../../_utils/constants/Customer";
import CustomButton from "../../elements/CustomButton";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
// import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';;
import { PDFDocument } from "pdf-lib";
import { API_URL } from "../../../config";

const ExistingOrders = ({
    auth: { auth, isAuthenticated },
    customerInfo: { existingOrderList, totalCount, isLoading, savedExistingOrder, printingPdf, isPdfProcessing },
    StoreInfo: { storeInfo },
    fetchExistingOrdersList,
    fetchStoreInfo,
    saveExistingOrdersList,
    generatePdf
}) => {

    const printButtonLabel = 'Print Orders'
    // states declaration
    const [checkFlag, setCheckFlag] = useState(false);
    const showPrintPacket = true;
    const generatePdfByBE = true;
    const [pdfProcessingMsg, setPdfProcessingMsg] = useState("Processing your PDF, please wait...");
    const [checkPrintFlag, setCheckPrintFlag] = useState(false);
    const [selectedOrderedData, setSelectedOrderedData] = useState([]);
    const [savedJobNumber, setSavedJobNumber] = useState([]);
    const [savedCopyJobNumber, setSavedCopyJobNumber] = useState([]);
    const [printBtnLabel, setPrintBtnLabel] = useState(printButtonLabel)
    const [selectAll, setSelectAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedIdObj, setSelectedIdObj] = useState([]);
    // const [copySelectedIds, setCopySelectedIds] = useState([]);
    const [sort, setSort] = useState("desc");
    const [page, setPage] = useState(1);
    const [paginate, setPaginate] = useState(20);
    const [localTime, setLocalTime] = useState();
    const [localTimeZone, setLocalTimeZone] = useState();
    // const [loadPrintIframe, setLoadPrintIframe] = useState();
    const [packetUrl, setPacketUrl] = useState();
    const [listParamState, setListParamState] = useState({
        "sortOn": "",
        "jobNumber": "",
        "salesPerson": "",
        "shipToPropertyName": "",
        "unitName": "",
        "installationDate": "",
        "StoreId": "",
        "isQuality": "",
        "qualityStatus": ""
        // "orderedDate": ""
    })

    const navigate = useNavigate();
    const iframeRef = useRef(null);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login")
        }
    }, [auth, isAuthenticated])

    // function to call store api
    useEffect(() => {
        if (isAuthenticated) {
            fetchStoreInfo(auth[0].token);
            localStorage.removeItem('isQuality')
            localStorage.removeItem('quality-order')
            localStorage.removeItem('quality-JobNum')
            localStorage.removeItem('quality-UnitId')
        }
    }, [auth, isAuthenticated]);

    // function to call existing order list api
    useEffect(() => {
        if (isAuthenticated) {
            fetchExistingOrdersList(auth[0].token, sort, page, paginate, listParamState);
            setSelectAll(false)
        }
    }, [auth, isAuthenticated, sort, page, paginate, listParamState]);

    // handle api response
    useEffect(() => {
        if (savedExistingOrder && savedExistingOrder.length > 0) {
            if (savedExistingOrder[0] === 'success') {
                toast.success('Success')
                fetchExistingOrdersList(auth[0].token, sort, page, paginate, listParamState);
            } else {
                toast.error(savedExistingOrder[0])
            }
        }
    }, [savedExistingOrder])

    // handle timezone calculation
    useEffect(() => {
        const clientTimezoneOffset = new Date().getTimezoneOffset();
        setLocalTime(clientTimezoneOffset);
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setLocalTimeZone(clientTimezone);
    }, [])

    // function called to show updated listing
    const existingOrderListing = useMemo(() => {
        if (existingOrderList && localStorage.getItem('Order-Listing')) {
            let arr = []
            localStorage.removeItem('Order-Listing')
            return arr
        } else {
            return existingOrderList
        }
    }, [existingOrderList])

    // function to handle selection of existing order data
    useMemo(() => {
        if (existingOrderList && existingOrderListing.length > 0) {
            existingOrderList.map(item => {
                if (item.OdooStatus === 'Quotation created successfully.' || item.OdooStatus === 'Sales Order created successfully.') {
                    item.IsChecked = true
                    savedJobNumber.push(item.JobNo)
                    selectedIds.push(item.JobNo)
                } else if (selectedIds.includes(item.JobNo)) {
                    item.IsChecked = true
                } else {
                    item.IsChecked = false
                }
            })
            // temporary setting for selectedIds
            // if(showPrintPacket){ //this is for testing purpose
            //     setSelectedIds(['1002845','1002843', '1002840'])
            //     setSavedJobNumber(['1002845','1002843', '1002840'])
            // }
        }
    }, [existingOrderListing])

    // function called on any filter value change
    const handleFilterChange = (e) => {
        const { id, value } = e.target;
        if (id === 'jobNumber' || id === 'shipToPropertyName' || id === 'unitName' || id === 'installationDate' || id === 'Salesperson' || id === 'StoreId' || id === 'isQuality' || id === 'qualityStatus') {
            // if (id === 'jobNumber' || id === 'shipToPropertyName' || id === 'orderedDate' || id === 'unitName' || id === 'installationDate' || id === 'Salesperson' || id === 'StoreId') {
            if (value.length > 0) {
                setPaginate(200)
            } else {
                setPaginate(20)
            }
        }
        setListParamState({
            ...listParamState,
            [id]: value
        })
    }

    // function to manage store list data fetched
    const storeList = useMemo(() => {
        if (storeInfo)
            return storeInfo.map(v => ({ label: v.storeName, value: v.storeName }))
    }, [storeInfo])

    // function to modify listing on order selection  // commented on 18 feb 2025 , optimized function is given below
    // const handleOrderListing = (isChecked, data) => {
    //  setCheckFlag(isChecked)
    //  let tmpObj = cloneDeep(selectedIdObj);
    //  if (existingOrderListing && existingOrderListing.length > 0) {
    //      existingOrderListing.map(item => {
    //          if (!item.IsChecked && (item.JobNo === data.JobNo)) {
    //              item.IsChecked = isChecked
    //              selectedIds.push(item.JobNo)
    //              tmpObj.push(item)
    //          } else if (item.IsChecked && (item.JobNo === data.JobNo)) {
    //              item.IsChecked = isChecked
    //              const index = selectedIds.indexOf(item.JobNo);
    //              if (index > -1) {
    //                  selectedIds.splice(index, 1);
    //                  tmpObj.splice(index, 1)
    //              }
    //          }
    //      })
    //  }
    //  if (existingOrderList && existingOrderList.length > 0) {
    //      existingOrderList.map(item => {
    //          if (!item.IsChecked && (item.JobNo === data.JobNo)) {
    //              item.IsChecked = isChecked
    //          } else if (item.IsChecked && (item.JobNo === data.JobNo)) {
    //              item.IsChecked = isChecked
    //          }
    //      })
    //  }
    //  let uniqueSelectedIds = _.uniq(selectedIds)
    //  setSelectedIds(uniqueSelectedIds)
    //  setSelectedIdObj(tmpObj)
    // }
    const handleOrderListing = (isChecked, data) => {
        setCheckFlag(isChecked);

        setSelectedIdObj(prevSelectedIdObj => {
            let tmpObj = cloneDeep(prevSelectedIdObj); // Ensure deep copy

            if (existingOrderListing && existingOrderListing.length > 0) {
                existingOrderListing.forEach(item => {
                    if (!item.IsChecked && item.JobNo === data.JobNo) {
                        item.IsChecked = isChecked;
                        tmpObj.push(item);
                    } else if (item.IsChecked && item.JobNo === data.JobNo) {
                        item.IsChecked = isChecked;
                        tmpObj = tmpObj.filter(obj => obj.JobNo !== item.JobNo);
                    }
                });
            }

            return tmpObj; // Update state correctly
        });

        setSelectedIds(prevSelectedIds => {
            let updatedSelectedIds = [...prevSelectedIds];

            if (isChecked) {
                updatedSelectedIds.push(data.JobNo);
            } else {
                updatedSelectedIds = updatedSelectedIds.filter(id => id !== data.JobNo);
            }

            return _.uniq(updatedSelectedIds); // Ensure uniqueness
        });
        if (checkPrintFlag) {
            let tmpLbl = (selectedIds.length > 0) ? 'Click To Process Printing' : 'Select Orders To Print';
            setPrintBtnLabel(tmpLbl)
        }
    };

    // function called on job number selection
    const handleCheckboxClick = (e, data) => {
        handleOrderListing(e.target.checked, data)
        setSelectedOrderedData(data)
    }

    // function to modify listing on order selection
    const handleAllOrderListing = (isChecked) => {
        if (existingOrderListing && existingOrderListing.length > 0) {
            existingOrderListing.map(item => {
                if (!savedJobNumber.includes(item.JobNo)) {
                    if (isChecked) {
                        item.IsChecked = isChecked
                        selectedIds.push(item.JobNo)
                    } else {
                        item.IsChecked = isChecked
                        const index = selectedIds.indexOf(item.JobNo);
                        if (index > -1) {
                            selectedIds.splice(index, 1);
                        }
                    }
                }
            })
        }
        if (existingOrderList && existingOrderList.length > 0) {
            existingOrderList.map(item => {
                if (!savedJobNumber.includes(item.JobNo)) {
                    if (isChecked) {
                        item.IsChecked = isChecked
                    } else {
                        item.IsChecked = isChecked
                    }
                }
            })
        }
        setCheckFlag(isChecked)
        let uniqueSelectedIds = _.uniq(selectedIds)
        setSelectedIds(uniqueSelectedIds)
        if (checkPrintFlag) {
            let tmpLbl = (selectedIds.length > 0) ? 'Click To Process Printing' : 'Select Orders To Print';
            setPrintBtnLabel(tmpLbl)
        }
        setSelectedIdObj(prevSelectedIdObj => {
            let tmpObj = cloneDeep(prevSelectedIdObj); // Ensure deep copy

            if (existingOrderListing && existingOrderListing.length > 0) {
                existingOrderListing.forEach(item => {
                    if (isChecked) {
                        item.IsChecked = isChecked;
                        tmpObj.push(item);
                    } else {
                        item.IsChecked = isChecked;
                        tmpObj = tmpObj.filter(obj => obj.JobNo !== item.JobNo);
                    }
                });
            }

            return tmpObj; // Update state correctly
        });
    }

    // function called to handle checkbox selection
    const handleAllCheckboxSelect = (e) => {
        setSelectAll(e.target.checked)
        handleAllOrderListing(e.target.checked)
    }

    // function called to filter checked data
    const handleFilterCheckedData = () => {
        let tempIds = selectedIds.filter(element => !savedJobNumber.includes(element))
        tempIds = _.uniq(tempIds)
        return tempIds
    }

    // function called for processing orders
    const handleSaveClick = () => {
        let tempIds = handleFilterCheckedData()
        if (tempIds.length > 0) {
            let payload = {
                'JobNo': tempIds,
                'time': localTime,
                'timezone': localTimeZone
            }
            saveExistingOrdersList(auth[0].token, payload)
        } else {
            toast.error('Please select atleast one order to be processed')
        }
    }


    const createPdfBlob = async (pdfUrl) => {
        try {
            const response = await fetch(pdfUrl); // Fetch the PDF file
            const blob = await response.blob(); // Convert to Blob
            return blob;
        } catch (error) {
            console.error("Error creating PDF Blob:", error);
        }
    };

    // const mergePdfBlobs = async (blob1, blob2, ordInfo) => {
    //     try {
    //         // Create a new PDF document
    //         const mergedPdf = await PDFDocument.create();

    //         // Load the first PDF Blob
    //         const pdf1 = await PDFDocument.load(await blob1.arrayBuffer());
    //         const pdf2 = await PDFDocument.load(await blob2.arrayBuffer());

    //         // Copy all pages from first PDF
    //         const copiedPages1 = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
    //         copiedPages1.forEach((page) => mergedPdf.addPage(page));

    //         // Copy all pages from second PDF
    //         const copiedPages2 = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
    //         copiedPages2.forEach((page) => mergedPdf.addPage(page));

    //         // Serialize the merged PDF into a Blob
    //         const mergedPdfBytes = await mergedPdf.save();
    //         const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

    //         // Create a download link
    //         const blobUrl = URL.createObjectURL(mergedBlob);
    //         const link = document.createElement("a");
    //         link.href = blobUrl;
    //         link.download = `order_${ordInfo?.JobNo}_detail.pdf`;
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //         // return mergedBlob;
    //     } catch (error) {
    //         console.error("Error merging PDFs:", error);
    //     }
    // };

    const mergeMultiplePdfBlobs = async (pdfBlobArr) => {
        try {
            if (!pdfBlobArr.length) {
                console.error("No PDFs to merge.");
                return;
            }

            // Create a new PDF document
            const mergedPdf = await PDFDocument.create();

            for (const blob of pdfBlobArr) {
                const pdf = await PDFDocument.load(await blob.arrayBuffer());
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                copiedPages.forEach((page) => mergedPdf.addPage(page));
            }

            // Serialize merged PDF into a Blob
            const mergedPdfBytes = await mergedPdf.save();
            const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

            // Create a download link
            const blobUrl = URL.createObjectURL(mergedBlob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = `order_detail.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error merging PDFs:", error);
        }
    };

    async function downloadPDF(htmlElem, ordInfo, pdfBlobArr, index) {
        const planImage = htmlElem?.querySelector("#floorimage");
        if (planImage) {
            htmlElem.querySelectorAll(".plan-img-container").forEach(element => {
                element.classList.remove("col-md-5");
                element.classList.add("col-md-8")
            });
            planImage.style.width = '500px';
        }
        const quantityPlan = htmlElem?.querySelector(".quantity-plan");
        if (quantityPlan) {
            quantityPlan.style.width = '500px';
        }
        const makeChangeBtn = htmlElem?.querySelector(".make-changes");
        if (makeChangeBtn) {
            makeChangeBtn.style.display = 'none';
        }

        const showPdfTotalAmount = htmlElem?.querySelector(".showPdfTotalAmount");
        if (showPdfTotalAmount) {
            showPdfTotalAmount.querySelector("#orderPdfTotal").style.display = 'block';
            showPdfTotalAmount.querySelector("#orderTotal").style.display = 'none';
        }
        if (htmlElem.querySelector("#store-pdf-logo")) {
            htmlElem.querySelector("#store-pdf-logo").style.display = "block"; // Change display to block
            htmlElem.querySelector(".store_phone").style.display = "block";
        }
        // remove scroll 
        htmlElem.querySelectorAll(".fixTableHead").forEach(element => element.classList.remove("fixTableHead"));

        // Material :: start
        const orderMat = htmlElem?.querySelectorAll(".order-material-pdf .showPdfData input");
        if (orderMat) {
            orderMat.forEach((elem) => {
                elem.value = "";
            });

            let tableMatBody = htmlElem.querySelector(".order-material-pdf tbody");
            if (tableMatBody) {
                let rows = tableMatBody.getElementsByTagName("tr");
                if (rows.length > 0) {
                    rows[rows.length - 1].style.display = "none";
                }
            }
        }

        let tmpMaterial = htmlElem?.querySelectorAll(".hideMaterial input");
        if (tmpMaterial) {
            tmpMaterial.forEach((elem) => {
                elem.value = "";
            });
        }
        // Material :: end
        // Labor :: start
        const orderLabor = htmlElem?.querySelector(".order-labor-pdf");
        if (orderLabor) {
            orderLabor.querySelectorAll('.showPdfData').forEach((elem) => {
                elem.querySelector('input').style.display = "none"; // Hide input if it exists
                elem.querySelector('span').style.display = "block"; // Show span if it exists
            });
            let totalPdfElem = orderLabor.querySelectorAll(".showPdfTotalData"); // ✅ Fixed selector
            if (totalPdfElem) {
                totalPdfElem.forEach((elem) => {
                    let spans = elem.querySelectorAll("span");
                    if (spans && spans.length >= 2) {
                        spans[0].style.display = "none"; // Hide first span
                        spans[1].style.display = "inline"; // Show second span
                    }
                })
            }
        }
        // Labor :: end

        // adons / shortage :: start
        htmlElem?.querySelectorAll('.addon-container .showPdfData').forEach((elem) => {
            elem.querySelector('input').style.display = "none"; // Hide input if it exists
            elem.querySelector('span').style.display = "block"; // Show span if it exists
        });
        htmlElem?.querySelectorAll('.addon-container .showPdfTotalData').forEach((elem) => {
            let strongs = elem.querySelectorAll("strong");
            if (strongs && strongs.length >= 2) {
                strongs[0].style.display = "none"; // Hide first span
                strongs[1].style.display = "inline"; // Show second span
            }
        })
        // adons / shortage :: end

        // const orderMat = htmlElem?.querySelectorAll(".order-detail");
        // if (orderDetails) {
        //   orderDetails.forEach((orderDetail) => {
        //     orderDetail.style.width = "100%";
        //   });
        // }
        const options = {
            margin: [0.2, 0, 0.2, 0], // [Top, Left, Bottom, Right]
            filename: 'OrderDetails.pdf',
            image: { type: 'jpeg', quality: .7 },
            html2canvas: {
                scale: 1,
                useCORS: true,
                allowTaint: true,
                scrollY: 0,
                scrollX: 0
            },
            // pagebreak: { before: '.pdfPageBreak', mode: ['avoid-all', 'css', 'legacy'], avoid: 'svg' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            worker: true // Enables web worker for better performance
        };
        if (ordInfo?.SeamPDF) {
            await new Promise(resolve => setTimeout(resolve, 200));
            let blob1 = await html2pdf().from(htmlElem).set(options).output('blob');
            pdfBlobArr.push(blob1);
            let blob2 = await createPdfBlob(ordInfo?.SeamPDF);
            pdfBlobArr.push(blob2)
            // await mergePdfBlobs(blob1, blob2, ordInfo);
        } else {
            let blob1 = await html2pdf().from(htmlElem).set(options).output('blob');
            pdfBlobArr.push(blob1);
            // await html2pdf().from(htmlElem).set(options).save(`order_${ordInfo?.JobNo}_detail.pdf`);
        }

    }

    const pdfGeneratingBE = async () => {
        const jobIds = selectedIdObj.map(item => parseInt(item.JobNo, 10));
        const contactInfoIds = selectedIdObj.map(item => parseInt(item.ContactInfoId, 10));
        let reqData = {
            'contact_info_ids':contactInfoIds,
            'order_ids': jobIds,
            'access_info': auth[0].token,
            'printing_url': API_URL
        }
        await generatePdf(auth[0].token, reqData);
        setCheckPrintFlag(false)
        setSelectedIdObj([]); // Ensure React state updates properly

        setSavedJobNumber(savedCopyJobNumber)
        setSelectedIds([])
        setPrintBtnLabel(printButtonLabel)
        existingOrderListing.map(item => item.IsChecked = false);
        setSelectAll(false)
        return true;
    }
    // function called for packet printing
    const handlePrintClick = () => {

        // if (!checkPrintFlag) {
        //     // console.log('--handlePrintClick--')
        //     setSavedCopyJobNumber(savedJobNumber)
        //     setCopySelectedIds(selectedIds)
        //     setSavedJobNumber([])
        //     setSelectedIds([])
        //     setSelectedIdObj([])
        //     setPrintBtnLabel('Select Orders To Print')
        //     setCheckPrintFlag(!checkPrintFlag)
        // } else {
            // console.log('--handlePrintClick-1-')
            if (selectedIdObj.length === 0) {
                toast.error('Please select atleast one order to be processed')
                return true;
            }
            if (selectedIdObj.length > 70) {
                toast.error('A maximum of 70 orders can be selected.')
                return true;
            }
            // setCopySelectedIds(selectedIds)
            setSavedCopyJobNumber(savedJobNumber)
            setCheckPrintFlag(!checkPrintFlag)
            setPrintBtnLabel('Processing Print Packets')
            
            // generate pdf using BE api : start
            if(generatePdfByBE){
                setPdfProcessingMsg(`Processing ${selectedIdObj.length} orders to generate PDFs. This may take some time. Please wait...`)
                pdfGeneratingBE()
                // generate pdf using BE api : start
            }
            else{
                const loadPacketHtml = async (item) => {
                    const domain = window.location.origin;
                    // console.log(domain); // Example: "https://example.com"

                    // let url = `${domain}/place-order/order-confirmation/${item?.ContactInfoId}/${item?.UnitId}/${item?.JobNo}`; //local
                    let url = `${domain}/place-order/order-confirmation/contactInfoId=${item?.ContactInfoId}/unitId=${item?.UnitId}/jobNum=${item?.JobNo}`; //live
                    setPacketUrl(url);
                };

                const fetchHtml = async (ordInfo, pdfBlobArr, index) => {
                    if (!iframeRef.current) return;

                    const iframe = iframeRef.current;
                    let doc = iframe.contentDocument || iframe.contentWindow.document;

                    return new Promise((resolve) => {
                        const checkImageLoaded = setInterval(() => {
                            const image = doc?.querySelector("#floorimage");
                            if (image && image.complete) {
                                clearInterval(checkImageLoaded); // Stop checking once we find the image
                                doc = iframe.contentDocument || iframe.contentWindow.document
                                setTimeout(async () => {
                                    const pdfcontainer = doc?.querySelector("#pdfcontainer");
                                    if (pdfcontainer) {
                                        pdfcontainer.style.width = '100%';
                                        await downloadPDF(pdfcontainer, ordInfo, pdfBlobArr, index);
                                        resolve();
                                    }
                                }, 350);
                            }
                        }, 500);
                    });
                };

                // Function to sequentially process each item
                let pdfBlobArr = []
                const processItemsSequentially = async () => {
                    let tempSelectedIdObj = [...selectedIdObj]; // Create a copy to modify
                    try {
                        
                        for (let i = 0; i < tempSelectedIdObj.length; i++) {
                            setPdfProcessingMsg(`Processing ${i+1}/${tempSelectedIdObj.length} Printing PDF, please wait...`)
                            const item = tempSelectedIdObj[i];
                            await loadPacketHtml(item);
                            await new Promise(resolve => setTimeout(resolve, 750)); // Wait for iframe update
                            await fetchHtml(item, pdfBlobArr, i); // Wait for PDF generation
                        }
                        if(pdfBlobArr.length> 0){
                            setPdfProcessingMsg(`Finalizing PDF, please wait...`)
                            await mergeMultiplePdfBlobs(pdfBlobArr);
                        }
                    } catch (error) {
                        console.error("processItemsSequentially Error creating PDF Blob:", error);
                    }
                    setCheckPrintFlag(false)
                    setSelectedIdObj([]); // Ensure React state updates properly

                    setSavedJobNumber(savedCopyJobNumber)
                    setSelectedIds([])
                    setPrintBtnLabel(printButtonLabel)
                    existingOrderListing.map(item => item.IsChecked = false);

                    // Wait for 3 seconds before setting setIsPdfProcessing(false)
                    await new Promise(resolve => setTimeout(resolve, 400));
                    setSelectAll(false)
                    console.timeEnd("Total Execution Time");
                };
                console.time("Total Execution Time");  // Start timer
                processItemsSequentially();
            }

        // }


        // let tempIds = handleFilterCheckedData()
        // if (tempIds.length > 0) {
        //  let payload = {
        //      'JobNo': tempIds,
        //      'time': localTime,
        //      'timezone': localTimeZone
        //  }
        //  saveExistingOrdersList(auth[0].token, payload)
        // } else {
        //  toast.error('Please select atleast one order to be processed')
        // }
    }

    return (
        <MainWrapper>
            {(isLoading || isPdfProcessing) ? (<div className="loading-container"><div className="spinner-border"></div>{isPdfProcessing && <p className="loading-text">{pdfProcessingMsg}</p>}</div>) : null}
            <div className="container">
                <div className="sub-header mt-5 mb-3">
                    <Title>
                        Existing Orders
                        {/*<span className="processed-label">Processed orders</span>*/}
                    </Title>
                    <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
                    <CustomButton variant="primary" className="me-1" disabled={checkPrintFlag} handleClick={handleSaveClick}>Process Orders</CustomButton>
                    {showPrintPacket && (<CustomButton variant="primary" disabled={isPdfProcessing} handleClick={handlePrintClick}>{printBtnLabel}</CustomButton>)}
                </div>
                {packetUrl && (<div id="loadPdfIframe" style={{'display':'none'}}><iframe ref={iframeRef} src={packetUrl} width="600" height="600" title="print packets"></iframe></div>)}
                <ExistingOrdersList 
                    existingOrderListing={existingOrderListing}
                    totalCount={totalCount}
                    setSort={setSort}
                    setPaginate={setPaginate}
                    setPage={setPage}
                    page={page}
                    paginate={paginate}
                    listParamState={listParamState}
                    handleFilterChange={handleFilterChange}
                    storeList={storeList}
                    handleCheckboxClick={handleCheckboxClick}
                    checkFlag={checkFlag}
                    selectedOrderedData={selectedOrderedData}
                    handleAllCheckboxSelect={handleAllCheckboxSelect}
                    savedJobNumber={savedJobNumber}
                    setSavedJobNumber={setSavedJobNumber}
                    selectAll={selectAll}
                    setSelectedIds={setSelectedIds}
                    selectedIds={selectedIds}
                    checkPrintFlag={checkPrintFlag}
                />
            </div>
        </MainWrapper>
    )
}

const mapStateToProps = ({ auth, customerInfo, StoreInfo }) => {
    return {
        auth,
        customerInfo,
        StoreInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchExistingOrdersList: (token, sort, page, paginate, listParamState) => dispatch({ type: CUSTOMER_EXISTING_ORDER_LIST_REQUEST, token, sort, page, paginate, listParamState }),
        fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
        saveExistingOrdersList: (token, payload) => dispatch({ type: SAVE_SELECTED_EXISTING_ORDER_REQUEST, token, payload }),
        generatePdf: (token, payload) => dispatch({ type: PRINT_PDF_REQUEST, token, payload }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExistingOrders);