import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
	PRICING_CONTRACT_CREATE_REQUEST,
	PRICING_CONTRACT_CREATE_SUCCESS,
	PRICING_CONTRACT_CREATE_FAILED,
	PRICING_CONTRACT_PRODUCT_LIST_REQUEST,
	PRICING_CONTRACT_PRODUCT_LIST_SUCCESS,
	PRICING_CONTRACT_PRODUCT_LIST_FAILED,
	PRICING_CONTRACT_LABOR_LIST_REQUEST,
	PRICING_CONTRACT_LABOR_LIST_SUCCESS,
	PRICING_CONTRACT_LABOR_LIST_FAILED,
	PRICING_CONTRACT_MATERIAL_LIST_REQUEST,
	PRICING_CONTRACT_MATERIAL_LIST_SUCCESS,
	PRICING_CONTRACT_MATERIAL_LIST_FAILED,
	PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST,
	PRICING_CONTRACT_LABOR_ITEM_LIST_SUCCESS,
	PRICING_CONTRACT_LABOR_ITEM_LIST_FAILED,
	PRICING_CONTRACT_DETAILS_REQUEST,
	PRICING_CONTRACT_DETAILS_SUCCESS,
	PRICING_CONTRACT_DETAILS_FAILED,
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST,
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_SUCCESS,
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_FAILED,
	GROUP_PRICING_CONTRACT_CREATE_REQUEST,
	GROUP_PRICING_CONTRACT_CREATE_SUCCESS,
	GROUP_PRICING_CONTRACT_CREATE_FAILED,
	GROUP_PRICING_CONTRACT_UPDATE_REQUEST,
	GROUP_PRICING_CONTRACT_UPDATE_SUCCESS,
	GROUP_PRICING_CONTRACT_UPDATE_FAILED,
	GROUP_PRICING_CONTRACT_DELETE_REQUEST,
	GROUP_PRICING_CONTRACT_DELETE_SUCCESS,
	GROUP_PRICING_CONTRACT_DELETE_FAILED,
	GROUP_PRICING_CONTRACT_FILTER_REQUEST,
	GROUP_PRICING_CONTRACT_FILTER_SUCCESS,
	GROUP_PRICING_CONTRACT_FILTER_FAILED,
	CUSTOMER_ORDER_MATERIAL_REQUEST,
	CUSTOMER_ORDER_MATERIAL_SUCCESS,
	CUSTOMER_ORDER_MATERIAL_FAILED,
	CUSTOMER_ORDER_INSTALLATION_REQUEST,
	CUSTOMER_ORDER_INSTALLATION_SUCCESS,
	CUSTOMER_ORDER_INSTALLATION_FAILED,
	CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST,
	CUSTOMER_ORDER_MATERIAL_DELETE_SUCCESS,
	CUSTOMER_ORDER_MATERIAL_DELETE_FAILED,
	CUSTOMER_ORDER_LABOR_DELETE_REQUEST,
	CUSTOMER_ORDER_LABOR_DELETE_SUCCESS,
	CUSTOMER_ORDER_LABOR_DELETE_FAILED,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_SUCCESS,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_FAILED,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_SUCCESS,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_FAILED,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_SUCCESS,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_FAILED,
	PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST,
	PRICING_CONTRACT_QUANTITY_SET_PRODUCT_SUCCESS,
	PRICING_CONTRACT_QUANTITY_SET_PRODUCT_FAILED,
	CUSTOMER_ESTIMATES_SUCCESS,
	CUSTOMER_ESTIMATES_FAILED,
	CUSTOMER_ESTIMATES_REQUEST,
	GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST,
	GROUP_PRICING_CONTRACT_SAVE_PROPERTY_SUCCESS,
	GROUP_PRICING_CONTRACT_SAVE_PROPERTY_FAILED,
	SAVE_QUALITY_ORDER_DETAILS_REQUEST,
	SAVE_QUALITY_ORDER_DETAILS_FAILED,
	SAVE_QUALITY_ORDER_DETAILS_SUCCESS
} from "../../../_utils/constants/PricingContract";

function* fetchProductList({ token, listParamState, checkFlag, unitId, jobNum }) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'User/api/CustomerInfo'
		let unitIdParam = checkFlag ? `&UnitId=${unitId !== "undefined" ? unitId : 0}` : ""
		let jobNumParam = checkFlag ? `&JobNo=${jobNum}` : ""
		const response = yield call(
			axios.get, 
			// `${API_URL}/${url}/GetProductForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&StoreId=${listParamState.StoreId}&IsStock=${listParamState.IsStock}&IsRecurringStock=${listParamState.IsRecurringStock}&IsPrimary=${listParamState.IsPrimary}&IsSecondary=${listParamState.IsSecondary}&PrivateName=${listParamState.PrivateName}&Manufacturer=${listParamState.Manufacturer}&Style=${listParamState.Style}&Color=${listParamState.Color}&Fiber=${listParamState.Fiber}&faceWidth=${listParamState.faceWidth}&BackingType=${listParamState.BackingType}&Guage=${listParamState.Guage}&WearLayer=${listParamState.WearLayer}&Thickness=${listParamState.Thickness}&currentCost=${listParamState.currentCost}&futureCost1=${listParamState.futureCost1}&futureCost2=${listParamState.futureCost2}&ContactInfoId=${listParamState.grpPricingContractId === "" ? listParamState.ContactInfoId : ''}&grpPricingContractId=${listParamState.grpPricingContractId}&SubProductStructureId=${listParamState.subProductStructureId}${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`, 
			`${API_URL}/${url}/GetProductForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&StoreId=${listParamState.StoreId}&IsStock=${listParamState.IsStock}&IsRecurringStock=${listParamState.IsRecurringStock}&IsPrimary=${listParamState.IsPrimary}&IsSecondary=${listParamState.IsSecondary}&PrivateName=${listParamState.PrivateName}&Manufacturer=${listParamState.Manufacturer}&Style=${listParamState.Style}&Color=${listParamState.Color}&Fiber=${listParamState.Fiber}&faceWidth=${listParamState.faceWidth}&BackingType=${listParamState.BackingType}&Guage=${listParamState.Guage}&WearLayer=${listParamState.WearLayer}&Thickness=${listParamState.Thickness}&currentCost=${listParamState.currentCost}&futureCost1=${listParamState.futureCost1}&futureCost2=${listParamState.futureCost2}&ContactInfoId=${listParamState.grpPricingContractId === "" ? listParamState.ContactInfoId : ''}&grpPricingContractId=${listParamState.grpPricingContractId}${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`,
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_PRODUCT_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_PRODUCT_LIST_FAILED, payload: error.response });
		}
	}
}

function* fetchLaborList({ token, listParamState, checkFlag, unitId, jobNum }) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'User/api/CustomerInfo'
		let unitIdParam = checkFlag ? `&UnitId=${unitId !== "undefined" ? unitId : 0}` : ""
		let jobNumParam = checkFlag ? `&JobNo=${jobNum}` : ""
		const response = yield call(
			axios.get, 
			// `${API_URL}/${url}/GetLaborForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&ProductStructureIdForLabor=${listParamState.ProductStructureIdForLabor}&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&SubProductStructureId=${listParamState.subProductStructureId ? listParamState.subProductStructureId : 0}&grpPricingContractId=${listParamState.grpPricingContractId}${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`, 
			`${API_URL}/${url}/GetLaborForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&ProductStructureIdForLabor=0&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&grpPricingContractId=${listParamState.grpPricingContractId}${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_LABOR_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_LABOR_LIST_FAILED, payload: error.response });
		}
	}
}

function* fetchMaterialList({ token, listParamState, checkFlag, unitId, jobNum }) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'User/api/CustomerInfo'
		let unitIdParam = checkFlag ? `&UnitId=${unitId !== "undefined" ? unitId : 0}` : ""
		let jobNumParam = checkFlag ? `&JobNo=${jobNum}` : ""
		const response = yield call(
			axios.get, 
			// `${API_URL}/${url}/GetMaterialForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&SubProductStructureId=${listParamState.subProductStructureId ? listParamState.subProductStructureId : 0}&grpPricingContractId=${listParamState.grpPricingContractId}&IsFilter=No${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`, 
			`${API_URL}/${url}/GetMaterialForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&SubProductStructureId=${listParamState.subProductStructureId ? listParamState.subProductStructureId : 0}&grpPricingContractId=${listParamState.grpPricingContractId}&IsFilter=No${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`,
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_MATERIAL_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_MATERIAL_LIST_FAILED, payload: error.response });
		}
	}
}

function* fetchLaborItemList({ token, listParamState, checkFlag, unitId, jobNum }) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'Labor/api/LaborItem'
		let unitIdParam = checkFlag ? `&UnitId=${unitId}` : ""
		let jobNumParam = checkFlag ? `&JobNo=${jobNum}` : ""
		const response = yield call(
			axios.get, 
			`${API_URL}/${url}/GetLaborItemForPricingContract?productStructureId=${listParamState.productStructureId && listParamState.productStructureId !== 'undefined' ? listParamState.productStructureId : 0}&storeId=${checkFlag ? 0 : (listParamState.storeId && listParamState.storeId !== 'undefined' ? listParamState.storeId : 0)}&laborCategoryId=${listParamState.laborCategoryId && listParamState.laborCategoryId !== 'undefined' ? listParamState.laborCategoryId : 0}&laborName=${listParamState.laborName}&unitId=${listParamState.unitId && listParamState.unitId !== 'undefined' ? listParamState.unitId : 0}&currentCost=${parseInt(listParamState.currentCost) !== 0 && listParamState.currentCost !== 'undefined' ? listParamState.currentCost : " "}&futureCost1=${listParamState.futureCost1 && listParamState.futureCost1 !== 'undefined' ? listParamState.futureCost1 : " "}&IncreaseDate=${listParamState.increaseDate ? listParamState.increaseDate : " "}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&grpPricingContractId=${listParamState.grpPricingContractId}${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_LABOR_ITEM_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_LABOR_ITEM_LIST_FAILED, payload: error.response });
		}
	}
}

function* createPricingContract({token, data}) {
	try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/CustomerInfo/SavePricingContract`,
      data, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    );
    yield put({
      type: PRICING_CONTRACT_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRICING_CONTRACT_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}

function* fetchPricingContractDetails({token, data, checkFlag, unitId, jobNum}) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'User/api/CustomerInfo'
		let unitIdParam = checkFlag ? `&UnitId=${unitId !== "undefined" ? unitId : 0}` : ""
		let jobNumParam = checkFlag ? `&JobNo=${jobNum}` : ""
    const response = yield call(
			axios.get, 
			`${API_URL}/${url}/GetPricingContractDetails?ContactInfoId=${data.cutomerId}&grpPricingContractId=${data.groupContractId}${checkFlag ? unitIdParam : ""}${checkFlag ? jobNumParam : ""}`, 
			{
				headers: {
					Authorization: `Bearer ${token}`
				},
    	}
    );
    yield put({
      type: PRICING_CONTRACT_DETAILS_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRICING_CONTRACT_DETAILS_FAILED,
        payload: error.response
      });
    }
  }
}

function* fetchGroupPricingContractData({ token }) {
	try {
		const response = yield call(
			axios.get, 
			`${API_URL}/User/api/CustomerInfo/GetGrpPricingContract`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: GROUP_PRICING_CONTRACT_PRODUCT_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: GROUP_PRICING_CONTRACT_PRODUCT_LIST_FAILED, payload: error.response });
		}
	}
}

function* CreateGroupPricingContractRequest({token, data}) {
	try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/CustomerInfo/CreateGrpPricingContract`,
      data, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    );
    yield put({
      type: GROUP_PRICING_CONTRACT_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: GROUP_PRICING_CONTRACT_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}

function* GroupPricingContractUpdateRequest({token, data}) {
	try {
    const response = yield call(
      axios.put,
      `${API_URL}/User/api/CustomerInfo/GrpPricingContractUpdate`,
      data, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    );
    yield put({
      type: GROUP_PRICING_CONTRACT_UPDATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: GROUP_PRICING_CONTRACT_UPDATE_FAILED,
        payload: error.response
      });
    }
  }
}

function* GroupPricingContractDeleteRequest(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/User/api/CustomerInfo/RemoveGrpPricingContract?id=${encodeURIComponent(action.data.groupPricingContractId)}`, {
      headers: {
        Authorization: `Bearer ${action.data.token}`
      }
    },
    );
    yield put({
      type: GROUP_PRICING_CONTRACT_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: GROUP_PRICING_CONTRACT_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

function* pricingContractFilterList({ token, listParamState }) {
	try {
		const response = yield call(
			axios.get, 
			`${API_URL}/User/api/CustomerInfo/GetGrpPricingContract?contractname=${listParamState.contractName}&salesperson=${listParamState.salesPerson}`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: GROUP_PRICING_CONTRACT_FILTER_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: GROUP_PRICING_CONTRACT_FILTER_FAILED, payload: error.response });
		}
	}
}

function* fetchOrderInstallationMaterial({token, jobNum}) {
	try {
    const response = yield call(
			axios.get, 
			`${API_URL}/Ordering/api/Order/GetOrderMaterialByJobNo?JobNo=${jobNum}`, 
			{
				headers: {
					Authorization: `Bearer ${token}`
				},
    	}
    );
    yield put({
      type: CUSTOMER_ORDER_MATERIAL_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CUSTOMER_ORDER_MATERIAL_FAILED,
        payload: error.response
      });
    }
  }
}

function* fetchCustomerEstimates({token, contactInfoId}) {
	try {
    const response = yield call(
			axios.get, 
			`${API_URL}/Ordering/api/Order/GetCustomerFloorplanEstimates?ContactInfoId=${contactInfoId}`, 
			{
				headers: {
					Authorization: `Bearer ${token}`
				},
    	}
    );
    yield put({
      type: CUSTOMER_ESTIMATES_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CUSTOMER_ESTIMATES_FAILED,
        payload: error.response
      });
    }
  }
}

function* fetchOrderInstallationOrder({token, jobNum}) {
	try {
    const response = yield call(
			axios.get, 
			`${API_URL}/Ordering/api/Order/GetLaborOrderByJobNo?JobNo=${jobNum}`, 
			{
				headers: {
					Authorization: `Bearer ${token}`
				},
    	}
    );
    yield put({
      type: CUSTOMER_ORDER_INSTALLATION_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CUSTOMER_ORDER_INSTALLATION_FAILED,
        payload: error.response
      });
    }
  }
}

function* deleteOrderMaterialData({token, id, checkFlag}) {
	try {
		let url = checkFlag ? 'DeleteOrderMaterial?OrderMaterialId' : 'DeleteOrderProduct?OrderProductId'
    const response = yield call(
      axios.post,
      `${API_URL}/Ordering/api/Order/${url}=${id}`,'',
      {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    );
    yield put({
      type: CUSTOMER_ORDER_MATERIAL_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CUSTOMER_ORDER_MATERIAL_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

function* deleteOrderLaborData({token, id}) {
	try {
    const response = yield call(
      axios.post,
      `${API_URL}/Ordering/api/Order/DeleteOrderLabor?OrderLaborId=${id}`,'',
      {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    );
    yield put({
      type: CUSTOMER_ORDER_LABOR_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CUSTOMER_ORDER_LABOR_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

function* fetchTransitionLaborForPricingContract({ token, listParamState, checkFlag }) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'User/api/CustomerInfo'
		const response = yield call(
			axios.get, 
			// `${API_URL}/${url}/GetLaborForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&ProductStructureIdForLabor=${listParamState.ProductStructureIdForLabor}&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&SubProductStructureId=${listParamState.subProductStructureId ? listParamState.subProductStructureId : 0}&grpPricingContractId=${listParamState.grpPricingContractId}`, 
			`${API_URL}/${url}/GetLaborForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&ProductStructureIdForLabor=0&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&grpPricingContractId=${listParamState.grpPricingContractId}`,
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_TRANSITION_LABOR_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_TRANSITION_LABOR_LIST_FAILED, payload: error.response });
		}
	}
}

function* fetchTransitionMaterialForPricingContract({ token, listParamState, checkFlag }) {
	try {
		let url = checkFlag ? 'Ordering/api/Order' : 'User/api/CustomerInfo'
		const response = yield call(
			axios.get, 
			// `${API_URL}/${url}/GetMaterialForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&SubProductStructureId=${listParamState.subProductStructureId ? listParamState.subProductStructureId : 0}&grpPricingContractId=${listParamState.grpPricingContractId}`, 
			`${API_URL}/${url}/GetMaterialForPricingContract?ProductStructureId=${listParamState.ProductStructureId}&StoreId=${listParamState.StoreId}&ContactInfoId=${listParamState.ContactInfoId !== "" ? listParamState.ContactInfoId : ''}&grpPricingContractId=${listParamState.grpPricingContractId}`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_FAILED, payload: error.response });
		}
	}
}

// fetch missing stairs labor in contract
function* fetchMissingLaborItemsInContract({ token, contactInfoId }) {
	try {
		const response = yield call(
			axios.get, 
			`${API_URL}/Measure/api/Stair/MissingStairsLaborItemsInContract?ContactinfoId=${contactInfoId}`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_MISSING_STAIRS_LABOR_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_MISSING_STAIRS_LABOR_FAILED, payload: error?.response });
		}
	}
}

// fetch missing stairs labor in contract
function* getQuantitySetProduct({ token, contactInfoId }) {
	try {
		const response = yield call(
			axios.get, 
			`${API_URL}/User/api/CustomerInfo/GetQuantitySetProduct?ContactInfoId=${contactInfoId}`, 
			{ 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: PRICING_CONTRACT_QUANTITY_SET_PRODUCT_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: PRICING_CONTRACT_QUANTITY_SET_PRODUCT_FAILED, payload: error?.response });
		}
	}
}

function* SaveGroupPCSaveProperty({token, data}) {
	try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/CustomerInfo/SavePropertyForGPC`,
      data, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    );
    yield put({
      type: GROUP_PRICING_CONTRACT_SAVE_PROPERTY_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: GROUP_PRICING_CONTRACT_SAVE_PROPERTY_FAILED,
        payload: error.response
      });
    }
  }
}

// fetch missing stairs labor in contract
function* saveQualityOrderDetail({ token, data }) {
	try {
		const response = yield call(
			axios.post, 
			`${API_URL}/Ordering/api/Order/SaveQualityOrderDetails`, 
			data, { 
				headers: { 
					Authorization: `Bearer ${token}` 
				} 
			}
		);
		yield put({ type: SAVE_QUALITY_ORDER_DETAILS_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: SAVE_QUALITY_ORDER_DETAILS_FAILED, payload: error?.response });
		}
	}
}

export default function* rootPricingContract() {
	yield all([
		takeLatest(PRICING_CONTRACT_PRODUCT_LIST_REQUEST, fetchProductList),
		takeLatest(PRICING_CONTRACT_LABOR_LIST_REQUEST, fetchLaborList),
		takeLatest(PRICING_CONTRACT_MATERIAL_LIST_REQUEST, fetchMaterialList),
		takeLatest(PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST, fetchLaborItemList),
		takeLatest(PRICING_CONTRACT_CREATE_REQUEST, createPricingContract),
		takeLatest(PRICING_CONTRACT_DETAILS_REQUEST, fetchPricingContractDetails),
		takeLatest(GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST, fetchGroupPricingContractData),
		takeLatest(GROUP_PRICING_CONTRACT_CREATE_REQUEST, CreateGroupPricingContractRequest),
		takeLatest(GROUP_PRICING_CONTRACT_UPDATE_REQUEST, GroupPricingContractUpdateRequest),
		takeLatest(GROUP_PRICING_CONTRACT_DELETE_REQUEST, GroupPricingContractDeleteRequest),
		takeLatest(GROUP_PRICING_CONTRACT_FILTER_REQUEST, pricingContractFilterList),
		takeLatest(CUSTOMER_ORDER_MATERIAL_REQUEST, fetchOrderInstallationMaterial),
		takeLatest(CUSTOMER_ORDER_INSTALLATION_REQUEST, fetchOrderInstallationOrder),
		takeLatest(CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST, deleteOrderMaterialData),
		takeLatest(CUSTOMER_ORDER_LABOR_DELETE_REQUEST, deleteOrderLaborData),
		takeLatest(PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST, fetchTransitionLaborForPricingContract),
		takeLatest(PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST, fetchTransitionMaterialForPricingContract),
		takeLatest(PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST, fetchMissingLaborItemsInContract),
		takeLatest(PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST, getQuantitySetProduct),
		takeLatest(CUSTOMER_ESTIMATES_REQUEST, fetchCustomerEstimates),
		takeLatest(GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST, SaveGroupPCSaveProperty),
		takeLatest(SAVE_QUALITY_ORDER_DETAILS_REQUEST, saveQualityOrderDetail),
	])
}