import React, { Fragment } from "react";
import Model from "../../../../elements/CustomModel";
import { Modal } from "react-bootstrap";
import ModelBody from "../../../../elements/ModelBody";
import ModelFooter from "../../../../elements/ModelFooter";
import Form from "../../../../elements/CustomForm";
import Select from "../../../../elements/CustomSelect";
import Input from "../../../../elements/CustomInput";
import Button from "../../../../elements/CustomButton";
import { Col, Row } from 'react-bootstrap';
import CustomCurrencyInput from "../../../../elements/CustomCurrencyInput";
import Checkbox from "../../../../elements/CustomCheckBox";
const AddEdit = ({
  isOpen,
  size,
  hideModel,
  handleSubmit,
  validated,
  handleChange,
  state,
  laborId,
  productStructure,
  storeList,
  laborCategories,
  units,
  handleChangeCurrency,
  isLoading
}) => {
  const handleValue = (value) => {
    if (value) {
      return value
    }
  }
  return (
    <Fragment>
      <Model isOpen={isOpen} size={size}>
        <Modal.Header closeButton onClick={hideModel}>
          <Modal.Title>{laborId ? 'Edit Labor Item' : "Add New Labor Item"}</Modal.Title>
        </Modal.Header>
        <Form handleSubmit={handleSubmit} validated={validated}>
          <ModelBody>
            <Row className="justify-content-center">
              <Col lg={4} md={6} className="mb-3">
                <Select label={`Product Structure`} result={productStructure} ids="productStructureId" value={state.productStructureId} handleChange={handleChange} validate={true} />
              </Col>
              <Col lg={4} md={6}>
                <Select label={`Store Name`} ids={`storeId`} result={storeList} handleChange={handleChange} value={state.storeId} validate={true} />
              </Col>
              <Col lg={4} md={6}>
                <Select label={`Labor Category`} ids={`laborCategoryId`} result={laborCategories} handleChange={handleChange} value={state.laborCategoryId} validate={true} />
              </Col>
              <Col lg={4} md={6} className="mb-3">
                <Input label={"Labor Name"} ids={'laborName'} handleChange={handleChange} value={state.laborName} validate={true} />
              </Col>
              <Col lg={4} md={6}>
                <Select label={`Unit`} ids={`unitId`} result={units} handleChange={handleChange} value={state.unitId} validate={true} />
              </Col>
              <Col lg={4} md={6} className="mb-3">
                <CustomCurrencyInput label={`Current Cost`} ids={`currentCost`} handleChange={handleChangeCurrency} value={handleValue(state.currentCost)} validate={true} />
              </Col>
              <Col className="mb-3">
                <Checkbox label={`Common Add Ons`} ids="isAddOn" value={state.isAddOn} handleChange={handleChange} validate={false} />
              </Col>
              { laborId &&
                <Col lg={4} md={6} className="mb-3">
                  <Input label={`Labor ID`} value={laborId} disabled={true} validate={false} />
                </Col>
              }
            </Row>
          </ModelBody>
          <ModelFooter>
            <Button handleClick={hideModel} variant="secondary" type="button">
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (<div className="spinner-border"></div>) : "Save"}
            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )
}

export default AddEdit;