import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import html2pdf from 'html2pdf.js';;
import { connect } from "react-redux";
import { Container, Card, Row, Col, Form, Table, Button, ListGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "react-icons-kit";
import {ic_delete_outline} from "react-icons-kit/md";
import {ic_phone} from 'react-icons-kit/md/ic_phone'
import { useParams, Navigate } from "react-router-dom";
import MainWrapper from "../../MainWrapper";
import PricingContract from "../customer/models/PricingContract";
import { 
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_UNIT_DETAIL_REQUEST,
  CUSTOMER_ORDER_DETAIL_REQUEST,
  CUSTOMER_STATE_CHANGE,
  SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST,
  CUSTOMER_ORDER_UNIT_CHANGE_REQUEST,
  CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST,
  CUSTOMER_ORDER_RESCHEDULE_REQUEST,
  CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST,
  CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST,
  CUSTOMER_ORDER_ADDON_SAVE_REQUEST,
  GET_SHORTAGE_REASON_REQUEST,
  SAVE_ORDER_SHORTAGE_DETAIL_REQUEST,
  UPDATE_SHORTAGE_REASON_REQUEST,
  GET_QUALITY_REASON_REQUEST,
  GET_QUALITY_ORDER_REQUEST,
  UPDATE_QUALITY_REASON_REQUEST,
  UPDATE_QUALITY_DETAIL_REQUEST
 } from "../../../_utils/constants/Customer";
 import {
  CUSTOMER_ORDER_MATERIAL_REQUEST,
  CUSTOMER_ORDER_INSTALLATION_REQUEST,
  CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST,
  CUSTOMER_ORDER_LABOR_DELETE_REQUEST,
  SAVE_QUALITY_ORDER_DETAILS_REQUEST,
  SAVE_ORDER_QUALITY_DETAIL_REQUEST,
 } from "../../../_utils/constants/PricingContract";
 import { useNavigate } from "react-router-dom";
 import ConfirmDelete from "../../elements/ConfirmDelete";
 import Input from "../../elements/CustomInput";
 import { toast } from 'react-toastify';
import ChangeJob from "./models/ChangeJob";
import {Context} from "../../../App";
import Select from "../../elements/CustomSelect";

const OrderConfirmation = ({
  auth: { auth, isAuthenticated },
	customerInfo: { customerList, customerOrderDetails, customerSaveOrderDetails, customerSaveContractDetails, isLoading, errors, isDateRescheduled, orderRescheduledDetails, orderAddOnDetails, saveOrderAddOnDetails, shortageReason, saveOrderShortage, updateShortageReason, qualityReasons, saveOrderQualityDetailData, OrderQualityDetail, updatedQualityReason, updatedQualityDetail, isOrderLoading },
  pricingContract: { orderMaterialData, orderInstallationData, deletedOrderMaterialData, deletedOrderLaborData, isProcessing },
  fetchOrderInstallationDetail,
  fetchCustomerList,
	saveCustomerOrderDetails,
  changeReducerState,
  fetchOrderInstallationMaterial,
  fetchOrderInstallationOrder,
  saveCustomerOrderDetailsForPricingContract,
  deleteOrderMaterialData,
  deleteOrderLaborData,
  saveCustomerOrderUnitId,
  saveCustomerOrderUnitTypeId,
  saveCustomerOrderRescheduleData,
  fetchCustomerOrderRescheduledData,
  fetchCustomerOrderAddOnDetails,
  saveCustomerOrderAddOnDetails,
  getShortageReasonRequest,
  saveOrderShortageRequest,
  updateShortageReasonRequest,
  getQualityReasonRequest,
  saveOrderQualityDetailRequest,
  saveQualityOrderDetail,
  fetchQualityOrderDetail,
  updateQualityReason,
  updateQualityDetail
}) => {

  const navigate = useNavigate();
  const {pushervalue} = useContext(Context);
  // states declaration
  const { contactInfoId } = useParams()
  const { unitId } = useParams()
  const { jobNum } = useParams()
  const [pricingContractModalOpen, setPricingContractModalOpen] = useState(false)
  const [laborContractModalOpen, setLaborContractModalOpen] = useState(false)
  const [materialContractModalOpen, setMaterialContractModalOpen] = useState(false)
  const [customerData, setCustomerData] = useState()
  const [customerOrderData, setCustomerOrderData] = useState()
  const [materialListData, setMaterialListData] = useState()
  const [orderInstallationListData, setOrderInstallationListData] = useState()
  const [state, setState] = useState({
    "newUnitId": "",
    "newUnitTypeId": "",
    "description": "",
    "internalNotes": "",
    "installerInstructions": "",
    "tax": "",
  })
  const [stateLaborFilter, setStateLaborFilter] = useState({
		"laborName": "",
		"storeId": "",
		"ContactInfoId": "",
		"grpPricingContractId": "",
		"productTypeId": 0,
		"productStructureId": 0,
		"laborCategoryId": 0,
		"unitId": 0,
		"currentCost": 0,
		"futureCost1": 0,
		"increaseDate": "",
		"sortOn": "",
	});
  const [lsUpdate, setLsUpdate] = useState(false)
  const [lsLaborUpdate, setLsLaborUpdate] = useState(false)
  const [lsLaborMaterialUpdate, setLsLaborMaterialUpdate] = useState(false)

  const [orderInstallationCarpetData, setOrderInstallationCarpetData] = useState()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteOrderData, setDeleteOrderData] = useState()
  const [deleteOrder, setDeleteOrder] = useState()
  const [totalMaterialUnitPrice, setTotalMaterialUnitPrice] = useState()
  const [totalMaterialLinePrice, setTotalMaterialLinePrice] = useState()
  const [totalLaborUnitPrice, setTotalLaborUnitPrice] = useState()
  const [totalLaborLinePrice, setTotalLaborLinePrice] = useState()
  const [totalLaborOrgUnitPrice, setTotalLaborOrgUnitPrice] = useState()
  const [totalLaborOrgLinePrice, setTotalLaborOrgLinePrice] = useState()
  const [orderTotal, setOrderTotal] = useState()
  const [checkOrder, setCheckOrder] = useState(false)
  const [isChangeJob, setIsChangeJob] = useState(false)
  const [startDate, setStartDate] = useState()
  const [rescheduleData, setRescheduleData] = useState({
    'RescheduleChoicesId': '',
    'changeJobNotes': ''
  })
  const [ selectedCheckbox, setSelectedCheckbox] = useState([])
  const [ orderShortageList, setOrderShortageList ] = useState([])
  const [ changeJobHeader, setChangeJobHeader ] = useState('Change Job')
  const [ addOnData, setAddOnData ] = useState({
    'approvedBy': '',
    'description': ''
  })
  const [ isStateChanged, setIsStateChanged ] = useState(false)
  const [orderingUrl, setIsOrderingUrl] = useState();
  const [processStart, setProcessStart] = useState(false);
  const qualityOrderData = JSON.parse(localStorage.getItem('quality-order'))
  const [isQualityPlaced, setIsQualityPlaced] = useState(false)
  const [ selectedQualityCheckbox, setSelectedQualityCheckbox] = useState([])
  const [createdDate, setCreatedDate] = useState("")
  const [createdTime, setCreatedTime] = useState("")

  useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

  // function to check if order generated or is selected
  const orderedPlaced = () => {
    let fromExistingOrder = localStorage.getItem('Existing_Orders');
    if (fromExistingOrder === 'true') {
      return true
    } else {
      return false
    }
  }

  // function to fetch quality order data
  const handleQualityOrderData = () => {
    if (qualityOrderData) {
      let formData = new FormData();
      formData.append('OrderInstallationDetail', qualityOrderData?.OrderInstallationDetail)
      formData.append('AddonItems', qualityOrderData?.AddonItems)
      fetchQualityOrderDetail(auth[0].token, formData)
    }
  }

  // handle placed order
  const qualityJobNo = () => {
    return JSON.parse(localStorage.getItem('quality-JobNum'))
  }

  // handle quality placed
  const handlePlacedQuality = () => {
    if (typeof jobNum === "string" && jobNum.includes("-Q")) {
      setIsQualityPlaced(true)
      return true; // Returns true if the string contains "-A".
    }
    setIsQualityPlaced(false)
    return false;
  }

  // handle formatting of date
  const handleFormatDate = (installationDate) => {
    const dateObj = new Date(installationDate);
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
    return formattedDate;
  }

  // parse the date | database to frontend
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${month}.${day}.${year}`;
  };

  // handle created data and time
  const handleOrderedDetails = () => {
    let existing_Orders_value = JSON.parse(localStorage.getItem('Existing_Orders_value'))
    if (existing_Orders_value) {
      let dateObject = new Date(existing_Orders_value?.CreatedOn)
      let formattedDate = dateObject.toISOString().split("T")[0]
      let formattedTime = dateObject.toTimeString().split(" ")[0]
      setCreatedDate(handleFormatDate(formattedDate));
      setCreatedTime(formattedTime);
    }
  }

  // function to call customer detail api
	useEffect(() => {
		if (isAuthenticated) {
      fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': contactInfoId }, auth[0].userId, false, false, false)
      getShortageReasonRequest(auth[0].token, '')
      fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
      fetchOrderInstallationMaterial(auth[0].token, jobNum)
      fetchOrderInstallationOrder(auth[0].token, jobNum)
      handlePlacedQuality()
      handleOrderedDetails()
		}
		if (!isAuthenticated) return <Navigate to="/login" />
	}, [auth, isAuthenticated]);

  // function called to modify order listing
	useEffect(() => {
		localStorage.setItem('Order-Listing', 'on');
	}, [])

  // for update the shortage reason
  useEffect(() => {
    if (jobNum && !JSON.parse(localStorage.getItem('isQuality'))) {
      fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
    }
    handleQualityOrderData()
  },[updateShortageReason])

  // for update the Quality reason
  useMemo(() => {
    if (jobNum && !JSON.parse(localStorage.getItem('isQuality'))) {
      fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
    }
    handleQualityOrderData()
  },[updatedQualityReason])

  // for update the quality detail
  useEffect(() => {
    if (jobNum && !JSON.parse(localStorage.getItem('isQuality'))) {
      fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
    }
    handleQualityOrderData()
  },[updatedQualityDetail])

  // function to handle view from existing order
  const handleExistingOrder = () => {
    let fromExistingOrder = localStorage.getItem('Existing_Orders');
    if (fromExistingOrder === 'true') {
      setCheckOrder(true)
    } else {
      setCheckOrder(false)
    }
  }

  useEffect(() => {
    handleExistingOrder()
  }, [checkOrder, localStorage.getItem('Existing_Orders')])

  // function to manage customer list data
  useMemo(() => {
    if(customerList.length > 0) {
      setCustomerData(customerList)
      setStateLaborFilter({
        ...stateLaborFilter,
        "storeId": customerList[0].storeId,
        "ContactInfoId": contactInfoId
      })
    }
  }, [customerList])

  // handle quality status list data
  const qualityOrderedStatusList = useMemo(() => {
    let qualityStatusData = [{ value: "Open", label: "Open" }, { value: "Closed", label: "Closed" }];
    return qualityStatusData
  }, [customerOrderDetails])

  // function to manage customer order details
  useMemo(() => {
    if(customerOrderDetails) {
      setOrderShortageList(customerOrderDetails ? customerOrderDetails[0]?.orderShortage : [])
      setCustomerOrderData(customerOrderDetails)
      let tempData = qualityOrderData?.OrderInstallationDetail && JSON.parse(localStorage.getItem('isQuality')) ? JSON.parse(qualityOrderData?.OrderInstallationDetail) : null
      customerOrderDetails.map(data => {
        setState({
          "newUnitId": data?.orderInstallationDetails?.UnitName ? data?.orderInstallationDetails?.UnitName : "",
          "newUnitTypeId": data?.orderInstallationDetails?.UnitTypeName ? data?.orderInstallationDetails?.UnitTypeName : "",
          "description": data?.orderInstallationDetails?.Description ? data?.orderInstallationDetails?.Description : "",
          "internalNotes": data?.orderInstallationDetails?.InternalNotes ? data?.orderInstallationDetails?.InternalNotes : "",
          "installerInstructions": tempData?.Instruction ? tempData?.Instruction : (data?.orderInstallationDetails?.InstallerInstruction ? data?.orderInstallationDetails?.InstallerInstruction : data?.orderInstallationDetails?.Instruction),
          "tax": ""
        })
      })
    }
  }, [customerOrderDetails])

  // handle listing modification
  const roundSpecificKeys = (data, keysToRound = ["quantity", "unitPrice", "linePrice"]) => {
    return data.map(item =>
      Object.fromEntries(
        Object.entries(item).map(([key, value]) => {
          if (keysToRound.includes(key) && typeof value === "number") {
            return [key, value.toFixed(2)]
          }
          return [key, value]
        })
      )
    )
  }

  useEffect(() => {
    if (!parseInt(jobNum) && OrderQualityDetail && OrderQualityDetail.length > 0 && JSON.parse(localStorage.getItem('isQuality'))) {
      let arr1 = OrderQualityDetail.map(item => [...item.productData, ...item.materialData]);
      let arr2 = OrderQualityDetail.map(item => [...item.laborData]);
      let tempList = roundSpecificKeys(arr1.flat())
      let tempList2 = roundSpecificKeys(arr2.flat())
      setMaterialListData(tempList)
      setOrderInstallationListData(tempList2)
      let tempData = JSON.parse(qualityOrderData.OrderInstallationDetail)
      if (customerOrderData && customerOrderData.length > 0) {
        customerOrderData.map(data => {
          data.orderInstallationDetails.MeasureImagePng = tempData.FloorPlan ? tempData.FloorPlan : data.orderInstallationDetails.MeasureImagePng,
          data.orderInstallationDetails.InstallationDate = tempData.InstallationDate ? tempData.InstallationDate : data.orderInstallationDetails.InstallationDate,
          data.orderInstallationDetails.Email = tempData.Email ? tempData.Email : data.orderInstallationDetails.Email,
          data.orderInstallationDetails.FullName = tempData.FullName ? tempData.FullName : data.orderInstallationDetails.FullName,
          data.orderInstallationDetails.Instruction = tempData.Instruction ? tempData.Instruction : data.orderInstallationDetails.Instruction,
          data.orderInstallationDetails.Occupaied = tempData.Occupaied ? tempData.Occupaied : data.orderInstallationDetails.Occupaied,
          data.orderInstallationDetails.PhoneNo = tempData.PhoneNo ? tempData.PhoneNo : data.orderInstallationDetails.PhoneNo,
          data.orderInstallationDetails.PurchaseOrder = tempData.PurchaseOrder ? tempData.PurchaseOrder : data.orderInstallationDetails.PurchaseOrder,
          data.orderInstallationDetails.UnitId = tempData.unitId ? tempData.unitId : data.orderInstallationDetails.UnitId
        })
      }
    }
  }, [OrderQualityDetail])

  useEffect(() => {
    if(saveOrderQualityDetailData?.result && saveOrderQualityDetailData?.result?.length > 0 && JSON.parse(localStorage.getItem('isQuality'))) {
      if (saveOrderQualityDetailData?.result[0] === 'Quality order saved successfully') {
        // toast.success(saveOrderQualityDetailData?.result[0])
        localStorage.setItem('Existing_Orders', JSON.parse(true))
        localStorage.setItem('Existing-Orders-Page', JSON.parse(true))
        localStorage.setItem('isQuality', false)
        navigate(`/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${parseInt(JSON.parse(localStorage.getItem('quality-UnitId')))}/jobNum=${parseInt(JSON.parse(localStorage.getItem('quality-JobNum')))}`)
        setCheckOrder(false)
        fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
        fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': contactInfoId }, auth[0].userId, false, false, false)
        getShortageReasonRequest(auth[0].token, '')
        fetchOrderInstallationMaterial(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo())
        fetchOrderInstallationOrder(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo())
      }
    }
  }, [saveOrderQualityDetailData])

  // handle click make change
  const handleMakeChangeClose = () => {
    setIsChangeJob(false)
    setStartDate()
    setChangeJobHeader('Change Job')
    setRescheduleData({
      'RescheduleChoicesId': '',
      'changeJobNotes': ''
    })
    setAddOnData({
      'approvedBy': '',
      'description': ''
    })
    fetchOrderInstallationMaterial(auth[0].token, jobNum)
    fetchOrderInstallationOrder(auth[0].token, jobNum)
  }

  // handle click make change
  const handleMakeChangeModalOpen = () => {
    setIsChangeJob(true)
  }

  // handle click make change
  const handleMakeChangeClick = () => {
    handleMakeChangeModalOpen()
  }

  useEffect(() => {
    if (changeJobHeader === 'Reschedule' && isDateRescheduled[0] === 'saved') {
      fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
      toast.success(isDateRescheduled[0])
      handleMakeChangeClose()
      setIsChangeJob(false)
    } else if (changeJobHeader === 'Add On' && isDateRescheduled[0] === 'saved') {
      fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
      toast.success(isDateRescheduled[0])
      handleMakeChangeClose()
      setIsChangeJob(false)
    } else if (changeJobHeader === 'Reschedule' && isDateRescheduled[0]) {
      toast.error(isDateRescheduled[0])
    }
  }, [isDateRescheduled])

  // handle reschedule date edit case
  // useEffect(() => {
  //   if (orderRescheduledDetails && orderRescheduledDetails[0] && orderRescheduledDetails.length > 0) {
      // setRescheduleData({
      //   'RescheduleChoicesId': orderRescheduledDetails[0].reason,
      //   'changeJobNotes': orderRescheduledDetails[0].note
      // })
      // setStartDate(orderRescheduledDetails[0].orderInstallationDate)
  //   }
  // }, [orderRescheduledDetails])
    
  //reset all state
  const resetState = () => {
    setMaterialContractModalOpen()
    setCustomerData()
    setCustomerOrderData()
    setMaterialListData()
    setOrderInstallationListData()
    setState({
      "newUnitId": "",
      "newUnitTypeId": "",
      "description": "",
      "internalNotes": "",
      "installerInstructions": "",
      "tax": "",
    })
    setStateLaborFilter({
      "laborName": "",
      "storeId": "",
      "ContactInfoId": "",
      "grpPricingContractId": "",
      "productTypeId": 0,
      "productStructureId": 0,
      "laborCategoryId": 0,
      "unitId": 0,
      "currentCost": 0,
      "futureCost1": 0,
      "increaseDate": "",
      "sortOn": "",
    })
    setOrderInstallationCarpetData()
    setConfirmDeleteModal()
    setDeleteOrderData()
    setDeleteOrder()
    setTotalMaterialUnitPrice()
    setTotalMaterialLinePrice()
    setTotalLaborUnitPrice()
    setTotalLaborLinePrice()
    setOrderTotal()
  }

  // function called to save updated listing for material and labor
  const handleMaterialLaborListingUpdate = () => {
    let productList = []
    let materialList = []
    let laborList = []
    if(materialListData && materialListData.length > 0) {
      materialListData.map(data => {
        if(data.orderProductId) {
          productList.push({
            ProductId: data.productId,
            Quantity: parseFloat(data.quantity),
            GPM: parseFloat(data.gpm),
            LinePrice: parseFloat(data.linePrice),
            OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
            CreatedBy: parseFloat(auth[0].userId),
            UnitPrice: data.unitPrice ? parseFloat(data.unitPrice) : data.price ? parseFloat(data.price) : 0.00,
          })
        }
        if(data.orderMaterialId) {
          materialList.push({
            MaterialId: parseFloat(data.materialId),
            Product: parseFloat(data.productId),
            Quantity: parseFloat(data.quantity),
            GPM: parseFloat(data.gpm),
            LinePrice: parseFloat(data.linePrice),
            OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
            CreatedBy: parseFloat(auth[0].userId),
            UnitPrice: data.unitPrice ? parseFloat(data.unitPrice) : data.price ? parseFloat(data.price) : 0.00,
          })
        }
        if(data.productId && !data.orderProductId && !data.orderMaterialId) {
          productList.push({
            ProductId: data.productId,
            Quantity: parseFloat(data.quantity),
            GPM: parseFloat(data.gpm),
            LinePrice: parseFloat(data.linePrice),
            OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
            CreatedBy: parseFloat(auth[0].userId),
            UnitPrice: data.unitPrice ? parseFloat(data.unitPrice) : data.price ? parseFloat(data.price) : 0.00,
          })
        }
      })
    }
    if(orderInstallationListData && orderInstallationListData.length > 0) {
      orderInstallationListData.map(data => {
        laborList.push({
          LaborItemId: data.laborItemId,
          LaborId: data.laborId,
          Quantity: data.quantity,
          GPM: data.gpm,
          LinePrice: data.linePrice,
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: data.unitPrice ? parseFloat(data.unitPrice) : data.price ? parseFloat(data.price) : 0.00,
        })
      })
    }
    let payload = {
      OrderMaterial: materialList,
      ProductOrder: productList,
      LaborOrder: laborList
    }
    saveCustomerOrderDetailsForPricingContract(auth[0].token, payload)
  }

  // handle save order details api response
  useMemo(() => {
    if(customerSaveOrderDetails.length > 0 && customerSaveOrderDetails[0] === "save") {
      handleMaterialLaborListingUpdate()
    }
    if(customerSaveOrderDetails.length > 0 && customerSaveOrderDetails[0] !== "save") {
      toast.error(customerSaveOrderDetails[0])
    }
  }, [customerSaveOrderDetails])

  // function called to modify customer listing
	useEffect(() => {
		localStorage.setItem('Customer-Listing', 'on')
		handleExistingOrder()
	}, [])

  useMemo(() => {
    if(customerSaveOrderDetails.length > 0 && customerSaveOrderDetails[0] === "save" && customerSaveContractDetails.length > 0 && customerSaveContractDetails[0] === "saved") {
      resetState()
      localStorage.removeItem("copySelectedLaborList")
      localStorage.removeItem("selectedAllLaborMaterialCarpetData")
      localStorage.removeItem("selectedAllCarpetData")
      navigate(`/place-order`)
    }
  }, [customerSaveOrderDetails, customerSaveContractDetails])
  
  //handle api response errors
  useEffect(() => {
    if(errors && errors?.data && (errors?.data?.title||errors?.data?.result)){
      toast.error(errors?.data?.title)
    if(errors?.data?.result?.length > 0 ){
        const msg = errors?.data?.result
        msg.map((m)=>{
          toast.error(m)
        })
      }}
  }, [errors])

  // handle labor and material listing
  const materialAndLaborListing = () => {
    fetchOrderInstallationMaterial(auth[0].token, jobNum)
    fetchOrderInstallationOrder(auth[0].token, jobNum)
  }

  // function to handle digits after decimal
  const formatNumberWithTwoDigitsAfterDecimal = (number) => {
    const numberString = parseFloat(number).toFixed(2); // Convert the number to a string with two decimal places
    const decimalIndex = numberString.indexOf('.');
    
    if (decimalIndex === -1) {
      // No decimal point found, so add two zeros after the number
      return numberString + '.00';
    }
  
    const digitsAfterDecimal = numberString.length - decimalIndex - 1;

    if (digitsAfterDecimal < 2) {
      // Add a zero after the number to make it two digits after the decimal point
      return numberString + '0';
    }
  
    // Return the number as is, already with two digits after the decimal point
    return numberString;
  }

  // function to generate key
  const generateKey = (item = {}) => [
    item.orderMaterialId ?? "",
    item.materialId ?? "",
    item.orderProductId ?? "",
    item.productId ?? "",
    item.orderLaborId ?? "",
    item.laborItemId ?? item.LaborItemId ?? "",
    item.laborId ?? item.LaborId ?? "",
    item.linePrice ?? item.LinePrice ?? "",
    item.quantity ?? item.Quantity ?? ""
  ].join("-")

  const [localStorageData, setLocalStorageData] = useState({})

  const loadLocalStorageDataSetup = () => {
    let data = JSON.parse(localStorage.getItem('selectedAllCarpetData') || '{}');
    if(data){
      setLocalStorageData(data)
    }
  }

  // function to upate material list data
  const updateMaterialList = (materialListData, localStorageData) => {
    let materialKeys = new Set(materialListData.map(generateKey))
    Object.values(localStorageData).flat().forEach(item => {
      if (!materialKeys.has(generateKey(item))) {
        let newEntry = Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key]
          return acc
        }, {})
        materialListData.push(newEntry);
      }
    })
  }

  useEffect(() => {
    if (localStorageData && Object.keys(localStorageData).length > 0) {
      updateMaterialList(materialListData, localStorageData)
    }
  }, [localStorageData, lsUpdate])

  const [ localStorageLaborData, setLocalStorageLaborData ] = useState([])

  // handle transform of required array
  const handleTransformArray = (arr) => {
    if (arr && arr.length > 0) {
      let newKeys = ['laborItemId', 'laborId', 'quantity', 'gpm', 'linePrice', 'orderInstallationDetailId', 'createdBy', 'unitPrice', 'labor', 'unitName', 'laborCategory']
      return arr.map(obj =>
        Object.fromEntries(Object.values(obj).map((value, index) => [newKeys[index] || `key${index}`, value]))
      );
    } else {
      return []
    }
  }

  // handle duplicates for grid
  const removeOlderDuplicates = (data) => {
    const uniqueMap = new Map();

    data
      .slice()
      .reverse()
      .forEach((item) => {
        const uniqueKey = [
          item.orderMaterialId ?? "",
          item.materialId ?? "",
          item.orderProductId ?? "",
          item.productId ?? "",
          item.orderLaborId ?? "",
          item.laborItemId ?? "",
          item.laborId ?? "",
        ].join("-");

        if (!uniqueMap.has(uniqueKey)) {
          uniqueMap.set(uniqueKey, item);
        }
      });

    // Update data array in-place
    data.splice(0, data.length, ...[...uniqueMap.values()].reverse());
  };

  // handle updated listing for grid
  const handleUpdateQualityGrid = () => {
    let tempProductData = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData'))
    let tempLaborData = JSON.parse(localStorage.getItem('copySelectedLaborList'))
    let modifiedTempLaborData = handleTransformArray(tempLaborData)
    orderInstallationListData.push(...(tempProductData?.labors ?? []), ...(modifiedTempLaborData ?? []))
    removeOlderDuplicates(orderInstallationListData)
  }

  const loadLocalStorageLaborDataSetup = () => {
    let data = JSON.parse(localStorage.getItem('copySelectedLaborList') || '{}');
    if(data){
      if (JSON.parse(localStorage.getItem('isQuality')) || !checkOrder) {
        handleUpdateQualityGrid()
      }
      setLocalStorageLaborData(data)
    }
  }

  const [ lsAllLaborMaterialCarpetData, setLsAllLaborMaterialCarpetData ] = useState({})

  // handle updated listing for grid
  const handleUpdateQualityListing = () => {
    let tempProductData = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData'))
    let tempData = JSON.parse(localStorage.getItem('selectedAllCarpetData'))
    materialListData.push(...(tempData ? Object.values(tempData).flat() : []), ...(tempProductData?.materials ?? []))
    removeOlderDuplicates(materialListData)
  }

  const loadLocalStorageLaborMaterialCarpetData = () => {
    let data = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData') || '{"materials":[],"labors":[]}');
    if(data){
      if (JSON.parse(localStorage.getItem('isQuality')) || !checkOrder) {
        handleUpdateQualityListing()
        handleUpdateQualityGrid()
      }
      setLsAllLaborMaterialCarpetData(data)
    }
  }

  // handle updated listing for grid
  const handleUpdateGrid = (orderInstallationData) => {
    let tempProductData = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData'))
    let tempLaborData = JSON.parse(localStorage.getItem('copySelectedLaborList'))
    let modifiedTempLaborData = handleTransformArray(tempLaborData)
    orderInstallationData.push(...(tempProductData?.labors ?? []), ...(modifiedTempLaborData ?? []))
    removeOlderDuplicates(orderInstallationData)
  }

  // handle updated listing for grid
  const handleUpdateGridList = (tempOrderMaterial) => {
    let tempProductData = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData'))
    let tempData = JSON.parse(localStorage.getItem('selectedAllCarpetData'))
    tempOrderMaterial.push(...(tempData ? Object.values(tempData).flat() : []), ...(tempProductData?.materials ?? []))
    removeOlderDuplicates(tempOrderMaterial)
  }

  // handle material listing api response
  useMemo(() => {
    if(orderMaterialData) {
      let tempOrderMaterial = orderMaterialData
      if (tempOrderMaterial.length  > 0 ) {
        tempOrderMaterial.map(data => {
          data.quantity = formatNumberWithTwoDigitsAfterDecimal(data.quantity)
          data.unitPrice = formatNumberWithTwoDigitsAfterDecimal(data.unitPrice)
          data.linePrice = formatNumberWithTwoDigitsAfterDecimal(data.linePrice)
        })
        tempOrderMaterial = Object.values(tempOrderMaterial)
      }
      if (!checkOrder) {
        handleUpdateGridList(tempOrderMaterial)
      }
      setMaterialListData(tempOrderMaterial)
    }
  }, [orderMaterialData])

  // handle order installation listing api response
  useMemo(() => {
    if(orderInstallationData) {
      let tempOrderInstallation = orderInstallationData
      if (tempOrderInstallation.length  > 0 ) {
        tempOrderInstallation.map(data => {
          data.quantity = formatNumberWithTwoDigitsAfterDecimal(data.quantity)
          data.unitPrice = formatNumberWithTwoDigitsAfterDecimal(data.unitPrice)
          data.linePrice = formatNumberWithTwoDigitsAfterDecimal(data.linePrice)
        })
        tempOrderInstallation = Object.values(tempOrderInstallation)
      }
      if (!checkOrder) {
        handleUpdateGrid(orderInstallationData)
      }
      setOrderInstallationListData(orderInstallationData)
    }
  }, [orderInstallationData])

  // clear storage data
  const clearStorageState = () => {
    localStorage.removeItem("selectedProductList")
    localStorage.removeItem("selectedLaborList")
    localStorage.removeItem("selectedMaterialList")
    localStorage.removeItem("selectedOrderLaborList")
  }

  //handle order save contract details data
  useEffect(() => {
    if(customerSaveContractDetails[0] === 'saved' && (materialContractModalOpen || laborContractModalOpen) && orderInstallationCarpetData) {
      setMaterialContractModalOpen(false)
      setLaborContractModalOpen(false)
      fetchOrderInstallationMaterial(auth[0].token, jobNum)
      fetchOrderInstallationOrder(auth[0].token, jobNum)
      setOrderInstallationCarpetData(false)
      clearStorageState()
    }
    if(customerSaveContractDetails[0] === 'not saved' && (materialContractModalOpen || laborContractModalOpen)) {
      toast.error(customerSaveContractDetails[0])
    }
  }, [customerSaveContractDetails])

  // handle delete material api response
  useEffect(() => {
    if(deletedOrderMaterialData[0] === 'deleted') {
      materialAndLaborListing()
      setConfirmDeleteModal(false)
    }
  }, [deletedOrderMaterialData])

  // handle delete labor api response
  useEffect(() => {
    if(deletedOrderLaborData[0] === 'deleted') {
      materialAndLaborListing()
      setConfirmDeleteModal(false)
    }
  }, [deletedOrderLaborData])

  // handle materialListData for listing
  useEffect(() => {
    if(materialListData && materialListData.length>0) {
      let totalUnitPrice = 0.00
      let totalLinePrice = 0.00
      materialListData.map(data => {
        totalUnitPrice += parseFloat(data.unitPrice) ? parseFloat(data.unitPrice) : parseFloat(data.price) ? parseFloat(data.price) : 0.00
        totalLinePrice += parseFloat(data.linePrice) ? parseFloat(data.linePrice) : 0.00
      })
      setTotalMaterialUnitPrice(totalUnitPrice)
      setTotalMaterialLinePrice(totalLinePrice)
    }
  }, [materialListData, materialContractModalOpen, pricingContractModalOpen])

  // handle laborListData for listing
  useEffect(() => {
    if(orderInstallationListData && orderInstallationListData.length>0) {
      let totalUnitPrice = 0.00
      let totalLinePrice = 0.00
      let totalOrgUnitPrice = 0.00
      let totalOrgLinePrice = 0.00
      orderInstallationListData.map(data => {
        totalUnitPrice += parseFloat(data.unitPrice) ? parseFloat(data.unitPrice) : parseFloat(data.price) ? parseFloat(data.price) : 0.00
        totalLinePrice += parseFloat(data.linePrice) ? parseFloat(data.linePrice) : 0.00

        totalOrgUnitPrice += parseFloat(data.unitCost) ? parseFloat(data.unitCost) : 0.00
        totalOrgLinePrice += parseFloat(data.lineCost) ? parseFloat(data.lineCost) : 0.00
      })
      setTotalLaborUnitPrice(totalUnitPrice)
      setTotalLaborLinePrice(totalLinePrice)
      setTotalLaborOrgUnitPrice(totalOrgUnitPrice)
      setTotalLaborOrgLinePrice(totalOrgLinePrice)
    }
  }, [orderInstallationListData, laborContractModalOpen, pricingContractModalOpen])

  // function to handle order total
  useMemo(() => {
    if(totalLaborUnitPrice, totalLaborLinePrice, totalMaterialUnitPrice, totalMaterialLinePrice) {
      let orderTotal = parseFloat(totalLaborLinePrice ? totalLaborLinePrice : 0.00) + parseFloat(totalMaterialLinePrice ? totalMaterialLinePrice : 0)
      setOrderTotal(orderTotal)
    }
  }, [totalLaborLinePrice, totalMaterialLinePrice])

  // function to handle saved add on data
  useEffect(() => {
    if (saveOrderAddOnDetails && saveOrderAddOnDetails.length > 0) {
      if (changeJobHeader === 'Add On' && saveOrderAddOnDetails[0] === 'AddOn Details Saved Successfully') {
        toast.success(saveOrderAddOnDetails[0])
        fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
        fetchOrderInstallationMaterial(auth[0].token, jobNum)
        fetchOrderInstallationOrder(auth[0].token, jobNum)
        handleMakeChangeClose()
      } else if (changeJobHeader === 'Add On' && saveOrderAddOnDetails[0]) {
        toast.error(saveOrderAddOnDetails[0])
      }
    }
  }, [saveOrderAddOnDetails])

  const handleUpdateCustomerDetail = () => {
		fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': id }, auth[0].userId, false, false, false);
	}

  // function called on input change event
	const handleChange = (e) => {
		const { id, value } = e.target
		setState({
      ...state,
      [id]: value
    })
    // Commented on 03-07-2024 for add on implementation
    // if (id === "newUnitId") {
    //   let payload = {
    //     UnitName: e.target.value,
    //     ContactInfoId: parseInt(contactInfoId),
    //     UnitId: parseInt(unitId)
    //   }
    //   saveCustomerOrderUnitId(auth[0].token, payload)
    // }
    // if (id === "newUnitTypeId") {
    //   let payload = {
    //     UnitTypeName: e.target.value,
    //     ContactInfoId: parseInt(contactInfoId),
    //     UnitTypeId: parseInt(customerOrderData[0].UnitTypeId) ? parseInt(customerOrderData[0].UnitTypeId) : 0
    //   }
    //   saveCustomerOrderUnitTypeId(auth[0].token, payload)
    // }
	}

  // function to handle reschedule job change
  const handleRescheduleChange = (e) => {
    const { id, value } = e.target
    setRescheduleData(
			{
				...rescheduleData,
				[id]: value
			}
		)
  }

  // function to open pricing contract modal
	const showPricingContractModal = () => {
    clearStorageState()
		setPricingContractModalOpen(true)
    setMaterialContractModalOpen(true)
	}

  // function to remove matching items
  const removeMatchingItems = (list, removedData) => {
    const removedKeys = new Set(removedData.laborData.map(item => generateKey(item)))
    list.forEach((item, index) => {
      if (removedKeys.has(generateKey(item))) {
        list.splice(index, 1)
      }
    })
  }

  const removeMatchingItem = (list, itemToRemove) => {
    const itemKey = generateKey(itemToRemove)
    list.forEach((item, index) => {
      if (generateKey(item) === itemKey) {
        list.splice(index, 1)
      }
    })
  }

	// function to close pricing contract modal
	const hidePricingContractModal = () => {
    clearStorageState()
		setPricingContractModalOpen(false)
    setMaterialContractModalOpen(false)
    setLaborContractModalOpen(false)
    if(localStorage.getItem("selectedAllCarpetData")){
      setLsUpdate(true)
      loadLocalStorageDataSetup()
    }
    if(localStorage.getItem("copySelectedLaborList")){
      setLsLaborUpdate(true)
      loadLocalStorageLaborDataSetup()
    }
    if(localStorage.getItem("selectedAllLaborMaterialCarpetData")){
      setLsLaborMaterialUpdate(true)
      loadLocalStorageLaborMaterialCarpetData()
    }
    if (orderInstallationListData && orderInstallationListData.length > 0 && JSON.parse(localStorage.getItem('isQuality'))) {
      let removedData = JSON.parse(localStorage.getItem('removedQualityPCData'))
      if (deleteOrderData) {
        removeMatchingItem(orderInstallationListData, deleteOrderData)
      }
      if (removedData) {
        removeMatchingItems(orderInstallationListData, removedData)
      }
    }
	}

  // function to add new material
  const handleClickNewMaterialItem = () => {
    clearStorageState()
    localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":true,"addNewMaterialItemClicked":true,"addNewLaborItem":false}))
    setPricingContractModalOpen(true)
    setMaterialContractModalOpen(true)
  }

  // function to add new labor
  const handleClickNewLabourItem = () => {
    changeReducerState()
    localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":true,"addNewMaterialItemClicked":false,"addNewLaborItem":true}))
    clearStorageState()
    setLaborContractModalOpen(true)
    setPricingContractModalOpen(true)
  }

  // handle edit change on material listing
  const handleMaterialListingEditChange = (value, data, filterValue) => {
    if(materialListData && materialListData.length > 0) {
      let materialData = materialListData
      materialData.map(editedData => {
        if((data.orderMaterialId || data.orderProductId) ? (data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId) : (data.materialId === editedData.materialId && data.productId === editedData.productId)) {
          if(filterValue === 'quantity') {
            editedData.quantity = value
            editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice ? editedData.unitPrice : editedData.price)).toFixed(2)
          }
          if(filterValue === 'unitPrice') {
            editedData.unitPrice = value
            editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.quantity)).toFixed(2)
          }
          if(filterValue === 'linePrice') {
            editedData.linePrice = value
            editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice ? editedData.unitPrice : editedData.price)).toFixed(2)
          }
        }
      })
      materialData = Object.values(materialData)
			setMaterialListData(materialData)
    }
  }
  
  // function call on modifying material kit listing
	const handleMaterialItemChange = (e, data, filterValue) => {
		handleMaterialListingEditChange(e.target.value, data, filterValue)
	}

  // handle edit change on labor listing
  const handleLaborListingEditChange = (value, data, filterValue) => {
    if(orderInstallationListData && orderInstallationListData.length > 0) {
      let laborData = orderInstallationListData
      laborData.map(editedData => {
        if(data.orderLaborId ? data.orderLaborId === editedData.orderLaborId : (data.laborItemId === editedData.laborItemId && data.laborId === editedData.laborId)) {
          if(filterValue === 'quantity') {
            editedData.quantity = value
            editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice ? editedData.unitPrice : editedData.price)).toFixed(2)
          }
          if(filterValue === 'unitPrice') {
            editedData.unitPrice = value
            editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.quantity)).toFixed(2)
          }
          if(filterValue === 'linePrice') {
            editedData.linePrice = value
            editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice ? editedData.unitPrice : editedData.price)).toFixed(2)
          }
        }
      })
      laborData = Object.values(laborData)
			setOrderInstallationListData(laborData)
    }
  }

  // function call on modifying labor listing
	const handleLaborItemChange = (e, data, filterValue) => {
		handleLaborListingEditChange(e.target.value, data, filterValue)
	}
  
  const hidePdfElem = (inputElem, cName, colIndex, showType) => {
    let table = inputElem.querySelector(`.${cName}`);

    if (table) {
      let thead = table.querySelector("thead tr");
      let tbodyRows = table.querySelectorAll("tbody tr:not(:last-child)"); // Exclude last row

      let colIndexes = colIndex; // 1st, 2nd, and 6th columns (0-based index)

      // Hide columns in thead
      colIndexes.forEach(index => {
          if (thead) {
              let th = thead.children[index];
              if (th) th.style.display = showType;
              if(showType ==='block'){
                if (th) th.removeAttribute("style")
              }
          }
      });

      // Hide columns in tbody (excluding last row)
      tbodyRows.forEach(row => {
          colIndexes.forEach(index => {
              let td = row.children[index];
              if (td) td.style.display = showType;
              if(showType ==='block'){
                if (td) td.removeAttribute("style")
              }
          });
      });
      // Select the second <td> (index 1) in the last row of <tbody> and clear its text
      let lastRow = inputElem.querySelector(`.${cName} tbody tr:last-child`);
      if (lastRow) {
          let secondTd = lastRow.children[1]; // 1-based index
          if (secondTd) {
              secondTd.querySelector("div").style.display = showType;
              if(showType ==='block'){
                secondTd.querySelector("div").removeAttribute("style")
              }
          }
          let fourthTd = lastRow.children[3]; // 1-based index
          if (fourthTd) {
              fourthTd.style.display = showType;
              if(showType ==='block'){
                fourthTd.removeAttribute("style")
              }
          }
      }

      // Select the <td> with colspan="5" and change it to colspan="2"
      let tmpColSpan = (cName === 'order-material-pdf') ? 5 : 4
      let tmpChangeSpan = (cName === 'order-material-pdf') ? 2 : 1
      if(showType === 'block'){
        tmpColSpan = (cName === 'order-material-pdf') ? 2 : 1
        tmpChangeSpan = (cName === 'order-material-pdf') ? 5 : 4
      }
      let colspanTd = inputElem.querySelector(`.${cName} tbody tr td[colspan='${tmpColSpan}']`);
      if (colspanTd) {
          colspanTd.setAttribute("colspan", tmpChangeSpan);
      }
    }
  }
  const handleSaveClick = () => {
    let pdfFile={}; let payload={}; let clientPdfFile={};
    const generatePDF =  () => {
      const pdfInput = document.getElementById("pdfcontainer")
      // const storeLogo = document.getElementById("store-pdf-logo")
      // Copy the container's HTML content
      const input = pdfInput.cloneNode(true); 
      const submitButton = input.querySelector("#submitpdf");
      const cancelButton = input.querySelector("#pdcancel");
      const materialbutton = input.querySelector("#pdfmaterialitem");
      const laborbutton = input.querySelector("#pdflaboritem");
      const materialitemaction=input.querySelector("#pdfactionmaterial")
      const laboritemaction=input.querySelector("#pdfactionlabor")
      const planElem = input.querySelector(".plan-img-container");
      if (planElem) {
          planElem.classList.replace("col-md-5", "col-md-10");
      }
      // remove scroll 
      input.querySelectorAll(".fixTableHead").forEach(element => element.classList.remove("fixTableHead"));

      if (input.querySelector("#store-pdf-logo")) {
          input.querySelector("#store-pdf-logo").style.display = "block"; // Change display to block
          input.querySelector(".store_phone").style.display = "block";
      }
      if (submitButton) submitButton.style.display = "none";
      // if (storeLogo) storeLogo.style.display = "block";
      if (cancelButton) cancelButton.style.display = "none";
      if (materialbutton) materialbutton.style.display = "none";
      if (laborbutton) laborbutton.style.display = "none";
      if (materialitemaction) materialitemaction.style.display = "none";
      if (laboritemaction) laboritemaction.style.display = "none";
      const buttons = input.querySelectorAll("button");
      buttons.forEach((button) => {
        button.style.display = "none";
      });
      const options = {
        margin: 0.2,
        filename: 'OrderDetails.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          scrollY: 0,
          scrollX:0
        },
        pagebreak: { before: '.pageBreak', mode: ['avoid-all', 'css', 'legacy'], avoid: 'svg' },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      };
      setTimeout( async () => {
        
        const clientPdfInput = input.cloneNode(true); 
        if (clientPdfInput) {
          // remove scroll 
          clientPdfInput.querySelectorAll(".fixTableHead").forEach(element => element.classList.remove("fixTableHead"));
          // hide only for client pdf
          hidePdfElem(clientPdfInput, 'order-material-pdf', [0,1,5,7], 'none');
          hidePdfElem(clientPdfInput, 'order-labor-pdf', [0,1,4,6], 'none');
          if (clientPdfInput.querySelector("#store-pdf-logo")) {
              clientPdfInput.querySelector("#store-pdf-logo").style.display = "block"; // Change display to block
              clientPdfInput.querySelector(".store_phone").style.display = "block";
          }
          let optionalElems = clientPdfInput.querySelectorAll(".pdf-optional");
          optionalElems.forEach(elem => elem.style.display = "none");
        }
        const pdfBlob = await html2pdf().from(input).set(options).output('blob');
        pdfFile = new File([pdfBlob], 'Orderdetails.pdf', { type: 'application/pdf' });
        const clientPdfBlob = await html2pdf().from(clientPdfInput).set(options).output('blob');
        clientPdfFile = new File([clientPdfBlob], 'ClientOrderPDF.pdf', { type: 'application/pdf' });
        // const blobURL = URL.createObjectURL(clientPdfBlob);
        // window.open(blobURL);
        payload= {
          OrderPDF: pdfFile,
          ClientPDF: clientPdfFile,
          InstallationData: JSON.stringify({
           UnitName: state.newUnitId,
          UnitType: state.newUnitTypeId,
          Notes: state.internalNotes ? state.internalNotes : "",
          InstallationInstracution: state.installerInstructions
            ? state.installerInstructions
            : "",
          ContactInfoId: parseInt(contactInfoId),
          OrderInstallationDetailId:
          customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          Description: state.description ? state.description : "",
          OldUnitId: parseInt(unitId),
          OldUnitType: parseInt(customerOrderData[0].UnitTypeId)
            ? parseInt(customerOrderData[0].UnitTypeId)
            : 0,
          })
        }; 
        saveCustomerOrderDetails(auth[0].token, payload);
        
          // html2pdf()
          //   .from(input)
          //   .set(options)
          //   .output('blob')
          //   .then((pdfBlob) =>{
          //     pdfFile = new File([pdfBlob], 'Orderdetails.pdf', { type: 'application/pdf' });
          //     payload= {
          //       OrderPDF: pdfFile,
          //       InstallationData: JSON.stringify({
          //        UnitName: state.newUnitId,
          //       UnitType: state.newUnitTypeId,
          //       Notes: state.internalNotes ? state.internalNotes : "",
          //       InstallationInstracution: state.installerInstructions
          //         ? state.installerInstructions
          //         : "",
          //       ContactInfoId: parseInt(contactInfoId),
          //       OrderInstallationDetailId:
          //       customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          //       Description: state.description ? state.description : "",
          //       OldUnitId: parseInt(unitId),
          //       OldUnitType: parseInt(customerOrderData[0].UnitTypeId)
          //         ? parseInt(customerOrderData[0].UnitTypeId)
          //         : 0,
          //       })
          //     }; console.log(payload.InstallationData)
          //     saveCustomerOrderDetails(auth[0].token, payload);
          //      }
          // );
      }, 1000); 
    };
    generatePDF();
  };

  // handle fetching of data using key
  const fetchValue = () => {
    let data = JSON.parse(localStorage.getItem('SaveQualityOrderReason'))
    const key = "OrderQualityDetailId"
    const item = data.find((item) => item.startsWith(`${key} :`));
    if (item) {
      const value = item.split(":")[1]?.trim();
      return parseInt(value) || null;
    }
    return null;
  };

  const handleQualitySaveClick = () => {

    let productList = []
    let materialList = []
    let laborList = []
    materialListData.map(data => {
      if(data.orderProductId) {
        productList.push({
          ProductId: data.productId,
          Quantity: parseFloat(data.quantity),
          GPM: parseFloat(data.gpm),
          LinePrice: parseFloat(data.linePrice),
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: parseFloat(data.unitPrice),
        })
      }
      if(data.orderMaterialId) {
        materialList.push({
          MaterialId: parseFloat(data.materialId),
          Product: parseFloat(data.productId),
          Quantity: parseFloat(data.quantity),
          GPM: parseFloat(data.gpm),
          LinePrice: parseFloat(data.linePrice),
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: parseFloat(data.unitPrice),
        })
      }
    })
    if(orderInstallationListData && orderInstallationListData.length>0) {
      orderInstallationListData.map(data => {
        laborList.push({
          LaborItemId: data.laborItemId,
          LaborId: data.laborId,
          Quantity: data.quantity,
          GPM: data.gpm,
          LinePrice: data.linePrice,
          OrderInstallationDetailId: customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId,
          CreatedBy: parseFloat(auth[0].userId),
          UnitPrice: data.unitPrice,
        })
      })
    }

    const payload = {
      "jobNo": parseInt(JSON.parse(localStorage.getItem('quality-JobNum'))),
      "orderInstallationDetail": {
          "InstallationDate": customerOrderData[0]?.orderInstallationDetails?.InstallationDate ? customerOrderData[0]?.orderInstallationDetails?.InstallationDate : "",
          "Occupaied": customerOrderData[0]?.orderInstallationDetails?.Occupaied ? customerOrderData[0]?.orderInstallationDetails?.Occupaied : ""          ,
          "PurchaseOrder": customerOrderData[0]?.orderInstallationDetails?.PurchaseOrder ? customerOrderData[0]?.orderInstallationDetails?.PurchaseOrder : "",
          "Instruction": customerOrderData[0]?.orderInstallationDetails?.Instruction ? customerOrderData[0]?.orderInstallationDetails?.Instruction : "",
          "FullName": customerOrderData[0]?.orderInstallationDetails?.FullName ? customerOrderData[0]?.orderInstallationDetails?.FullName: "",
          "Email": customerOrderData[0]?.orderInstallationDetails?.Email ? customerOrderData[0]?.orderInstallationDetails?.Email : "",
          "PhoneNo": customerOrderData[0]?.orderInstallationDetails?.PhoneNo ? customerOrderData[0]?.orderInstallationDetails?.PhoneNo : "",
          "ContactInfoId": customerOrderData[0]?.orderInstallationDetails?.ContactInfoId ? customerOrderData[0]?.orderInstallationDetails?.ContactInfoId : "",
          "CreatedBy": customerOrderData[0]?.orderInstallationDetails?.CreatedBy ? parseFloat(customerOrderData[0]?.orderInstallationDetails?.CreatedBy) : "",
          "ProductStructureId": customerOrderData[0]?.orderInstallationDetails?.ProductStructureId ? customerOrderData[0]?.orderInstallationDetails?.ProductStructureId : "",
          "UnitId":  customerOrderData[0]?.orderInstallationDetails?.UnitId ? customerOrderData[0]?.orderInstallationDetails?.UnitId : "",
          "UnitTypeId": customerOrderData[0]?.orderInstallationDetails?.UnitTypeId ? customerOrderData[0]?.orderInstallationDetails?.UnitTypeId : "",
          "InternalNotes": state.internalNotes ? state?.internalNotes : "",
          "InstallerInstruction": state?.installerInstructions ? state?.installerInstructions : "",
          "orderQualityDetailId": JSON.parse(localStorage.getItem('SaveQualityOrderReason')) ? fetchValue() : null,
          "Description": state.description ? state.description : "",
          "uniqueChannelName": JSON.parse(localStorage.getItem('geoErp'))[0]?.uniqueChannelName,
          "MeasureImagePng": customerOrderData[0]?.orderInstallationDetails?.MeasureImagePng,
          "UnitName": state?.newUnitId ? state?.newUnitId : "",
          "UnitTypeName": state?.newUnitTypeId ? state?.newUnitTypeId : "",
          "tax": state?.tax ? state?.tax : ""
      },
      "orderQualityProduct": productList,
      "orderQualityMaterial": materialList,
      "orderQualityLabor": laborList,
    }
    saveQualityOrderDetail(auth[0].token, payload)

    localStorage.setItem("Qualityorder",0)
   }

  //function to save contract for order details
  const saveContractForOrderDetails = (data, bool) => {
    setOrderInstallationCarpetData(bool)
    saveCustomerOrderDetailsForPricingContract(auth[0].token, data)
  }

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteOrderData('')
    setConfirmDeleteModal(false)
  }

  // remove from secondary product data
  const removeEntries = (dataObject, removedItems, label) => {
    Object.keys(dataObject).forEach(key => {
      if (!Array.isArray(dataObject[key])) return
      const removedKeys = new Set(removedItems.map(generateKey))
      dataObject[key] = dataObject[key].filter(item => !removedKeys.has(generateKey(item)))
    });
  };

  // Remove from product data
  const removeFromSelectedCarpetData = (selectedData, removedItems, productToRemove) => {
    const productIdToRemove = deleteOrderData.productId;

    Object.keys(selectedData).forEach(key => {
      selectedData[key] = selectedData[key].filter(item => item.productId !== productIdToRemove);

      if (selectedData[key].length === 0) {
        delete selectedData[key];
      }
    })
  }

  // function to filter list
  const filterSelectedLaborList = (tempCopySelectedLaborList, removedItems) => {
    if (!Array.isArray(tempCopySelectedLaborList)) {
      return tempCopySelectedLaborList;
    }
    const removedKeys = new Set(removedItems.map(generateKey));
    return tempCopySelectedLaborList.filter(item => !removedKeys.has(generateKey(item)));
  }

  // Remove from labor data
  const removeLaborData = (tempCopySelectedLaborList, removedItems) => {
    const updatedList = filterSelectedLaborList(tempCopySelectedLaborList, removedItems);
    return updatedList
  }

  // function to update listing on grid updation
  const handleUpdateGridData = (removedItems) => {
    let tempSelectedCarpetData = JSON.parse(localStorage.getItem("selectedAllCarpetData")) || {}
    let tempAllLaborMaterialCarpetData = JSON.parse(localStorage.getItem("selectedAllLaborMaterialCarpetData")) || {}
    let tempCopySelectedLaborList = JSON.parse(localStorage.getItem("copySelectedLaborList")) || {}
    // Call function to remove matching entries
    removeFromSelectedCarpetData(tempSelectedCarpetData, removedItems, "tempSelectedCarpetData");
    removeEntries(tempAllLaborMaterialCarpetData, removedItems, "tempAllLaborMaterialCarpetData");
    tempCopySelectedLaborList = removeLaborData(tempCopySelectedLaborList, removedItems);

    localStorage.setItem("selectedAllCarpetData", JSON.stringify(tempSelectedCarpetData));
    localStorage.setItem("selectedAllLaborMaterialCarpetData", JSON.stringify(tempAllLaborMaterialCarpetData));
    localStorage.setItem("copySelectedLaborList", JSON.stringify(tempCopySelectedLaborList));
  }

  // function to delete data
  const removeDeletedEntry = (productData = {}, laborData = [], deleteOrderData = {}) => {

    const uniqueKey = generateKey(deleteOrderData)

    const storedRemovedData = localStorage.getItem('removedQualityPCData');
    let removedEntries = storedRemovedData ? JSON.parse(storedRemovedData) : { labors: [], materials: [], laborData: [] };

    const removeMatchingEntriesInPlace = (arr, label) => {
      if (!Array.isArray(arr)) return arr

      const { filteredArray, removedItems } = arr.reduce(
        (acc, item) => {
          const itemKey = generateKey(item)
          if (itemKey === uniqueKey) {
            acc.removedItems.push(item)
          } else {
            acc.filteredArray.push(item)
          }
          return acc
        },
        { filteredArray: [], removedItems: [] }
      )


      removedEntries[label] = [...removedEntries[label], ...removedItems]
      localStorage.setItem('removedQualityPCData', JSON.stringify(removedEntries))
      handleUpdateGridData(removedItems)
      return filteredArray
    }

    if (productData.labors) productData.labors = removeMatchingEntriesInPlace(productData.labors, "labors")
    if (productData.materials) productData.materials = removeMatchingEntriesInPlace(productData.materials, "materials")
    if (Array.isArray(laborData)) laborData = removeMatchingEntriesInPlace(laborData, "laborData")

  }

  // function to remove order data
  const removeDeletedOrderData = () => {
    let tempProductData = JSON.parse(localStorage.getItem('selectedAllLaborMaterialCarpetData')) || []
    let tempLaborData = JSON.parse(localStorage.getItem('copySelectedLaborList')) || []
    removeDeletedEntry(tempProductData, tempLaborData, deleteOrderData)
    localStorage.setItem('selectedAllLaborMaterialCarpetData', JSON.stringify(tempProductData))
    localStorage.setItem('copySelectedLaborList', JSON.stringify(tempLaborData))
    let tempCopySelectedLaborList = JSON.parse(localStorage.getItem("copySelectedLaborList")) || {}
    tempCopySelectedLaborList = removeLaborData(tempCopySelectedLaborList, [deleteOrderData])
    localStorage.setItem("copySelectedLaborList", JSON.stringify(tempCopySelectedLaborList))
  }

  // handle grid data removal
  const removeGridData = () => {
    if(deleteOrderData.orderMaterialId || deleteOrderData.materialId) {
      setMaterialListData(prev => prev.filter(item => item.orderMaterialId !== deleteOrderData.orderMaterialId || item.materialId !== deleteOrderData.materialId))
    } else if(deleteOrderData.orderProductId || deleteOrderData.productId && !deleteOrderData.orderLaborId) {
      setMaterialListData(prev => prev.filter(item => item.orderProductId !== deleteOrderData.orderProductId || item.productId !== deleteOrderData.productId))
    } else {
      setOrderInstallationListData(prev => prev.filter(item =>!(item.laborItemId === deleteOrderData.laborItemId && item.laborId === deleteOrderData.laborId)
      ))
    }
    if (JSON.parse(localStorage.getItem('isQuality')) || !checkOrder) {
      removeDeletedOrderData()
    } else {
      localStorage.removeItem('removedQualityPCData')
    }
  }

  // function to check if key is present
  const hasValidOrderKey = (obj) => {
    const keysToCheck = ['orderMaterialId', 'orderProductId', 'orderLaborId'];
    return keysToCheck.some(key => obj.hasOwnProperty(key) && obj[key] > 0);
  }

  // function called for delete api
  const handleConfirmDeleteSubmit = () => {
    if (!hasValidOrderKey(deleteOrderData) && (JSON.parse(localStorage.getItem('isQuality')) || !checkOrder)) {
      removeGridData()
      setConfirmDeleteModal(false)
    } else if (hasValidOrderKey(deleteOrderData) && !JSON.parse(localStorage.getItem('isQuality'))) {
      let checkFlag = deleteOrderData.orderMaterialId ? true : false
      if(deleteOrderData.orderMaterialId) {
        deleteOrderMaterialData(auth[0].token, deleteOrderData.orderMaterialId, checkFlag)
      } else if(deleteOrderData.orderProductId) {
        deleteOrderMaterialData(auth[0].token, deleteOrderData.orderProductId, checkFlag)
      } else {
        deleteOrderLaborData(auth[0].token, deleteOrderData.orderLaborId)
      }
    } else if (JSON.parse(localStorage.getItem('isQuality')) || !checkOrder) {
      removeGridData()
      setConfirmDeleteModal(false)
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (data) => {
    if(data.orderLaborId) {
      setDeleteOrder('labor')      
    } else {
      setDeleteOrder('material')
    }
    setDeleteOrderData(data)
    setConfirmDeleteModal(true)
  }

  // handle remove material data
  const handleMaterialDeleteClick = (data) => {
    showConfirmDeleteModal(data)
  }

  // handle remove labor data
  const handleLaborDeleteClick = (data) => {
    showConfirmDeleteModal(data)
  }

  // handle cancel click
  const handleCancelClick = () => {
    navigate(`/place-order`)
    localStorage.setItem("Qualityorder",0)
  }

  // function to format a number with commas every 3 digits
  const handleFormat = (number) => {
    if (!number) return 0.00;
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const handleChangeUpdateShortageReasons = (e, item) =>{
    
    const { checked } = e.target;
    if(checked){
      let payload = {
        "orderShortageReasonId": item.orderShortageReasonId,
        "shortageReasonId": item.shortageReasonId,
        "orderShortageDetailId": item.orderShortageDetailId,
        "isSelected": true,
      }
      updateShortageReasonRequest(auth[0].token, payload)
      setSelectedCheckbox(selectedCheckbox => [...selectedCheckbox,item.shortageReasonId,]);
    } else {
      let payload = {
        "orderShortageReasonId": item.orderShortageReasonId,
        "shortageReasonId": item.shortageReasonId,
        "orderShortageDetailId": item.orderShortageDetailId,
        "isSelected": false,
      }
      updateShortageReasonRequest(auth[0].token, payload)
      setSelectedCheckbox(selectedCheckbox => selectedCheckbox.filter(d => d !== item.shortageReasonId));
    }
  }

  // handle update quality reason
  const handleUpdateQualityReason = (e, item) =>{
    const { checked } = e.target;
    if(checked){
      let payload = {
        "orderQualityReasonId": item.orderQualityReasonId,
        "qualityReasonId": item.qualityReasonId,
        "orderQualityDetailId": item.orderQualityDetailId,
        "isSelected": true,
        "qualityReason": item.qualityReason
      }
      updateQualityReason(auth[0].token, payload)
      setSelectedQualityCheckbox(selectedQualityCheckbox => [...selectedQualityCheckbox, item.orderQualityReasonId]);
    } else {
      let payload = {
        "orderQualityReasonId": item.orderQualityReasonId,
        "qualityReasonId": item.qualityReasonId,
        "orderQualityDetailId": item.orderQualityDetailId,
        "isSelected": false,
        "qualityReason": item.qualityReason
      }
      updateQualityReason(auth[0].token, payload)
      setSelectedQualityCheckbox(selectedQualityCheckbox => selectedQualityCheckbox.filter(d => d !== item.orderQualityReasonId));
    }
  }

  // no. of checkboxes in a row/column
  const chunkArray = (array, size) =>
    array.reduce((acc, _, i) =>
      i % size === 0 ? [...acc, array.slice(i, i + size)] : acc, []);

  // Reason for Shortage checkboxs
  const ShortageReasonsComponent = ( shortageReasons ) => {
    const itemsPerColumn = 4;
    const chunks = chunkArray(shortageReasons, itemsPerColumn);

    return (
      <Row>
        {chunks.map((chunk, chunkIndex) => (
          <Col md={4} key={`chunk-${chunkIndex}`}>
            <Form.Group>
              {chunk.map((item, itemIndex) => (
                <Form.Check
                  key={`item-${item.shortageReasonId}-${itemIndex}`}
                  type="checkbox"
                  id={`custom-switch-${item.shortageReasonId}`}
                  onChange={(e) => handleChangeUpdateShortageReasons(e, item)}
                  checked={selectedCheckbox.some(d => d === item.shortageReasonId) || item.isSelected}
                  label={item.shortageReason}
                />
              ))}
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  // reason for qulaity checkbox
  const handleQualityReasons = (qualityReasons) => {
    const itemsPerColumn = 4;
    const chunks = chunkArray(qualityReasons, itemsPerColumn);

    return (
      <Row>
        {chunks.map((chunk, chunkIndex) => (
          <Col md={4} key={`chunk-${chunkIndex}`}>
            <Form.Group>
              {chunk.map((item, itemIndex) => (
                <Form.Check
                  key={`item-${item.orderQualityReasonId}-${itemIndex}`}
                  type="checkbox"
                  id={`custom-switch-${item.orderQualityReasonId}`}
                  onChange={(e) => handleUpdateQualityReason(e, item)}
                  checked={selectedQualityCheckbox.some(d => d === item.orderQualityReasonId) || item.isSelected}
                  label={item.qualityReason}
                />
              ))}
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  // Create a Date object from the string
  function extractDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formattedDate = `${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}-${year}`;
    return formattedDate;
  }

  // handle click make change
  const handleMakeChangeModalClose = (e) => {
    e.preventDefault()
    if (rescheduleData.RescheduleChoicesId && startDate) {
      let payload = {
        "JobNo": parseInt(jobNum),
        "Reason": rescheduleData.RescheduleChoicesId,
        "Note": rescheduleData.changeJobNotes,
        "OrderInstallationDate": startDate ? extractDate(startDate) : null,
        "CreatedBy": parseFloat(auth[0].userId)
      }
      saveCustomerOrderRescheduleData(auth[0].token, payload)
    } else {
      if (!rescheduleData.RescheduleChoicesId) {
        toast.error('Rescheduled reason is required')
      } else if (!startDate) {
        toast.error('Rescheduled date is required')
      }
    }
  }

  // handle installation date
  const handleInstallationDate = (customerOrderData) => {
    let tempDate = customerOrderData?.orderInstallationDetails && customerOrderData?.orderInstallationDetails.InstallationDate ? handleFormatDate(customerOrderData?.orderInstallationDetails.InstallationDate) : ""
    return tempDate
  }

  // handle rescheduled data
  const handleFetchRescheduledData = () => {
    fetchCustomerOrderRescheduledData(auth[0].token, customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId)
  }

  // function to handle Reschedule click
  const handleReschedule = () => {
    handleFetchRescheduledData()
    setChangeJobHeader('Reschedule')
  }

  // handle shortage data
  const handleGetShortageReason = () => {
    getShortageReasonRequest(auth[0].token, '')
  }

  // function to handle Shortage Click
  const handleShortage = () => {
    handleGetShortageReason()
    setChangeJobHeader('Shortage')
  }

  // handle quality
  const handleQuality = () => {
    getQualityReasonRequest(auth[0].token, '')
    localStorage.setItem('isQuality', true)
    setChangeJobHeader('Quality')
  }

  // handle click property name
  const handlePropertyClickForOrdering = (customer) => {
    setProcessStart(true)
    let url = customer?.crossDomainLink
    localStorage.setItem("place-order-contactInfoId", customer?.contactInfoId)
    let modified_url = url.replace("select-plan", "property-options")
    let authValue = JSON.parse(localStorage.getItem('geoErp'))
    let uniqueChannelName = `${modified_url}&uniqueChannelName=${authValue[0].uniqueChannelName}&editUnitId=${state.newUnitId}&editUnitTypeId=${state.newUnitTypeId}`
    setIsOrderingUrl(uniqueChannelName)
    // handlePropertyModalOpen()
  }

  // useMemo(() => {
  //   if (processStart) {
  //     let authValue = JSON.parse(localStorage.getItem('geoErp'))
  //     let channel = pushervalue.subscribe(authValue[0]?.uniqueChannelName);
  //     localStorage.setItem('Existing_Orders', JSON.parse(false))
  //     channel.bind("update-order-status", (data) => {
  //       if(data?.PusherTrigger === "installation-detail-status") {
  //         // navigate(`/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${data?.UnitId}/jobNum=${data?.JobNum}`)
  //         window.location.href = `http://localhost:3000/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${data?.UnitId}/jobNum=${data?.JobNum}`
  //         // handlePropertyModalClose()
  //       }
       
  //     });
  //   }
  // }, [processStart]);

  useEffect(() => {
    if (processStart) {
      let authValue = JSON.parse(localStorage.getItem('geoErp'));
      let channel = pushervalue.subscribe(authValue[0]?.uniqueChannelName);
      localStorage.setItem('Existing_Orders', JSON.stringify(false));
     
      channel.bind("update-order-status", (data) => {
        if (data?.PusherTrigger === "installation-detail-status") {
          toast.success('Order Successful')
          let quality_response = data ? data : null
          localStorage.setItem('quality-order', JSON.stringify(quality_response))
          navigate(`/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${data?.UnitId ? data?.UnitId : 0}/jobNum=${data?.JobNum ? data?.JobNum : 0}`);
          navigate(0);
        }
				if(data?.PusherTrigger === "close-ordering-popup") {
					localStorage.setItem('Existing_Orders', JSON.parse(true))
          localStorage.setItem('Existing-Orders-Page', JSON.parse(true))
          localStorage.setItem('isQuality', false)
          navigate(`/place-order/order-confirmation/contactInfoId=${localStorage.getItem("place-order-contactInfoId")}/unitId=${parseInt(JSON.parse(localStorage.getItem('quality-UnitId')))}/jobNum=${parseInt(JSON.parse(localStorage.getItem('quality-JobNum')))}`)
          setCheckOrder(false)
          fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
          window.location.reload();
				}
      });
    }
  }, [processStart]);

  // handle Odering model
  const handleOrdering = () => {
    handleMakeChangeModalOpen()
    handlePropertyClickForOrdering(customerData[0])
    setChangeJobHeader('Ordering')
  }

  useEffect(() => {
    if (saveOrderShortage && saveOrderShortage.result) {
      if (changeJobHeader === 'Shortage' && saveOrderShortage.result[0] === 'Order shortage saved successfully') {
        toast.success(saveOrderShortage.result[0])
        fetchOrderInstallationDetail(auth[0].token, parseInt(jobNum) ? jobNum : qualityJobNo(), parseInt(jobNum) ? orderedPlaced() : true)
        fetchOrderInstallationMaterial(auth[0].token, jobNum)
        fetchOrderInstallationOrder(auth[0].token, jobNum)
        handleMakeChangeClose()
      } else if (changeJobHeader === 'Shortage' && saveOrderShortage.error) {
        toast.error(saveOrderShortage.error)
      }
    }
  }, [saveOrderShortage])

  const handleAddOn = () => {
    fetchCustomerOrderAddOnDetails(auth[0].token, jobNum)
    localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":true,"addNewMaterialItemClicked":false,"addNewLaborItem":false}))
    setChangeJobHeader('Add On')
  }

  useEffect(() => {
    if(localStorage.getItem("addOnModal")){
      localStorage.setItem("addOnModal",JSON.stringify({"addOnModalOpen":false,"addNewMaterialItemClicked":false,"addNewLaborItem":false}))
    }
    loadLocalStorageDataSetup()
    loadLocalStorageLaborDataSetup()
  },[])

  // handle reschedule cancel button
  const handleRescheduleCancel = () => {
    setChangeJobHeader('Change Job')
    setStartDate()
    setRescheduleData({
      'RescheduleChoicesId': '',
      'changeJobNotes': ''
    })
    localStorage.removeItem('isQuality')
  }

  // handle add on form data
  const handleAddOnFormChange = (e) => {
    const { id, value } = e.target
    setAddOnData({
      ...addOnData,
      [id]: value
    })
  }

  // handle addon submit details
  const handleAddOnSubmit = (data) => {
    if (addOnData.approvedBy && addOnData.approvedBy.length > 0) {
      let payload = {
        "JobNo": parseInt(jobNum),
        "orderAddOnDetail": {
          "ApprovedBy": addOnData.approvedBy,
          "Description": addOnData.description,
          "EnteredBy": auth[0].userId
        },
        "addOnProducts": data.addOnProducts,
        "addOnMaterials": data.addOnMaterials,
        "addOnLabors": data.addOnLabors
      }
    saveCustomerOrderAddOnDetails(auth[0].token, payload)
    } else {
      toast.error('Please fill all the required fields')
    }
  }

  // handle addon submit details
  const handleorderShortageSubmit = (data) => {
    if (data) {
      let updatedData = {
        ...data,
        orderShortageDetail: {
          ...data.orderShortageDetail,
          enteredBy: auth[0].userId, 
        },
      };
      saveOrderShortageRequest(auth[0].token, updatedData)
    } else {
      toast.error('Please fill all the required fields')
    }
  }

  // handle add on cancel
  const handleAddOnCancel = () => {
    setChangeJobHeader('Change Job')
    setAddOnData({
      'approvedBy': '',
      'description': ''
    })
    fetchOrderInstallationMaterial(auth[0].token, jobNum)
    fetchOrderInstallationOrder(auth[0].token, jobNum)
    localStorage.removeItem('isQuality')
  }

  // function to handle sum of line price
  const handleAddOnSum = (addOnData) => {
    let sum = 0
    if (addOnData && addOnData.length > 0) {
      addOnData.map(data => {
        if (data?.linePrice) {
          sum += data.linePrice
        }
      })
    }
    return sum
  }

  // Function to handle add on total
  const handleAddOnTotal = (data) => {
    let dataLength = Object.keys(data).length;
    if (dataLength && dataLength > 0) {
      let addOnTotal = 0;
      let addOnOrgTotal = 0
      if (data?.products && data?.products.length > 0) {
        let productSum = handleAddOnSum(data?.products)
        addOnTotal += productSum
        // addOnOrgTotal += productSum
      }
      if (data?.materials && data?.materials.length > 0) {
        let materialSum = handleAddOnSum(data?.materials)
        addOnTotal += materialSum
      }
      if (data?.labors && data?.labors.length > 0) {
        let laborSum = handleAddOnSum(data?.labors)
        addOnTotal += laborSum

        data?.labors.map(item => {
          if (item?.linePrice) {
            addOnOrgTotal += item.lineCost
          }
        })
      }

      return [addOnTotal.toFixed(2), addOnOrgTotal.toFixed(2)]
    }
  }

  // function to calculate the totalPrice 
  const calculateTotal = (material, product, labor) =>{
    let totalAmount = 0;
    let totalOrgAmount = 0;
    if(material.length !== 0){
      for(let obj of material){
        totalAmount += parseFloat(obj?.linePrice)
      }
    }
    if(product.length !== 0){
      for(let obj of product){
        totalAmount +=  parseFloat(obj.linePrice)
        // totalOrgAmount += parseFloat(obj.linePrice)
      }
    }
    if(labor.length !== 0){
      for(let obj of labor){
        totalAmount +=  parseFloat(obj.linePrice)
        totalOrgAmount +=  parseFloat(obj.lineCost)
      }
    }
    return [parseFloat(totalAmount).toFixed(2), parseFloat(totalOrgAmount).toFixed(2)];
  }

  //function to calculate quality total
  const handleQualityTotal = (customerQualitydata, qualityType) => {
    const calculateTotal = (data, key) =>
      (data || []).reduce((sum, obj) => sum + parseFloat(obj?.[key] || 0), 0);

    let totalAmount = calculateTotal(customerQualitydata?.orderQualityLabor, qualityType);
    if(qualityType === 'unitPrice' || qualityType === 'linePrice'){
      totalAmount += calculateTotal(customerQualitydata?.orderQualityProduct, qualityType);
      totalAmount += calculateTotal(customerQualitydata?.orderQualityMaterial, qualityType);
    }
    return totalAmount.toFixed(2);
  }

  // function to tract state change
  const handleStateChange = () => {
    setIsStateChanged(!isStateChanged)
  }
  const prevImageUrl = useRef(null); // Store previous image URL
  const [base64Image, setBase64Image] = useState("");
  const ImageContainer = ({ imageUrl, customerOrderData }) => {
    

    //  Convert createdOn date only once when data changes
    useEffect(() => {
        const createdOn = customerOrderData?.[0]?.orderInstallationDetails?.CreatedOn;
        if (createdOn) {
          const [date, time] = createdOn.split('T');
          const formattedTime = time ? time.slice(0, 5) : ''; 
          setCreatedDate(date);
          setCreatedTime(formattedTime);
        }
        else{
          const orderNow = new Date();
          const usDateFormat = orderNow.toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "UTC"
          });
          const usTimeFormat = orderNow.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "UTC",
            hour12: true, // Ensures AM/PM format
          });
          setCreatedDate(usDateFormat);
          setCreatedTime(usTimeFormat);
        }
    }, [customerOrderData]); //  Runs only when `customerOrderData` changes

    // Convert Image URL to Base64
    
    useEffect(() => {
        if (imageUrl && prevImageUrl.current !== imageUrl) {
            prevImageUrl.current = imageUrl; // Update previous URL
            fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => setBase64Image(reader.result);
                    reader.readAsDataURL(blob);
                })
                .catch((error) => console.error("Error converting image to Base64:", error));
        }
    }, [imageUrl]); // Runs only when `imageUrl` changes

    return (
        <img id="floorimage"
            className="img-fluid view-img"
          src={base64Image}
            alt="Floorplan"
        />
    );
  };

  const [ordBase64Image, setOrdBase64Image] = useState("");
  const LogoImageContainer = ({ imageUrl }) => {
    // Convert Image URL to Base64
    if (!imageUrl) return true;
    useEffect(() => {
        if (imageUrl) {
            fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => setOrdBase64Image(reader.result);
                    reader.readAsDataURL(blob);
                })
                .catch((error) => console.error("Error converting image to Base64:", error));
        }
    }, [imageUrl]); // Runs only when `imageUrl` changes
    const storePhone = customerData?.[0]?.storeContactNo;
    return (
      <>
        {ordBase64Image && (<><img id="store-pdf-logo"
          className="img-fluid view-img"
          src={ordBase64Image}
          alt="Store Logo"
          style={{'display':'none', 'width': '200px'}}
        />
        <div className="store_phone" style={{'display':'none'}}>
        <div className="d-flex align-items-center gap-2 w-100 justify-content-center p-1">
        <Icon size={16} icon={ic_phone}/>
          <span className="storePhone">{storePhone ? storePhone : ''}</span>
        </div>
        </div></>)}
      </>
    );
  };

  // function to handle quality date
  const handleQualityInstallationDate = (data) => {
    return formatDate(data?.orderQualityDetail?.installationDate)
  }

  // function to handle quality status data
  const handleQualityStatus = (data) => {
    return data?.orderQualityDetail?.qualityStatus
  }

  // function to handle quality status change
  const handleQualityInstallationChange = (date, customerQualitydata) => {
    let payload = {
      "installationDate": date ? extractDate(date) : null,
      "status": customerQualitydata?.orderQualityDetail?.qualityStatus,
      "orderQualityDetailId": customerQualitydata?.orderQualityDetail?.orderQualityDetailId
    }
    updateQualityDetail(auth[0].token, payload)
  }

  // function to handle quality status change
  const handleQualityStatusChange = (e, customerQualitydata) => {
    const { value } = e.target
    let payload = {
      "installationDate": formatDate(customerQualitydata?.orderQualityDetail?.installationDate),
      "status": value,
      "orderQualityDetailId": customerQualitydata?.orderQualityDetail?.orderQualityDetailId
    }
    updateQualityDetail(auth[0].token, payload)
  }

  return (
    <MainWrapper id="pdfmainwrapper">
      {isProcessing || isLoading || isOrderLoading ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}
      <Container id="pdfcontainer" className="overflow-visible" >
        {customerOrderData && customerOrderData.map((data, index) => (
          <Card className="mb-7" key={index} >
            <Card.Body >
              { checkOrder && !isQualityPlaced &&
                <div className="make-changes text-end mb-4">
                  <div data-bs-toggle="modal" onClick={handleMakeChangeClick} role="button" className="btn btn-primary">
                    Make Changes
                  </div>
                </div>
              }
              <Row className="justify-content-center">
                <Col md={9}>
                  <div className="order-detail">
                    {customerData?.[0]?.storeLogo && (<Row className="my-3">
                      <Col md={6}>
                        <div className="plan-img">
                          <LogoImageContainer imageUrl={customerData?.[0]?.storeLogo}/>
                        </div>
                      </Col>
                    </Row>)}
                    <h3 className="mb-4">Order Details</h3>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="inst-date" className="col-md-4">Property Name</Form.Label>
                      <span className="value col-md-8" id="propertyname">{data?.orderInstallationDetails?.ShipToPropertyName}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">Address</Form.Label>
                      <span className="value col-md-8" id="address">{data?.orderInstallationDetails?.ShipToAddress}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">City</Form.Label>
                      <span className="value col-md-8" id="city">{data?.orderInstallationDetails?.ShipToCity}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">State</Form.Label>
                      <span className="value col-md-8" id="state">{data?.orderInstallationDetails?.ShipToState}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">Zip</Form.Label>
                      <span className="value col-md-8" id="zip">{data?.orderInstallationDetails?.ShipToZip}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="name" className="col-md-4">Requestor Name</Form.Label>
                      <span className="value col-md-8" id="name">{data?.orderInstallationDetails?.RequesterName}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="email" className="col-md-4">Requestor Email</Form.Label>
                      <span className="value col-md-8" id="email">{data?.orderInstallationDetails?.RequesterEmail}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="phone" className="form-label col-md-4">Requestor Phone Number</Form.Label>
                      <span className="value col-md-8" id="phone">{data?.orderInstallationDetails?.PhoneNo}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">Unit#</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control col-md-8" name="newUnitId" id="newUnitId" onChange={handleChange} value={state.newUnitId} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">Unit Type</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="newUnitTypeId" id="newUnitTypeId" onChange={handleChange} value={state.newUnitTypeId} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">Order Date & Time</Form.Label>
                      <span className="value col-md-8" id="inst-date">{(createdDate || createdTime) && `${createdDate} ${createdTime} UTC`}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="inst-date" className="form-label col-md-4">Installation Date</Form.Label>
                      <span className="value col-md-8" id="inst-date">{handleInstallationDate(data)}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="description" className="form-label col-md-4">Description</Form.Label>
                      <div className="col-md-8">
                        <Form.Control as="textarea" className="form-control" name="description" id="description" onChange={handleChange} value={state.description} row={3} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="occupied" className="form-label col-md-4">Occupied</Form.Label>
                      <span className="value col-md-8" id="occupied">{data?.orderInstallationDetails?.Occupaied ? 'Yes' : 'No'}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="purchaseOrder" className="form-label col-md-4">Purchase Order</Form.Label>
                      <span className="value col-md-8" id="purchaseOrder">{data?.orderInstallationDetails?.PurchaseOrder}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="salesperson" className="form-label col-md-4">Salesperson</Form.Label>
                      <span className="value col-md-8" id="salesperson">{data?.orderInstallationDetails?.SalesPerson}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="jobNumber" className="form-label col-md-4">Job Number</Form.Label>
                      <span className="value col-md-8" id="jobNumber">{parseInt(jobNum) ? jobNum : qualityJobNo()}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="orderTotal" className="form-label col-md-4">Total</Form.Label>
                      <span className="value col-md-8" id="orderTotal">{orderTotal ? handleFormat(parseFloat(orderTotal)) : null}</span>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                      <Form.Label htmlFor="tax" className="form-label col-md-4">Tax</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="tax" id="tax" onChange={handleChange} value={state.tax} />
                      </div>
                      <span className="value col-md-8" id="zip"></span>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="internalNotes" className="form-label col-md-4">Internal Notes</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="internalNotes" id="internalNotes" onChange={handleChange} value={state.internalNotes} />
                      </div>
                    </Form.Group>
                    <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="installerInstructions" className="form-label col-md-4">Installer Instructions</Form.Label>
                      <div className="col-md-8">
                        <Form.Control type="text" className="form-control" name="installerInstructions" id="installerInstructions" onChange={handleChange} value={state.installerInstructions} />
                      </div>
                    </Form.Group>
                    {data?.orderInstallationDetails?.InstallerNotes && <Form.Group className="row mb-2 pdf-optional">
                      <Form.Label htmlFor="installerNotes" className="form-label col-md-4">Installer Notes</Form.Label>
                      <span className="value col-md-8" id="installerNotes">{data?.orderInstallationDetails?.InstallerNotes}</span>
                    </Form.Group>}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center my-3 pdfPageBreak">
                <Col className="plan-img-container" md={5}>
                  <div className="plan-img">
                    <ImageContainer imageUrl={customerOrderData[index]?.orderInstallationDetails?.MeasureImagePng}/>
                  </div>
                </Col>
              </Row>
              <hr className="mt-0" />
              <div className="order-detail py-0">
                <h5 className="mb-2 pdfPageBreak">Material</h5>
                <div className="fixTableHead mb-3">
                  <Table bordered className="mb-0 t-scroll order-material-pdf">
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Private Name</th>
                        <th>Style</th>
                        <th>Color</th>
                        <th>Unit Price</th>
                        <th>Line Price</th>
                        { !checkOrder &&
                          <th id="pdfactionmaterial">Actions</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {materialListData && materialListData?.length > 0 && materialListData.map((data, index) => (
                        <tr key={index}>
                          <td width="110">
                            <Input 
                              ids={`materialQuantityItem`} 
                              validate={false}
                              handleChange={e=>handleMaterialItemChange(e, data, 'quantity')}
                              value={data.quantity ? data.quantity : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td>{_.get(data, "unitName", "") || _.get(data, "unit", "")}</td>
                          <td>{_.get(data, "privateName", "") || _.get(data, "pvtName", "")}</td>
                          <td>{_.get(data, "product", "") || _.get(data, "style", "")}</td>
                          <td>{_.get(data, "color", "")}</td>
                          <td width="110" className="showPdfData">
                            <Input 
                              ids={`materialUnitpriceItem`} 
                              validate={false}
                              handleChange={e=>handleMaterialItemChange(e, data, 'unitPrice')}
                              value={data.unitPrice ? data.unitPrice : data.price}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td width="110" className="showPdfData">
                            <Input 
                              ids={`materialLinePriceItem`} 
                              validate={false}
                              handleChange={e=>handleMaterialItemChange(e, data, 'linePrice')}
                              value={data.linePrice ? data.linePrice : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          { !checkOrder &&
                            <td><Button variant="link" className="text-danger p-0" onClick={e=>handleMaterialDeleteClick(data)}><Icon size={24} icon={ic_delete_outline}/></Button></td>
                          }
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={5} align="right"><div className="form-control border-0 bg-transparent py-0">Total</div></td>
                        <td>
                          <div className="form-control border-0 bg-transparent py-0 showPdfTotalData">{totalMaterialUnitPrice ? handleFormat(parseFloat(totalMaterialUnitPrice)) : parseFloat(0.00).toFixed(2)}</div>
                        </td>
                        <td><div className="form-control border-0 bg-transparent py-0 showPdfTotalData">{totalMaterialLinePrice ? handleFormat(parseFloat(totalMaterialLinePrice)) : parseFloat(0.00).toFixed(2)}</div></td>
                        { !checkOrder && <td></td> }
                      </tr>
                    </tbody>
                  </Table>
                </div>
                { checkOrder ? '' :
                  <div className="d-block text-center" >
                    <div data-bs-toggle="modal" id="pdfmaterialitem" role="button" className="btn btn-primary" onClick={handleClickNewMaterialItem}>
                      Add New Material Item
                    </div>
                  </div>
                }
                <hr />
                <h5 className="mb-2 pdfPageBreak">Labor</h5>
                <div className="fixTableHead mb-3">
                  <Table bordered className="mb-0 order-labor-pdf">
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Labor Category</th>
                        <th>Labor Name</th>
                        <th>Unit Price</th>
                        <th>Line Price</th>
                        { !checkOrder &&
                          <th id="pdfactionlabor">Actions</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {orderInstallationListData && orderInstallationListData?.length > 0 && orderInstallationListData.map((data, index) => (
                        <tr key={index}>
                          <td width="110">
                            <Input 
                              ids={`laborQuantityItem`} 
                              validate={false}
                              handleChange={e=>handleLaborItemChange(e, data, 'quantity')}
                              value={data.quantity ? data.quantity : null}
                              disabled={checkOrder ? true : false}
                            />
                          </td>
                          <td>{_.get(data, "unitName", "")}</td>
                          <td>{_.get(data, "laborCategory", "")}</td>
                          <td>{_.get(data, "labor", "") || _.get(data, "laborName", "")}</td>
                          <td width="110" className="showPdfData">
                            <Input 
                              ids={`laborUnitpriceItem`} 
                              validate={false}
                              handleChange={e=>handleLaborItemChange(e, data, 'unitPrice')}
                              value={data.unitPrice ? data.unitPrice : data.price}
                              disabled={checkOrder ? true : false}
                            />
                            <span className="form-control" style={{'display':'none', 'background-color':'#e9ecef'}}>{handleFormat(parseFloat(data?.unitCost))}</span>
                          </td>
                          <td width="110" className="showPdfData">
                            <Input 
                              ids={`laborLinePriceItem`} 
                              validate={false}
                              handleChange={e=>handleLaborItemChange(e, data, 'linePrice')}
                              value={data.linePrice ? data.linePrice : null}
                              disabled={checkOrder ? true : false}
                            />
                            <span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{handleFormat(parseFloat(data?.lineCost))}</span>
                          </td>
                          { !checkOrder &&
                            <td><Button variant="link" className="text-danger p-0"  onClick={e=>handleLaborDeleteClick(data)}><Icon size={24} icon={ic_delete_outline}/></Button></td>
                          }
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} align="right"><div className="form-control border-0 bg-transparent py-0">Total</div></td>
                        <td><div className="form-control border-0 bg-transparent py-0 showPdfTotalData"><span>{totalLaborUnitPrice ? handleFormat(parseFloat(totalLaborUnitPrice)) : parseFloat(0.00).toFixed(2)}</span><span style={{'display':'none'}}>{totalLaborOrgUnitPrice ? handleFormat(parseFloat(totalLaborOrgUnitPrice)) : parseFloat(0.00).toFixed(2)}</span></div></td>
                        <td><div className="form-control border-0 bg-transparent py-0 showPdfTotalData"><span>{totalLaborLinePrice ? handleFormat(parseFloat(totalLaborLinePrice)) : parseFloat(0.00).toFixed(2)}</span><span style={{'display':'none'}}>{totalLaborOrgLinePrice ? handleFormat(parseFloat(totalLaborOrgLinePrice)) : parseFloat(0.00).toFixed(2)}</span></div></td>
                        { !checkOrder && <td></td> }
                      </tr>
                    </tbody>
                  </Table>
                </div>
                { checkOrder ? '' :
                  <div className="d-block text-center mb-4">
                    <div data-bs-toggle="modal" id="pdflaboritem" onClick={handleClickNewLabourItem} role="button" className="btn btn-primary">
                      Add New Labor Item
                    </div>
                  </div>
                }
                <hr />

                { checkOrder && customerOrderData && customerOrderData?.[0]?.orderAddOns?.length > 0 && customerOrderData[0].orderAddOns.map((customerAddOndata, customerAddOnIndex) => (
                  <div className="addon-container pdfPageBreak" key={customerAddOnIndex}>
                    <div className="text-center">
                      <h5>Add On #{customerAddOnIndex+1}</h5>
                    </div>
                    <ListGroup horizontal className="mb-3">
                      <ListGroup.Item className="border-0 ps-0"><strong>Approved By: {customerAddOndata?.addOnDetail?.approvedBy}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Date: {handleFormatDate(customerAddOndata?.addOnDetail?.approvedDate)}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Entered By: {customerAddOndata?.addOnDetail?.enteredBy}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Description: {customerAddOndata?.addOnDetail?.description}</strong></ListGroup.Item>
                    </ListGroup>
                    <div className="mb-3">
                      <Table bordered responsive className="addons">
                        <thead>
                          <tr>
                            <th width="100">Qty</th>
                            <th width="100">Unit</th>
                            <th colSpan="3"></th>
                            <th width="100">Unit Price</th>
                            <th width="100">Line Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          { customerAddOndata?.products && customerAddOndata?.products?.length > 0 && customerAddOndata?.products.map((productsAddOnData, productsAddOnIndex) => (
                            <tr key={productsAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(productsAddOnData?.quantity)} disabled /></td>
                              <td>{productsAddOnData?.unitName}</td>
                              <td>{productsAddOnData?.privateName}</td>
                              <td>{productsAddOnData?.style}</td>
                              <td>{productsAddOnData?.color}</td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(productsAddOnData?.unitPrice)} disabled /></td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(productsAddOnData?.linePrice)} disabled /></td>
                            </tr>
                          ))}
                          { customerAddOndata?.materials && customerAddOndata?.materials?.length > 0 && customerAddOndata?.materials.map((materialAddOnData, materialAddOnIndex) => (
                            <tr key={materialAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialAddOnData?.quantity)} disabled /></td>
                              <td>{materialAddOnData?.unitName}</td>
                              <td>{materialAddOnData?.privateName}</td>
                              <td>{materialAddOnData?.style}</td>
                              <td>{materialAddOnData?.color}</td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialAddOnData?.unitPrice)} disabled /></td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialAddOnData?.linePrice)} disabled /></td>
                            </tr>
                          ))}
                          { customerAddOndata?.labors && customerAddOndata?.labors?.length > 0 && customerAddOndata?.labors.map((laborAddOnData, laborAddOnIndex) => (
                            <tr key={laborAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborAddOnData?.quantity)} disabled /></td>
                              <td>{laborAddOnData?.unitName}</td>
                              <td>{laborAddOnData?.laborCategory}</td>
                              <td>{laborAddOnData?.labor}</td>
                              <td></td>
                              <td className="showPdfData"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborAddOnData?.unitPrice)} disabled /><span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{handleFormat(parseFloat(laborAddOnData?.unitCost))}</span></td>
                              <td className="showPdfData"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborAddOnData?.linePrice)} disabled /><span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{handleFormat(parseFloat(laborAddOnData?.lineCost))}</span></td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={'5'} className="border-0 text-end"></td>
                            <td colSpan={'2'} className="border-0 text-end showPdfTotalData"><strong><h5>Total:  ${handleAddOnTotal(customerAddOndata)?.[0]}</h5></strong><strong style={{'display':'none'}}><h5>Total:  ${handleAddOnTotal(customerAddOndata)?.[1]}</h5></strong></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
                </div>
                <div className="addon-container">
                {(orderShortageList && orderShortageList?.length > 0 && !JSON.parse(localStorage.getItem('isQuality'))) ? 
                  orderShortageList.map((data,idx)=>(
                  <div key={idx} className="pdfPageBreak">
                    <div className="text-center">
                      <h5>Shortage #{idx+1}</h5>
                    </div>
                    <ListGroup horizontal className="mb-3">
                      <ListGroup.Item className="border-0 ps-0"><strong>Called in By: {data?.orderShortageDetail?.calledInBy ? data?.orderShortageDetail?.calledInBy : ""}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Date: {data?.orderShortageDetail?.approvedDate ? formatDate(data?.orderShortageDetail?.approvedDate) : ""}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Entered By: {data?.orderShortageDetail?.enteredBy ? data?.orderShortageDetail?.enteredBy : ""}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Description: {data?.orderShortageDetail?.description ? data?.orderShortageDetail?.description : ""}</strong></ListGroup.Item>
                    </ListGroup>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label className="form-label">
                            Reason for Shortage
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md={8}>
                        <Row>
                          {data?.orderShortageReasons ? ShortageReasonsComponent(data?.orderShortageReasons, data?.orderShortageReasons) : ""}
                        </Row>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <Table bordered responsive className="addons">
                        <thead>
                          <tr>
                            <th width="100">Qty</th>
                            <th width="100">Unit</th>
                            {/* <th width="100">Style</th> */}
                            <th colSpan="3"></th>
                            <th width="100">Unit Price</th>
                            <th width="100">Line Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(data?.orderShortageProduct && data?.orderShortageProduct?.length > 0 ) ? data?.orderShortageProduct.map((kdata) => (
                          <tr key={kdata.orderShortageProductId}>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata.quantity|| (kdata.quantity === 0)) ? parseFloat(kdata.quantity).toFixed(2) : ""} disabled /></td>
                            <td>{kdata.unitName ? kdata.unitName : ""}</td>
                            <td>{kdata.privateName ? kdata.privateName : ""}</td>
                            <td>{kdata.style ? kdata.style : ""}</td>
                            <td>{kdata.color ? kdata.color : ""}</td>
                            <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata?.unitPrice || ( kdata.unitPrice === 0))? parseFloat(kdata?.unitPrice).toFixed(2) : ""} disabled/></td>
                            <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={kdata?.linePrice || (kdata?.linePrice === 0) ? parseFloat(kdata?.linePrice).toFixed(2) : ""} disabled/></td>
                          </tr>)) : ""}
                          {(data?.orderShortageMaterial && data?.orderShortageMaterial?.length > 0 ) ? data?.orderShortageMaterial.map((kdata) => (
                          <tr key={kdata.orderShortageMaterialId}>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata.quantity|| (kdata.quantity === 0)) ? parseFloat(kdata.quantity).toFixed(2):""} disabled /></td>
                            <td>{kdata.unitName ? kdata.unitName : ""}</td>
                            <td>{kdata.privateName ? kdata.privateName : ""}</td>
                            <td>{kdata.style ? kdata.style : ""}</td>
                            <td>{kdata.color ? kdata.color : ""}</td>
                            <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata?.unitPrice || ( kdata.unitPrice === 0)) ? parseFloat(kdata?.unitPrice).toFixed(2) : ""} disabled/></td>
                            <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={kdata?.linePrice || (kdata?.linePrice === 0) ? parseFloat(kdata?.linePrice).toFixed(2) : ""} disabled/></td>
                          </tr>)) : ""}
                          {(data?.orderShortageLabor && data?.orderShortageLabor?.length > 0 ) ? data?.orderShortageLabor.map((kdata) => (
                          <tr key={kdata.orderShortageLaborId}>
                            <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata.quantity|| (kdata.quantity === 0))? parseFloat(kdata.quantity).toFixed(2):""} disabled /></td>
                            <td>{kdata.unitName ? kdata.unitName : ""}</td>
                            <td >{kdata.laborCategory ? kdata.laborCategory : ""}</td>
                            <td>{kdata.labor ? kdata.labor : ""}</td>
                            <td>{kdata.privateName ? kdata.privateName : ""}</td>
                            <td className="showPdfData"><Form.Control placeholder="" aria-label="" aria-describedby="" value={(kdata?.unitPrice || ( kdata.unitPrice === 0)) ? parseFloat(kdata?.unitPrice).toFixed(2) : ""} disabled/><span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{parseFloat(kdata?.unitCost).toFixed(2)}</span></td>
                            <td className="showPdfData"><Form.Control placeholder="" aria-label="" aria-describedby="" value={kdata?.linePrice || (kdata?.linePrice === 0) ? parseFloat(kdata?.linePrice).toFixed(2) : ""} disabled/><span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{parseFloat(kdata?.lineCost).toFixed(2)}</span></td>
                          </tr>)) : ""}
                          <tr>
                            <td colSpan={'5'} className="border-0 text-end"></td>
                            <td colSpan={'2'} className="border-0 text-end showPdfTotalData"><strong><h5>Total:  {calculateTotal(data?.orderShortageMaterial, data?.orderShortageProduct, data?.orderShortageLabor)?.[0]}</h5></strong><strong style={{'display':'none'}}><h5>Total:  {calculateTotal([], [], data?.orderShortageLabor)?.[1]}</h5></strong></td>
                          </tr>
                        </tbody>
                      </Table>
                     </div>
                  </div>
                  ))
                : "" }
                { checkOrder && customerOrderData && customerOrderData?.[0]?.orderQuality?.length > 0 && customerOrderData[0].orderQuality.map((customerQualitydata, customerQualityIndex) => (
                  <div className="addon-container pdfPageBreak" key={customerQualityIndex}>
                    <div className="text-center mb-3">
                      <h5>Quality #{customerQualityIndex+1}</h5>
                    </div>
                    <Row className="mb-3 justify-content-center">
                      <Col sm={6} md={5} lg={4} xl={3}>
                        <div className="d-block d-md-flex align-items-center">
                          <Form.Label className="text-nowrap me-2 mb-sm-0">
                            Installation Date
                          </Form.Label>
                          <DatePicker
                            selected={handleQualityInstallationDate(customerQualitydata)}
                            // onChange={(date) => setQualityInstallationDate(date)}
                            onChange={(date)=>handleQualityInstallationChange(date, customerQualitydata)}
                            minDate={new Date()}
                            placeholderText={"--/--/----"}
                          />
                        </div>

                      </Col>
                      <Col sm={0} md={1} lg={1} xl={1}></Col>
                      <Col sm={6} md={5} lg={4} xl={3}>
                        <div className="d-block d-md-flex align-items-center">
                          <Form.Label className="text-nowrap me-2 mb-sm-0">
                            Status
                          </Form.Label>
                          <Select ids="qualityOrderedStatus" result={qualityOrderedStatusList} handleChange={e=>handleQualityStatusChange(e, customerQualitydata)} value={handleQualityStatus(customerQualitydata)} validate={false} />
                        </div>
                      </Col>
                    </Row>
                    <ListGroup horizontal className="mb-3">
                      <ListGroup.Item className="border-0 ps-0"><strong>Called In By: {customerQualitydata?.orderQualityDetail?.calledInBy}</strong></ListGroup.Item>
                      {/* <ListGroup.Item className="border-0"><strong>Date: {handleFormatDate(customerQualitydata?.orderQualityDetail?.approvedDate)}</strong></ListGroup.Item> */}
                      <ListGroup.Item className="border-0"><strong>Entered By: {customerQualitydata?.orderQualityDetail?.enteredBy}</strong></ListGroup.Item>
                      <ListGroup.Item className="border-0"><strong>Description: {customerQualitydata?.orderQualityDetail?.description}</strong></ListGroup.Item>
                    </ListGroup>
                    <Row className="mb-3 justify-content-center">
                      <Col md={10}>
                        <Row>
                          {customerQualitydata?.orerQaulityReason ? handleQualityReasons(customerQualitydata?.orerQaulityReason, customerQualitydata?.orerQaulityReason) : ""}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="justify-content-center my-3">
                      <Col md={7}>
                        <div className="plan-img">
                          <img className="img-fluid view-img quantity-plan" src={customerQualitydata?.orderQualityDetail?.floorPlanImage} />
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Table bordered responsive className="addons">
                        <thead>
                          <tr>
                            <th width="100">Qty</th>
                            <th width="100">Unit</th>
                            <th colSpan="1">Private Name</th>
                            <th colSpan="1">Style</th>
                            <th colSpan="1">Color</th>
                            <th width="100">Unit Price</th>
                            <th width="100">Line Price</th>
                            {/* <th width="100">Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          { customerQualitydata?.orderQualityProduct && customerQualitydata?.orderQualityProduct?.length > 0 && customerQualitydata?.orderQualityProduct.map((QualityAddOnData, QualityAddOnIndex) => (
                            <tr key={QualityAddOnIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(QualityAddOnData?.quantity)} disabled /></td>
                              <td>{QualityAddOnData?.unitName}</td>
                              <td>{QualityAddOnData?.privateName}</td>
                              <td>{QualityAddOnData?.style}</td>
                              <td>{QualityAddOnData?.color}</td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(QualityAddOnData?.unitPrice)} disabled /></td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(QualityAddOnData?.linePrice)} disabled /></td>
                              {/* <td><Button variant="link" className="text-danger p-0"><Icon size={24} icon={ic_delete_outline}/></Button></td> */}
                            </tr>
                          ))}
                          { customerQualitydata?.orderQualityMaterial && customerQualitydata?.orderQualityMaterial?.length > 0 && customerQualitydata?.orderQualityMaterial.map((materialQualityData, materialQualityIndex) => (
                            <tr key={materialQualityIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialQualityData?.quantity)} disabled /></td>
                              <td>{materialQualityData?.unitName}</td>
                              <td>{materialQualityData?.privateName}</td>
                              <td>{materialQualityData?.style}</td>
                              <td>{materialQualityData?.color}</td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialQualityData?.unitPrice)} disabled /></td>
                              <td className="hideMaterial"><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(materialQualityData?.linePrice)} disabled /></td>
                              {/* <td><Button variant="link" className="text-danger p-0"><Icon size={24} icon={ic_delete_outline}/></Button></td> */}
                            </tr>
                          ))}
                          { customerQualitydata?.orderQualityLabor && customerQualitydata?.orderQualityLabor?.length > 0 && customerQualitydata?.orderQualityLabor.map((laborQualityData, laborQualityIndex) => (
                            <tr key={laborQualityIndex}>
                              <td><Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborQualityData?.quantity)} disabled /></td>
                              <td>{laborQualityData?.unitName}</td>
                              <td>{laborQualityData?.laborCategory}</td>
                              <td>{laborQualityData?.labor}</td>
                              <td></td>
                              <td className="showPdfData">
                                <Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborQualityData?.unitPrice)} disabled />
                                <span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{handleFormat(parseFloat(laborQualityData?.unitCost))}</span>
                              </td>
                              <td className="showPdfData">
                                <Form.Control placeholder="" aria-label="" aria-describedby="" value={handleFormat(laborQualityData?.linePrice)} disabled />
                                <span className="form-control disabled" style={{'display':'none', 'background-color':'#e9ecef'}}>{handleFormat(parseFloat(laborQualityData?.lineCost))}</span>
                              </td>
                              {/* <td><Button variant="link" className="text-danger p-0"><Icon size={24} icon={ic_delete_outline}/></Button></td> */}
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={'5'} className="text-end"><strong>Total</strong></td>
                            <td colSpan={'1'} className="text-start showPdfTotalData"><strong>${handleQualityTotal(customerQualitydata, 'unitPrice')}</strong><strong style={{'display':'none'}}>${handleQualityTotal(customerQualitydata, 'unitCost')}</strong></td>
                            <td colSpan={'1'} className="text-start showPdfTotalData"><strong>${handleQualityTotal(customerQualitydata, 'linePrice')}</strong><strong style={{'display':'none'}}>${handleQualityTotal(customerQualitydata, 'lineCost')}</strong></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
                { !checkOrder &&
                  <div className="d-block text-center mb-4">
                    <div data-bs-toggle="modal" role="button" id="submitpdf" className="btn btn-primary me-2" onClick={JSON.parse(localStorage.getItem("Existing-Orders-Page")) === true ? handleQualitySaveClick : handleSaveClick}>Submit</div>
                    <div data-bs-toggle="modal" role="button" id="pdcancel" className="btn btn-primary" onClick={handleCancelClick}>Cancel</div>
                  </div>
                }
              </div>
            </Card.Body>
          </Card>
        ))}
  		</Container>
      {materialContractModalOpen && !laborContractModalOpen &&
        <PricingContract
          className="mb-2"
          storeId={customerData[0].storeId}
          contactInfoId={[contactInfoId]}
          pricingContractModalOpen={pricingContractModalOpen}
          showPricingContractModal={showPricingContractModal}
          hidePricingContractModal={hidePricingContractModal}
          isContractPricingAdded={customerData[0]?.isContractPricingAdded}
          changeReducerState={changeReducerState}
          handleUpdateCustomerDetail={handleUpdateCustomerDetail}
          materialContractModalOpen={materialContractModalOpen}
          orderInstallationDetailId={customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId}
          saveContractForOrderDetails={saveContractForOrderDetails}
          unitId={unitId}
          jobNum={jobNum}
          setLsUpdate={setLsUpdate}
          lsUpdate={lsUpdate}
          setLsLaborUpdate={setLsLaborUpdate}
          lsLaborUpdate={lsLaborUpdate}
          lsLaborMaterialUpdate={lsLaborMaterialUpdate}
          deleteOrderData={deleteOrderData}
          generateKey={generateKey}
          checkOrder={checkOrder}
        />
      }
      {laborContractModalOpen && !materialContractModalOpen ? (
        <PricingContract
          className="mb-2"
          storeId={customerData[0].storeId}
          contactInfoId={[contactInfoId]}
          pricingContractModalOpen={pricingContractModalOpen}
          showPricingContractModal={showPricingContractModal}
          hidePricingContractModal={hidePricingContractModal}
          isContractPricingAdded={customerData[0].isContractPricingAdded}
          changeReducerState={changeReducerState}
          handleUpdateCustomerDetail={handleUpdateCustomerDetail}
          laborContractModalOpen={laborContractModalOpen}
          saveContractForOrderDetails={saveContractForOrderDetails}
          orderInstallationDetailId={customerOrderData[0]?.orderInstallationDetails.OrderInstallationDetailId}
          unitId={unitId}
          jobNum={jobNum}
          setLsUpdate={setLsUpdate}
          lsUpdate={lsUpdate}
          setLsLaborUpdate={setLsLaborUpdate}
          lsLaborUpdate={lsLaborUpdate}
          lsLaborMaterialUpdate={lsLaborMaterialUpdate}
          deleteOrderData={deleteOrderData}
          generateKey={generateKey}
          checkOrder={checkOrder}
        />
      ) : ""}
      {confirmDeleteModal && <ConfirmDelete 
        variant="primary"
        title={`order-confirmation ${deleteOrder} data`}
        showConfirmDeleteModal={showConfirmDeleteModal}
        hideConfirmDeleteModal={hideConfirmDeleteModal}
        handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
        confirmDeleteModal={confirmDeleteModal}
      />}
      {isChangeJob && (
        <ChangeJob
          className="mb-2"
          handleMakeChangeModalOpen={handleMakeChangeModalOpen}
          handleMakeChangeModalClose={handleMakeChangeModalClose}
          startDate={startDate}
          setStartDate={setStartDate}
          handleRescheduleChange={handleRescheduleChange}
          handleMakeChangeClose={handleMakeChangeClose}
          handleFetchRescheduledData={handleFetchRescheduledData}
          rescheduleData={rescheduleData}
          changeJobHeader={changeJobHeader}
          setChangeJobHeader={setChangeJobHeader}
          handleRescheduleCancel={handleRescheduleCancel}
          handleReschedule={handleReschedule}
          handleShortage={handleShortage}
          handleQuality={handleQuality}
          handleOrdering={handleOrdering}
          handleAddOn={handleAddOn}
          handleClickNewMaterialItem={handleClickNewMaterialItem}
          handleClickNewLabourItem={handleClickNewLabourItem}
          handleAddOnFormChange={handleAddOnFormChange}
          addOnData={addOnData}
          materialListData={materialListData}
          orderInstallationListData={orderInstallationListData}
          handleAddOnSubmit={handleAddOnSubmit}
          handleorderShortageSubmit={handleorderShortageSubmit}
          handleAddOnCancel={handleAddOnCancel}
          orderAddOnDetails={orderAddOnDetails}
          handleFormat={handleFormat}
          handleStateChange={handleStateChange}
          shortageReason={shortageReason}
          jobNum={jobNum}
          qualityReasons={qualityReasons}
          auth={auth}
          customerData={customerData}
          orderingUrl={orderingUrl}
          saveOrderQualityDetailRequest={saveOrderQualityDetailRequest}
          saveOrderQualityDetailData={saveOrderQualityDetailData}
          localStorageData={localStorageData}
          localStorageLaborData={localStorageLaborData}
          setLsUpdate={setLsUpdate}
          lsUpdate={lsUpdate}
          setLsLaborUpdate={setLsLaborUpdate}
          lsLaborUpdate={lsLaborUpdate}
          lsLaborMaterialUpdate={lsLaborMaterialUpdate}
          lsAllLaborMaterialCarpetData={lsAllLaborMaterialCarpetData}
        />
      )}
    </MainWrapper>
  )
}



const mapStateToProps = ({ auth, customerInfo, pricingContract }) => {
	return {
		auth, customerInfo, pricingContract
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrderInstallationDetail: (token, jobNum, checkOrder) => dispatch({ type: CUSTOMER_UNIT_DETAIL_REQUEST, token, jobNum, checkOrder }),
		fetchCustomerList: (token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked) => dispatch({ type: CUSTOMER_LIST_REQUEST, token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked }),
		saveCustomerOrderDetails: (token, payload) => dispatch({ type: CUSTOMER_ORDER_DETAIL_REQUEST, token,  payload }),
    changeReducerState: () => dispatch({ type: CUSTOMER_STATE_CHANGE }),
		fetchOrderInstallationMaterial: (token, jobNum) => dispatch({ type: CUSTOMER_ORDER_MATERIAL_REQUEST, token, jobNum }),
		fetchOrderInstallationOrder: (token, jobNum) => dispatch({ type: CUSTOMER_ORDER_INSTALLATION_REQUEST, token, jobNum }),
    saveCustomerOrderDetailsForPricingContract: (token, data) => dispatch({ type: SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST, token, data }),
    deleteOrderMaterialData: (token, id, checkFlag) => dispatch({ type: CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST, token, id, checkFlag }),
    deleteOrderLaborData: (token, id) => dispatch({ type: CUSTOMER_ORDER_LABOR_DELETE_REQUEST, token, id }),
    saveCustomerOrderUnitId: (token, data) => dispatch({ type: CUSTOMER_ORDER_UNIT_CHANGE_REQUEST, token, data }),
    saveCustomerOrderUnitTypeId: (token, data) => dispatch({ type: CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST, token, data }),
    saveCustomerOrderRescheduleData: (token, data) => dispatch({ type: CUSTOMER_ORDER_RESCHEDULE_REQUEST, token, data }),
    fetchCustomerOrderRescheduledData: (token, id) => dispatch({ type: CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST, token, id }),
    fetchCustomerOrderAddOnDetails: (token, id) => dispatch({ type: CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST, token, id }),
    saveCustomerOrderAddOnDetails: (token, data) => dispatch({ type: CUSTOMER_ORDER_ADDON_SAVE_REQUEST, token, data }),
    getShortageReasonRequest: (token, data) => dispatch({ type: GET_SHORTAGE_REASON_REQUEST, token, data }),
    saveOrderShortageRequest: (token, data) => dispatch({ type: SAVE_ORDER_SHORTAGE_DETAIL_REQUEST, token, data }),
    updateShortageReasonRequest: (token, data) => dispatch({ type: UPDATE_SHORTAGE_REASON_REQUEST, token, data }),
    getQualityReasonRequest: (token, data) => dispatch({ type: GET_QUALITY_REASON_REQUEST, token, data }),
    saveOrderQualityDetailRequest: (token, data) => dispatch({ type: SAVE_ORDER_QUALITY_DETAIL_REQUEST, token, data }),
    saveQualityOrderDetail: (token, data) => dispatch({ type: SAVE_QUALITY_ORDER_DETAILS_REQUEST, token, data }),
    fetchQualityOrderDetail: (token, data) => dispatch({ type: GET_QUALITY_ORDER_REQUEST, token, data }),
    updateQualityReason: (token, data) => dispatch({ type: UPDATE_QUALITY_REASON_REQUEST, token, data }),
    updateQualityDetail: (token, data) => dispatch({ type: UPDATE_QUALITY_DETAIL_REQUEST, token, data })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);