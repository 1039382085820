import React, { Fragment, useState, useEffect, useMemo } from "react";
import Input from "../../elements/CustomInput";
import Select from "../../elements/CustomSelect";
import { Form } from "react-bootstrap";
import { Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

const ExistingOrdersList = ({
  existingOrderListing,
  totalCount,
  setPaginate,
  paginate,
  listParamState,
  handleFilterChange,
  storeList,
  handleCheckboxClick,
  handleAllCheckboxSelect,
  savedJobNumber,
  selectAll,
  selectedIds
}) => {

  // states declaration
  const [items, setItems] = useState([]);
  const [isMore, setIsMore] = useState([]);

  const navigate = useNavigate();

	// function called to fetch more data, pagination
	const fetchMoreData = () => {
    setTimeout(() => {
      setPaginate(paginate + 10)
    }, 1500)
  }

	// function to check pagination mor to load data or not
  useEffect(() => {
    setItems(existingOrderListing)
		if (_.isEqual(totalCount, existingOrderListing.length)) {
      setIsMore(false)
    } else {
      setIsMore(true)
    }
  }, [existingOrderListing])

  // setting new list data using memo
	const data = useMemo(() => items, [items]);

	// setting store list data using memo
	const storeData = useMemo(() => {
    var stores = [{ value: 0, label: "All" }];
    return stores.concat(storeList);
  }, [storeList])

	// handle quality list data
	const isQualityList = useMemo(() => {
    let qualityList = [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }];
    return qualityList
  }, [storeList])

	// handle quality status list data
	const qualityStatusList = useMemo(() => {
    let qualityStatusData = [{ value: "Open", label: "Open" }, { value: "Closed", label: "Closed" }];
    return qualityStatusData
  }, [storeList])

  //function called to handle job no click
  const handleJobNoClick = (existingOrderValue) => {
    localStorage.setItem('Existing_Orders', JSON.parse(true))
    localStorage.setItem('Existing-Orders-Page', JSON.parse(true))
    localStorage.setItem('quality-JobNum', JSON.stringify(existingOrderValue?.JobNo))
    localStorage.setItem('quality-UnitId', JSON.stringify(existingOrderValue?.UnitId))
    localStorage.setItem('Existing_Orders_value', JSON.stringify(existingOrderValue ? existingOrderValue : null))
    navigate(`/place-order/order-confirmation/contactInfoId=${existingOrderValue?.ContactInfoId}/unitId=${existingOrderValue?.UnitId}/jobNum=${existingOrderValue?.JobNo}`)
  }

  return (
    <Fragment>
      <Table bordered responsive>
      <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={isMore}
          loader={
            <div className="data-loading">
              <img src="https://64.media.tumblr.com/695ce9a82c8974ccbbfc7cad40020c62/tumblr_o9c9rnRZNY1qbmm1co1_1280.gifv" />
              <span>Load More</span>
            </div>
          }
          >
        <thead>
          <tr>
            <th style={{verticalAlign: "top"}}>
              <Form.Group controlId="select-all">
                <Form.Label>Select</Form.Label>
                <Form.Check ids="Select" type="checkbox" onChange={(e)=>handleAllCheckboxSelect(e)} checked={selectAll ? selectAll : false} />
              </Form.Group>
            </th>
            <th>
              <Input label={`Job Number`} ids={`jobNumber`} value={listParamState.jobNumber} handleChange={handleFilterChange} validate={false} />
            </th>
            <th>
              <Input label={`Property Name`} ids={`shipToPropertyName`} value={listParamState.shipToPropertyName} handleChange={handleFilterChange} validate={false} />
            </th>
            <th>
              <Input label={`Unit Number`} ids={`unitName`} value={listParamState.unitName} handleChange={handleFilterChange} validate={false} />
            </th>
            {/* <th>
              <Input label={`Ordered Date`} ids={`orderedDate`} value={listParamState.orderedDate} handleChange={handleFilterChange} validate={false} />
            </th> */}
            <th>
              <Input label={`Installation Date`} ids={`installationDate`} value={listParamState.installationDate} handleChange={handleFilterChange} validate={false} />
            </th>
            <th>
              <Input label={`Salesperson`} ids={`salesPerson`} value={listParamState.salesPerson} handleChange={handleFilterChange} validate={false} />
            </th>
            <th>
              <Select label={`Store Name`} ids="StoreId" result={storeData} value={listParamState.StoreId} handleChange={handleFilterChange} validate={false} />
            </th>
            <th>
              <Select label={`Is Quality?`} ids="isQuality" result={isQualityList} value={listParamState.isQuality} handleChange={handleFilterChange} validate={false} />
            </th>
            <th>
              <Select label={`Quality Status`} ids="qualityStatus" result={qualityStatusList} value={listParamState.qualityStatus} handleChange={handleFilterChange} validate={false} />
            </th>
          </tr>
        </thead>
          <tbody>
            {!_.isEmpty(data) ? _.map(data, (existingOrderValue, index) => {
              return (
                <tr key={index} className={savedJobNumber.includes(existingOrderValue.JobNo) && 'processed-order'}>
                  <td>
                    <Form.Group controlId="isPrimary">
                      <Form.Check
                        type="checkbox"
                        // disabled={savedJobNumber.includes(existingOrderValue.JobNo)}
                        onChange={e=>handleCheckboxClick(e, existingOrderValue)}
                        checked={selectedIds?.includes(existingOrderValue.JobNo) || false}
                      />
                    </Form.Group>
                  </td>
									<td>
                    <div className="link" onClick={e=>handleJobNoClick(existingOrderValue)}>
                      {_.get(existingOrderValue, "JobNo", "")}
                    </div>
                  </td>
                  <td>{_.get(existingOrderValue, "ShipToPropertyName", "")}</td>
                  <td>{_.get(existingOrderValue, "UnitName", "")}</td>
                  {/* <td>{_.get(existingOrderValue, "OrderDate", "")}</td> */}
                  <td>{_.get(existingOrderValue, "OrderInstallationDate", "")}</td>
                  <td>{_.get(existingOrderValue, "SalesPerson", "")}</td>
                  <td>{_.get(existingOrderValue, "Store", "")}</td>
                  <td>{_.get(existingOrderValue, "IsQuality", false) ? 'Yes' : 'No'}</td>
                  <td>{_.get(existingOrderValue, "QualityStatus", "")}</td>
                </tr>
              )
            }) : (
              <tr>
                <td colSpan={`18`}>No Data Found</td>
              </tr>
            )}
          </tbody>
        </InfiniteScroll>
      </Table>
    </Fragment>
  )
}

export default ExistingOrdersList;
