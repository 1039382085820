import React, { Fragment, useState, useMemo, useEffect } from "react";
import Icon from "react-icons-kit";
import '../../../assets/css/Contacts.css';
import { ic_mode_edit } from 'react-icons-kit/md';
import { Table, Form, Button } from "react-bootstrap";
import _ from "lodash";
// import Pagination from "./TablePagination"
import ConfirmDelete from "./ConfirmDelete";
import Select from "../../elements/CustomSelect";
import Input from "../../elements/CustomInput";
// import NumaricInput from "../../elements/NumaricInput";
import DateInput from "../../elements/DateInput";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomCurrencyInput from "../../elements/CustomCurrencyInput";
import Kit from "./UpdateKits";
import CopyProduct from "./AddStore";
import {
  LABOR_CATEGORY_REQUEST
} from "../../../_utils/constants/Labor";
import {
  MATERIAL_ADDONS_REQUEST,
  KIT_EDIT_REQUEST,
  LABOR_ADDONS_REQUEST,
  KIT_UPDATE_REQUEST,
  KIT_STATE_CHANGE
} from "../../../_utils/constants/Kits";
import {
  PRODUCT_CATALOG_COPY_PRODUCT,
  PRODUCT_CATALOG_STATE_CHANGE,
  // CARPET_ID_DB,
  // SHEET_VINYL_ID_DB,
  // VINYL_PLANK_ID_DB,
  // Commented on 14 Dec 2022 for removing sub-product structure filter and list
  // WALLBASE_ID_DB,
  // INSTALLATION_ACCESSORIES_ID_DB,
  // TRANSITION_ID_DB
} from "../../../_utils/constants/ProductCatalog";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
const ProductCatalogList = ({
  products,
  totalCount,
  paginate,
  handleEdit,
  showConfirmDeleteModal,
  hideConfirmDeleteModal,
  handleConfirmDeleteSubmit,
  confirmDeleteModal,
  handleSingleSelect,
  handleMultiSelect,
  selectedId,
  productStructure,
  storeList,
  handleStockSingleSelect,
  handleStockMultiSelect,
  stockSelectedId,
  filterState,
  handleFilterChange,
  handleUpdateChange,
  updateState,
  product_id,
  setPaginate,
  handleCurrencyChange,
  handleCurrencyUpdateChange,
  productStruct,
  otherProductStructure,
  auth: { auth, isAuthenticated },
  fetchLaborCategory,
  LaborCategory: { laborCategory },
  fetchEditKit,
  fetchMaterialAddons,
  fetchLaborAddons,
  kits: {
    materialAddons,
    laborAddons,
    kit,
    isEditedList,
    isUpdated,
    kitUpdated
  },
  updateKit,
  copyProduct: { isCopy },
  copyProductStore,
  changeReducerState,
  setCopyFetch,
  changeKitState,
  productSubStructure
}) => {
  const [items, setItems] = useState([]);
  const [isMore, setIsMore] = useState([]);
  const [isKitOpen, setIsKitOpen] = useState(false)
  const [isCopyProduct, setIsCopyProduct] = useState(false)
  const [copyProductId, setCopyProductId] = useState("");
  const [copyStoreId, setCopyStoreId] = useState("");
  const [prStoreId, setPrStoreId] = useState("")
  const [validatedCopy, setValidatedCopy] = useState(false);
  const [state, setState] = useState({
    "StoreId": "",
    "ProductTypeId": "",
    "ProductId": "",
    "kitId": "",
    "subProductTypeId": "",
  })
  const [materialFilter, setMaterialFilter] = useState({
    "ProductTypeId": "",
    "ProductId": "",
    "kitId": "",
    "StoreId": "",
    "PrivateName": "",
    "Manufacturer": "",
    "Style": "",
    "Color": ""
  })
  const [laborFilter, setLaborFilter] = useState({
    "ProductTypeId": "",
    "ProductId": "",
    "kitId": "",
    "ProductStructureIdForLabor": "0",
    "StoreId": "",
    "LaborCategory": "",
    "LaborName": ""
  })
  const [kitMaterial, setKitMaterial] = useState([]);
  const [kitLabor, setKitLabor] = useState([]);
  const [storeLoading, setStoreLoading] = useState(false);
  const [editId, setEditId] = useState("");
  const  kitUpdateInfo = "Kit can not be update because product is assign to pricing contract."
  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginate(paginate + 5)
    }, 1500);
  };
  useEffect(() => {
    setItems(products)
  }, [products])
  useEffect(() => {
    if (_.isEqual(totalCount, products.length)) {
      setIsMore(false)

    } else {
      setIsMore(true);
    }
  }, [products])

  const data = useMemo(() => items, [items]);
  const productData = useMemo(() => {
    var productType = [{ value: 0, label: "All" }];
    return productType.concat(productStructure);
  }, [productStructure])
  // Commented on 14 Dec 2022 for removing sub-product structure filter and list
  // handle sub product structure data for filter
  // const subProductData = useMemo(() => {
  //   if(parseInt(filterState?.ProductStructureId) === WALLBASE_ID_DB || parseInt(filterState?.ProductStructureId) === INSTALLATION_ACCESSORIES_ID_DB || parseInt(filterState?.ProductStructureId) === TRANSITION_ID_DB) {
  //     let subProductType = [{ value: 0, label: "All" }];
  //     return subProductType.concat(productSubStructure);
	// 	}
  // }, [productSubStructure])
  const storeData = useMemo(() => {
    var stores = [{ value: 0, label: "All" }];
    return stores.concat(storeList);
  }, [storeList])

  const handleValue = (value) => {
    if (value) {
      return value
    }
  }
  const handleValue1 = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2)
    }
  }
  const handleKitModal = (e, data) => {
    e.preventDefault();
    let filter = {
      "StoreId": data.StoreId,
      "ProductTypeId": data.ProductStructureId,
      "ProductId": data.ProductId,
      "KitId": data.kitId,
    }
    setState({
      "StoreId": data.StoreId,
      "ProductTypeId": data.ProductStructureId,
      "ProductId": data.ProductId,
      "KitId": data.kitId,
      "subProductTypeId": data.SubProductStructureId,
    })
    setMaterialFilter({
      ...materialFilter,
      "StoreId": data.StoreId,
      "ProductId": data.ProductId,
      "KitId": data.kitId,
      "ProductTypeId": data.ProductStructureId,
      "subProductTypeId": data.SubProductStructureId, //added on 01 dec 2022 for pre selected
    })
    setLaborFilter({
      ...laborFilter,
      "StoreId": data.StoreId,
      "ProductId": data.ProductId,
      "KitId": data.kitId,
      "ProductTypeId": data.ProductStructureId,
      "subProductTypeId": data.SubProductStructureId, //added on 01 dec 2022 for pre selected
    })

    fetchEditKit(auth[0].token, filter)
  }

  const reset = () => {
    setState({
      "StoreId": 0,
      "ProductTypeId": 0,
      "ProductId": 0,
      "KitId": 0,
    })
    setKitMaterial([])
    setKitLabor([])
    setMaterialFilter({
      "ProductTypeId": "",
      "ProductId": "",
      "KitId": "",
      "StoreId": "",
      "PrivateName": "",
      "Manufacturer": "",
      "Style": "",
      "Color": ""
    })
    setLaborFilter({
      "ProductTypeId": "",
      "ProductId": "",
      "KitId": "",
      "ProductStructureIdForLabor": "0",
      "StoreId": "",
      "LaborCategory": "",
      "LaborName": ""
    })
    setEditId("");
    changeKitState()
  }
  const hideKitModel = () => {

    setIsKitOpen(false)
    reset();
  }
  useEffect(() => {
    if (isAuthenticated) {
      fetchLaborCategory(auth[0].token)
    }

  }, [isAuthenticated, auth])
  useEffect(() => {
    if (isAuthenticated && materialFilter.StoreId) {
      fetchMaterialAddons(auth[0].token, materialFilter);
    }
  }, [auth, isAuthenticated, materialFilter, isEditedList]);
  useEffect(() => {
    if (isAuthenticated && laborFilter.StoreId) {
      fetchLaborAddons(auth[0].token, laborFilter);
    }
  }, [auth, isAuthenticated, laborFilter, isEditedList]);
  const laborCategories = useMemo(() => {
    if (laborCategory)
      return laborCategory.map(v => ({ label: v.laborCategory, value: v.laborCategoryId }))
  }, [laborCategory])
  const mAddon = useMemo(() => materialAddons, [materialAddons])

  useMemo(() => {
    materialAddons && materialAddons.map((material) => {
      if (material.isSelected) {
        setKitMaterial(kitMaterial => [...kitMaterial, { ProductId: material.productId }]);
      }
    })
  }, [materialAddons])

  const lAddon = useMemo(() => laborAddons, [laborAddons])

  useMemo(() => {
    laborAddons && laborAddons.map((labor) => {
      if (labor.isSelected) {
        setKitLabor(kitLabor => [...kitLabor, { LaborItemId: labor.laborItemId }]);
      }
    })
  }, [laborAddons])

  const handleMaterialFilterChange = (e) => {
    const { id, value } = e.target;
    setMaterialFilter({
      ...materialFilter,
      [id]: value
    })
    if (id === "ProductTypeId" && !value && state.ProductTypeId) {
      setMaterialFilter({
        ...materialFilter,
        [id]: state.ProductTypeId
      })
    }
  }
  const handleLaborFilterChange = (e) => {
    const { id, value } = e.target;
    setLaborFilter({
      ...laborFilter,
      [id]: value
    })
    if (id === "ProductTypeId" && !value && state.ProductTypeId) {
      setLaborFilter({
        ...laborFilter,
        [id]: state.ProductTypeId
      })
    }
  }
  const handleMaterialSelect = (e, id) => {
    if (!e.target.checked) {
      setKitMaterial(kitMaterial => kitMaterial.filter(d => d.ProductId !== id));
    } else {
      setKitMaterial(kitMaterial => [...kitMaterial, { ProductId: id }]);
    }
  };
  const handleLaborSelect = (e, id) => {

    if (!e.target.checked) {
      setKitLabor(kitLabor => kitLabor.filter(d => d.LaborItemId !== id));
    } else {
      setKitLabor(kitLabor => [...kitLabor, { LaborItemId: id }]);
    }
  };
  useEffect(() => {
    if (isEditedList) {

      let data = kit ? kit[0] : [];
      setEditId(data?.kitId);
      if (data) {
        if (data.kitMaterial) {

          setKitMaterial(data.kitMaterial.filter(labor => {
            if (labor.isSelected === true) {
              return {
                ProductId: labor.productId
              }
            }

          }).map(m => { return { "ProductId": m.productId } }))
        }
        if (data.kitLabor) {

          setKitLabor(data.kitLabor.filter(d => {
            if (d.isSelected === true) {
              return d
            }
          }).map(m => { return { "LaborItemId": m.laborItemId } }))
        }
      }

      setIsKitOpen(!isKitOpen)
    }
  }, [isEditedList])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!kitMaterial.length > 0 && !kitLabor.length > 0) {
      toast.error("At least one item is required.")
    } else {
      var requestData =
      {
        "data": {
          "KitId": state?.KitId ? state?.KitId : 0,
          "StoreId": parseInt(state.StoreId),
          "ProductStructureId": parseInt(state.ProductTypeId),
          "ProductId": state.ProductId ?? 0,
          "KitMaterial": kitMaterial,
          "KitLabor": [...new Map(kitLabor.map(item => [JSON.stringify(item), item])).values()],
          "CreatedBy": auth[0].userId,
          "subProductStructureId": parseInt(state.subProductTypeId),
        },
        "token": auth[0].token
      }
      updateKit(requestData)
    }

  }
  const resetCopy = () => {
    setIsCopyProduct(false);
    setCopyProductId("")
    setPrStoreId("")
    setCopyStoreId("")
    setValidatedCopy(false);
    setStoreLoading(false)
  }
  // use effect function called after api success
  useMemo(() => {
    if (isUpdated === true) {
      toast.success("Kit updated successfully.")
      hideKitModel();
      changeReducerState()
    }
    if (kitUpdated?.[0] === kitUpdateInfo) {
      toast.error(kitUpdateInfo)
      changeReducerState()
    }
    if (isCopy === true) {

      toast.success("Product Copied Successfully")
      resetCopy();
      changeReducerState()

    }
    setCopyFetch(isCopy)
  }, [isUpdated, isCopy, kitUpdated]);

  const handleAddStore = (e, data) => {
    e.preventDefault();
    resetCopy()
    setIsCopyProduct(!isCopy);
    setCopyProductId(data.ProductId)
    setPrStoreId(data.StoreId)
    setStoreLoading(false);
  }
  const handleCopyStoreChange = (e) => {
    setCopyStoreId(e.target.value)
    setStoreLoading(false);
  }
  const hideCopyModel = () => setIsCopyProduct(false);
  const handleCopySubmit = (event) => {
    event.preventDefault();
    setStoreLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let requestData =
      {
        "data": {
          "ProductId": parseInt(copyProductId),
          "StoreId": parseInt(copyStoreId)
        },
        "token": auth[0].token
      }
      copyProductStore(requestData)
    }
    setValidatedCopy(true);
  }
  // Commented on 14 Dec 2022 for removing sub-product structure filter and list
  // handle sub product structure listing
  // const showSubProductData = useMemo(() => {
  //   if(parseInt(filterState?.ProductStructureId) === WALLBASE_ID_DB || parseInt(filterState?.ProductStructureId) === INSTALLATION_ACCESSORIES_ID_DB || parseInt(filterState?.ProductStructureId) === TRANSITION_ID_DB) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }, [filterState])
  return (
    <Fragment>
      <div className="procatlist">
      <Table bordered responsive className="text-center">
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={isMore}
          loader={
            <div className="data-loading">
              <img src="https://64.media.tumblr.com/695ce9a82c8974ccbbfc7cad40020c62/tumblr_o9c9rnRZNY1qbmm1co1_1280.gifv" />
              <span>Load More</span>
            </div>
          }>
          <thead>
            <tr>
              <th>
                <Form.Group controlId="Stock">
                  <Form.Label>Stock</Form.Label>
                  <Form.Check type="checkbox" onChange={
                    handleMultiSelect
                  }
                    ids="IsStock" />
                </Form.Group>
              </th>
              <th>
                <Form.Group controlId="RecurringStock">
                  <Form.Label>Recurring Stock</Form.Label>
                  <Form.Check type="checkbox" onChange={
                    handleStockMultiSelect
                  }
                    ids="IsRecurringStock" />
                </Form.Group>
              </th>
              <th>
                <Select label={`Product Structure`} result={productData} ids="ProductStructureId" value={filterState.ProductStructureId} handleChange={handleFilterChange} validate={false} />

              </th>
              {/*Commented on 14 Dec 2022 for removing sub-product structure filter and list*/}
              {/* <th>
                <Select label={`Sub Product Structure`} result={showSubProductData ? subProductData : []} ids="subProductTypeId" value={filterState.subProductTypeId} handleChange={handleFilterChange} validate={false} />
              </th> */}
              <th>
                <Select label={`Store Name`} result={storeData} ids="StoreId" value={filterState.StoreId} handleChange={handleFilterChange} validate={false} />

              </th>
              <th>
                <Input label={`Private Name`} ids={`PrivateName`} value={filterState.PrivateName} validate={false} handleChange={handleFilterChange} />

              </th>
              <th>
                <Input label={`Manufacturer`} ids={`Manufacturer`} value={filterState.Manufacturer} validate={false} handleChange={handleFilterChange} />

              </th>
              <th>
                <Input label={`Style`} ids={`Style`} value={filterState.Style} validate={false} handleChange={handleFilterChange} />

              </th>
              <th>
                <Input label={`Color`} ids={`Color`} value={filterState.Color} validate={false} handleChange={handleFilterChange} />

              </th>
              <th>
                <CustomCurrencyInput label={`Current Cost`} value={filterState.CurrentCost} ids={`CurrentCost`} validate={false} handleChange={handleCurrencyChange} />
              </th>
              <th>
                <CustomCurrencyInput label={`Current Freight`} value={filterState.CurrentFreight} ids={`CurrentFreight`} validate={false} handleChange={handleCurrencyChange} />
              </th>
              <th>
                <CustomCurrencyInput label={`Future Cost 1`} value={filterState.futureCost1} ids={`futureCost1`} validate={false} handleChange={handleCurrencyChange} />
              </th>
              <th>
                <CustomCurrencyInput label={`Future Freight 1`} value={filterState.futureFreight1} ids={`futureFreight1`} validate={false} handleChange={handleCurrencyChange} />

              </th>
              <th>
                <DateInput label={`Increase Date 1`} value={filterState.IncreaseDate1} ids={`IncreaseDate1`} validate={false} handleChange={handleFilterChange} />

              </th>
              <th>
                <CustomCurrencyInput label={`Future Cost 2`} value={filterState.futureCost2} ids={`futureCost2`} validate={false} handleChange={handleCurrencyChange} />

              </th>
              <th>
                <CustomCurrencyInput label={`Future Freight 2`} value={filterState.futureFreight2} ids={`futureFreight2`} validate={false} handleChange={handleCurrencyChange} />

              </th>
              <th>
                <DateInput label={`Increase Date 2`} value={filterState.IncreaseDate2} ids={`IncreaseDate2`} validate={false} handleChange={handleFilterChange} />

              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) ? _.map(data, (prod) => {

              return (

                <tr key={prod.ProductId}>

                  <td>
                    <Form.Group controlId="Stock">
                      <Form.Check type="checkbox" checked={selectedId.some(
                        d =>
                          d ===
                          prod.ProductId
                      ) || prod.IsStock}
                        onChange={e =>
                          handleSingleSelect(
                            e,
                            prod
                          )
                        } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="Stock">
                      <Form.Check type="checkbox" checked={stockSelectedId.some(
                        d =>
                          d ===
                          prod.ProductId
                      ) || prod.IsRecurringStock}
                        onChange={e =>
                          handleStockSingleSelect(
                            e,
                            prod
                          )
                        } />
                    </Form.Group>
                  </td>
                  <td>{_.get(prod, "ProductStructure", "")}</td>
                  {/*Commented on 14 Dec 2022 for removing sub-product structure filter and list*/}
                  {/* <td>{_.get(prod, "subProductStructureName", "")}</td> */}
                  <td>{_.get(prod, "StoreName", "")}</td>
                  <td>{_.get(prod, "PrivateName", "")}</td>
                  <td>{_.get(prod, "Manufacturer", "")}</td>
                  <td>{_.get(prod, "Style", "")}</td>
                  <td>{_.get(prod, "Color", "")}</td>
                  <td>{parseFloat(_.get(prod, "CalculatedCurrentCost", "")).toFixed(2)}</td>
                  <td>{parseFloat(_.get(prod, "CalculatedCurrentFreight", "")).toFixed(2)}</td>
                  <td>
                    <CustomCurrencyInput ids={`futureCost1`} validate={false} handleChange={(value, name) => handleCurrencyUpdateChange(value, name, prod)} value={(parseInt(product_id) === prod.ProductId) ? handleValue(updateState.futureCost1) : handleValue1(prod.futureCost1)} />
                  </td>
                  <td>
                    <CustomCurrencyInput ids={`futureFreight1`} validate={false} handleChange={(value, name) => handleCurrencyUpdateChange(value, name, prod)} value={(parseInt(product_id) === prod.ProductId) ? handleValue(updateState.futureFreight1) : handleValue1(prod.futureFreight1)} />
                  </td>
                  <td>
                    <DateInput ids={`IncreaseDate1`} validate={false} handleChange={(e) => handleUpdateChange(e, prod)} value={(parseInt(product_id) === prod.ProductId) ? moment(new Date(`${updateState.IncreaseDate1}`)).format("YYYY-MM-DD") : moment(new Date(`${prod.IncreaseDate1}`)).format("YYYY-MM-DD")} />
                  </td>
                  <td>
                    <CustomCurrencyInput ids={`futureCost2`} validate={false} handleChange={(value, name) => handleCurrencyUpdateChange(value, name, prod)} value={(parseInt(product_id) === prod.ProductId) ? handleValue(updateState.futureCost2) : handleValue1(prod.futureCost2)} />
                  </td>
                  <td>
                    <CustomCurrencyInput ids={`futureFreight2`} validate={false} handleChange={(value, name) => handleCurrencyUpdateChange(value, name, prod)} value={(parseInt(product_id) === prod.ProductId) ? handleValue(updateState.futureFreight2) : handleValue1(prod.futureFreight2)} />
                  </td>
                  <td>
                    <DateInput ids={`IncreaseDate2`} validate={false} handleChange={(e) => handleUpdateChange(e, prod)} value={(parseInt(product_id) === prod.ProductId) ? moment(new Date(`${updateState.IncreaseDate2}`)).format('YYYY-MM-DD') : moment(new Date(`${prod.IncreaseDate2}`)).format('YYYY-MM-DD')} />
                  </td>
                  <td>
                    <div className="d-flex">
                      <Button variant="flat" className="px-1 py-0" onClick={(e) => handleEdit(e, prod)}>
                        <Icon size={18} icon={ic_mode_edit} />
                      </Button>
                      <ConfirmDelete variant="primary" title={`Product`} showConfirmDeleteModal={() => showConfirmDeleteModal(prod.ProductId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      {/*{(prod.ProductStructureId === CARPET_ID_DB || prod.ProductStructureId === SHEET_VINYL_ID_DB || prod.ProductStructureId === VINYL_PLANK_ID_DB) ? (*/}
                        <Button variant="primary" className="px-1 py-0" onClick={(e) => handleKitModal(e, prod)}
                          // disabled={prod.kitId ? false : true}>Kit</Button>
                          >Kit</Button>
                      {/*) : null}*/}

                      <Button variant="primary" className="px-1 py-0 ms-1" onClick={(e) => handleAddStore(e, prod)}
                      >Add Store</Button>

                    </div>
                  </td>
                </tr>

              )

            }) : (
              <tr>
                <td colSpan={`18`}>No Data Found</td>
              </tr>
            )}
          </tbody>

        </InfiniteScroll>
      </Table>
      <Kit
        isKitOpen={isKitOpen}
        hideKitModel={hideKitModel}
        size={`lg`}
        productStructure={productStruct}
        storeList={storeList}
        otherProductStructure={otherProductStructure}
        laborCategories={laborCategories}
        kitMaterial={kitMaterial}
        kitLabor={kitLabor}
        state={state}
        editId={editId}
        mAddon={mAddon}
        lAddon={lAddon}
        handleMaterialFilterChange={handleMaterialFilterChange}
        handleLaborFilterChange={handleLaborFilterChange}
        handleSubmit={handleSubmit}
        handleMaterialSelect={handleMaterialSelect}
        handleLaborSelect={handleLaborSelect}
      />
      <CopyProduct
        size={`xs`}
        storeList={storeList}
        isCopy={isCopyProduct}
        handleCopyStoreChange={handleCopyStoreChange}
        copyProductId={copyProductId}
        copyStoreId={copyStoreId}
        prStoreId={prStoreId}
        hideCopyModel={hideCopyModel}
        validatedCopy={validatedCopy}
        handleCopySubmit={handleCopySubmit}
        storeLoading={storeLoading}
      />
      </div>
    </Fragment >
  )

}
const mapStateToProps = ({ auth, LaborCategory, kits, copyProduct }) => {
  return {
    auth, kits, LaborCategory, copyProduct
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLaborCategory: (token) => dispatch({ type: LABOR_CATEGORY_REQUEST, token }),
    fetchEditKit: (token, data) => dispatch({ type: KIT_EDIT_REQUEST, token, data }),
    fetchMaterialAddons: (token, data) => dispatch({ type: MATERIAL_ADDONS_REQUEST, token, data }),
    fetchLaborAddons: (token, data) => dispatch({ type: LABOR_ADDONS_REQUEST, token, data }),
    updateKit: (data) => dispatch({ type: KIT_UPDATE_REQUEST, data }),
    copyProductStore: (data) => dispatch({ type: PRODUCT_CATALOG_COPY_PRODUCT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_CATALOG_STATE_CHANGE }),
    changeKitState: () => dispatch({ type: KIT_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogList);