import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { constantIds, constantProducts } from "../../../../config";
import Select from "../../../elements/CustomSelect";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { ic_delete_outline } from "react-icons-kit/md/ic_delete_outline";
import { toast } from "react-toastify";
import {
  CARPET_ID_DB,
  SHEET_VINYL_ID_DB,
  VINYL_PLANK_ID_DB,
  WALLBASE_ID_DB,
  INSTALLATION_ACCESSORIES_ID_DB,
  TRANSITION_ID_DB,
} from "../../../../_utils/constants/ProductCatalog";
import Quality from "./Quality.jsx"
import PropertyList from "./PropertyList";

const ChangeJob = ({
  handleMakeChangeModalOpen,
  handleMakeChangeModalClose,
  startDate,
  setStartDate,
  handleRescheduleChange,
  handleMakeChangeClose,
  handleFetchRescheduledData,
  rescheduleData,
  changeJobHeader,
  setChangeJobHeader,
  handleRescheduleCancel,
  handleReschedule,
  handleShortage,
  handleQuality,
  handleOrdering,
  handleAddOn,
  handleClickNewMaterialItem,
  handleClickNewLabourItem,
  handleAddOnFormChange,
  addOnData,
  materialListData,
  orderInstallationListData,
  handleAddOnSubmit,
  handleorderShortageSubmit,
  handleAddOnCancel,
  orderAddOnDetails,
  handleFormat,
  handleStateChange,
  shortageReason,
  qualityReasons,
  jobNum,
  auth,
  saveOrderQualityDetailRequest,
  saveOrderQualityDetailData,
  customerData,
  orderingUrl,
  localStorageData,
  localStorageLaborData,
  lsUpdate,
  setLsLaborUpdate,
  lsLaborUpdate,
  lsLaborMaterialUpdate,
  lsAllLaborMaterialCarpetData,

}) => {

  // const [ materialAddOnData, setMaterialAddOnData ] = useState([])
  const [productMaterialAddOn, setProductMaterialAddOn] = useState([])
  const [productLaborAddOn, setProductLaborAddOn] = useState([])
  const [materialAddOnUnitPrice, setMaterialAddOnUnitPrice] = useState()
  const [materialAddOnLinePrice, setMaterialAddOnLinePrice] = useState()
  const [laborAddOnUnitPrice, setLaborAddOnUnitPrice] = useState()
  const [laborAddOnLinePrice, setLaborAddOnLinePrice] = useState()
  const [getAddOnData, setGetAddOnData] = useState({})
  const [lsMaterialListData, setLsMaterialListData] = useState()
  const [lsLaborListData, setLsLaborListData] = useState()

  const [ orderShortageDetail, setOrderShortageDetail] = useState({"calledInBy":"","description":"","enteredBy":""})
  const [ orderShortageMaterial, setOrderShortageMaterial] = useState([])
  const [ orderShortageMaterialUnitPrice, setOrderShortageMaterialUnitPrice ] = useState()
  const [ orderShortageMaterialLinePrice, setOrderShortageMaterialLinePrice ] = useState()
  const [ orderShortageLabor, setOrderShortageLabor] = useState([])
  const [ orderShortageLaborUnitPrice, setOrderShortageLaborUnitPrice ] = useState()
  const [ orderShortageLaborLinePrice, setOrderShortageLaborLinePrice ] = useState()
  const [ orderShortageReasons, setOrderShortageReasons ] = useState(null)

  const updateMaterialListData = (data) => {
    setLsMaterialListData(data)
  }

  useEffect(() => { localStorage.removeItem("selectedAllCarpetData"); localStorage.removeItem("selectedAllLaborMaterialCarpetData"); localStorage.removeItem("copySelectedLaborList") }, [])

  useEffect(() => {
    const addOnData = localStorage.getItem("selectedAllCarpetData");
    const parsedData = JSON.parse(addOnData);
    // const addonData2 = localStorage.getItem("selectedAllLaborMaterialCarpetData")
    // const parsedData2 = JSON.parse(addonData2)

    // Ensure parsedData is an object
    setGetAddOnData(
      typeof parsedData === "object" && parsedData !== null ? parsedData : {}
    );
  }, []);

  // handle material list data
  useMemo(() => {
    if (materialListData && materialListData.length > 0) {
      let tempMaterialListData = materialListData.map(data => ({
        ...data,
        quantity: "",     // Reset quantity
        linePrice: ""     // Reset line price
        // Uncomment the following if you want to reset unitPrice as well
        // unitPrice: ''
      }));
      updateMaterialListData(tempMaterialListData);
    }
  }, [materialListData]);

  useEffect(() => {

    if (lsMaterialListData && lsMaterialListData.length > 0) {
      let tempMaterialListData = lsMaterialListData

      // Merging localStorageData into tempMaterialListData
      if (lsUpdate && localStorageData && Object.keys(localStorageData).length > 0) {
        Object.values(localStorageData).forEach(productArray => {
          productArray.forEach(product => {
            tempMaterialListData.push({
              primaryProduct: product.primaryProduct,
              productId: product.productId,
              quantity: product.quantity,
              unitName: product.unit,
              privateName: product.pvtName,
              style: product.style,
              color: product.color,
              unitPrice: product.unitPrice,
              linePrice: product.linePrice,
              IsOrderAddOnProduct: product.IsOrderAddOnProduct,
            });
          });
        });
      }

      let updatedList = Array.from(
        tempMaterialListData.reduce((map, item) => {
          map.set(item.productId, item);
          return map;
        }, new Map()).values()
      );

      updateMaterialListData(updatedList);
    }
  },[lsUpdate, localStorageData])

  useEffect(() => {
    if (lsMaterialListData && lsMaterialListData.length > 0) {
          let tempMaterialListData = lsMaterialListData
      if (lsLaborMaterialUpdate && lsAllLaborMaterialCarpetData && lsAllLaborMaterialCarpetData.materials && lsAllLaborMaterialCarpetData.materials.length > 0) {
        lsAllLaborMaterialCarpetData.materials.forEach(product => {
          tempMaterialListData.push({
            primaryProduct: product.primaryProduct,
            productId: product.productId,
            quantity: product.quantity,
            unitName: product.unitName,
            materialId: product.materialId,
            privateName: product.privateName,
            style: product.style,
            color: product.color,
            unitPrice: product.price,
            linePrice: product.linePrice,
          });
        });
      }

      let updatedList = Array.from(
        tempMaterialListData.reduce((map, item) => {
          map.set(item.productId, item);
          return map;
        }, new Map()).values()
      );

      updateMaterialListData(updatedList);
    }
  },[lsLaborMaterialUpdate,lsAllLaborMaterialCarpetData])

  // Commented on 10/7/2024 | Just for Backup Purpose
  // useMemo(() => {
  //   if (materialListData && materialListData.length > 0) {
  //     let tempMaterialListData = materialListData.map(data => ({
  //       ...data,
  //       quantity: "",     // Reset quantity
  //       linePrice: ""     // Reset line price
  //       // Uncomment the following if you want to reset unitPrice as well
  //       // unitPrice: ''
  //     }));

  //     // Merging localStorageData into tempMaterialListData
  //     if (lsUpdate && localStorageData && Object.keys(localStorageData).length > 0) {
  //       Object.values(localStorageData).forEach(productArray => {
  //         productArray.forEach(product => {
  //           tempMaterialListData.push({
  //             primaryProduct: product.primaryProduct,
  //             productId: product.productId,
  //             quantity: product.quantity,
  //             unitName: product.unit,
  //             privateName: product.pvtName,
  //             style: product.style,
  //             color: product.color,
  //             unitPrice: product.unitPrice,
  //             linePrice: product.linePrice,
  //           });
  //         });
  //       });
  //     }

  //     if (lsLaborMaterialUpdate && lsAllLaborMaterialCarpetData && lsAllLaborMaterialCarpetData.materials && lsAllLaborMaterialCarpetData.materials.length > 0) {
  //         lsAllLaborMaterialCarpetData.materials.forEach(product => {
  //           tempMaterialListData.push({
  //             primaryProduct: product.primaryProduct,
  //             productId: product.productId,
  //             quantity: product.quantity,
  //             unitName: product.unitName,
  //             materialId: product.materialId,
  //             privateName: product.privateName,
  //             style: product.style,
  //             color: product.color,
  //             unitPrice: product.price,
  //             linePrice: product.linePrice,
  //           });
  //       });
  //     }

      // let updatedList = Array.from(
      //   tempMaterialListData.reduce((map, item) => {
      //     map.set(item.productId, item);
      //     return map;
      //   }, new Map()).values()
      // );

  //     updateMaterialListData(updatedList);
  //   }
  // }, [lsUpdate, materialListData, localStorageData , lsLaborMaterialUpdate]);

  // Commented on 10/7/2024 | Just for Backup Purpose
  // handle material list data
  // useMemo(() => {
  //   if (orderInstallationListData && orderInstallationListData.length > 0) {
  //     let tempOrderInstallationListData = orderInstallationListData;
  //     tempOrderInstallationListData.map((data) => {
  //       (data.quantity = ""),
  //         // data.unitPrice = '',
  //         (data.linePrice = "");
  //     });
  //     setLaborAddOnData(orderInstallationListData);
  //   }
  // }, [orderInstallationListData])


  // useMemo(() => { 
  //   if (orderInstallationListData && orderInstallationListData.length > 0) {
  //     // Create a copy of orderInstallationListData
  //     let tempOrderInstallationListData = orderInstallationListData.map(data => ({
  //       ...data,
  //       quantity: "",   // Reset quantity
  //       linePrice: ""   // Reset line price
  //       // Uncomment if you want to reset unitPrice as well
  //       // unitPrice: ''
  //     }));

  //     // Merging localStorageLaborData into tempOrderInstallationListData
  //     if (lsLaborListData && localStorageLaborData && localStorageLaborData.length > 0) {
  //       localStorageLaborData.forEach(laborItem => {
  //         tempOrderInstallationListData.push({
  //           laborItemId: laborItem.LaborItemId,
  //           laborId: laborItem.LaborId,
  //           quantity: laborItem.Quantity,
  //           linePrice: laborItem.LinePrice,
  //           orderInstallationDetailId: laborItem.OrderInstallationDetailId,
  //           unitName: laborItem.UnitName,
  //           unitPrice: laborItem.UnitPrice,
  //           laborCategory: laborItem.LaborCategory,
  //           labor: laborItem.Labor,
  //         });
  //       });
  //     }

    //   if (lsLaborMaterialUpdate && lsAllLaborMaterialCarpetData && lsAllLaborMaterialCarpetData.labors && lsAllLaborMaterialCarpetData.labors.length > 0) {
    //     lsAllLaborMaterialCarpetData.labors.forEach(laborItem => {
    //       tempOrderInstallationListData.push({
    //         laborItemId: laborItem.laborItemId,
    //         laborId: laborItem.laborId,
    //         quantity: laborItem.quantity,
    //         linePrice: laborItem.linePrice,
    //         unitName: laborItem.unitName,
    //         unitPrice: laborItem.price,
    //         laborCategory: laborItem.laborCategory,
    //         labor: laborItem.laborName,
    //       });
    //   });
    // }
  //   console.log("tempOrderInstallationListData lsAllLaborMaterialCarpetData : ",lsAllLaborMaterialCarpetData)

  //     // setLaborAddOnData(tempOrderInstallationListData); // Update the state with new data
  //     let updatedList = Array.from(
  //       tempOrderInstallationListData.reduce((map, item) => {
  //         map.set(item.laborItemId, item); 
  //         return map;
  //       }, new Map()).values() 
  //     );
  //     console.log("updatedList L: : ",updatedList)
  //     setLsLaborListData(updatedList);
  //   }
  // }, [lsLaborUpdate, orderInstallationListData, localStorageLaborData, lsLaborMaterialUpdate]);

  useMemo(() => {
    if (orderInstallationListData && orderInstallationListData.length > 0) {
      let tempOrderInstallationListData = orderInstallationListData
      tempOrderInstallationListData.map(data => {
        data.quantity = '',
          // data.unitPrice = '',
          data.linePrice = ''
      })
      setLsLaborListData(tempOrderInstallationListData);
    }
  }, [orderInstallationListData])

  // updated shortage reasons in from api
  useEffect(() => {
    if (shortageReason?.result.length > 0) {
      const updatedReasons = shortageReason.result.map((reason) => ({
        ...reason,
        isSelected: false,
      }));
      setOrderShortageReasons(updatedReasons);
    }
  }, [shortageReason]);

  const handleChangeOrderShortageReasons = (e, shortageReasonId) => {
    const isChecked = e.target.checked;
    setOrderShortageReasons((prevReasons) =>
      prevReasons.map((reason) =>
        reason.shortageReasonId === shortageReasonId
          ? { ...reason, isSelected: isChecked }
          : reason
      )
    );
  };

  const chunkArray = (array, size) =>
    array.reduce((acc, _, i) =>
      i % size === 0 ? [...acc, array.slice(i, i + size)] : acc, []);

  // Reason for Shortage checkbox
  const ShortageReasonsComponent = ( shortageReasons ) => {
    const itemsPerColumn = 4;
    const chunks = chunkArray(shortageReasons, itemsPerColumn);
    return (
      <Row>
        {chunks.map((chunk, index) => (
          <Col md={4} key={index}>
            <Form.Group>
              {chunk.map((item) => (
                <Form.Check
                  key={item.shortageReasonId}
                  type="checkbox"
                  id={`custom-switch-${item.shortageReasonId}`}
                  onChange={(e) => handleChangeOrderShortageReasons(e, item.shortageReasonId)}
                  checked={orderShortageReasons.find(reason => reason.shortageReasonId === item.shortageReasonId)?.isSelected || false}
                  label={item.shortageReason}
                />
              ))}
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };
  
  // Change of Shortage Detail
  const handleOrderShortageDetailChange = (e) =>{
    const {name, value} = e.target
    setOrderShortageDetail((prev)=>({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    if (lsLaborListData && lsLaborListData.length > 0) {
      let tempOrderInstallationListData = lsLaborListData
      // Merging localStorageLaborData into tempOrderInstallationListData
      if (lsLaborListData && localStorageLaborData && localStorageLaborData.length > 0) {
        localStorageLaborData.forEach(laborItem => {
          tempOrderInstallationListData.push({
            laborItemId: laborItem.LaborItemId,
            laborId: laborItem.LaborId,
            quantity: laborItem.Quantity,
            linePrice: laborItem.LinePrice,
            orderInstallationDetailId: laborItem.OrderInstallationDetailId,
            unitName: laborItem.UnitName,
            unitPrice: laborItem.UnitPrice,
            laborCategory: laborItem.LaborCategory,
            labor: laborItem.Labor,
          });
        });
      }
      let updatedList = Array.from(
        tempOrderInstallationListData.reduce((map, item) => {
          map.set(item.laborItemId, item);
          return map;
        }, new Map()).values()
      );
      console.log("updatedList L: : ", updatedList)
      setLsLaborListData(updatedList);
    }
  }, [localStorageLaborData])

  useEffect(() => {
    if (lsLaborListData && lsLaborListData.length > 0) {
      let tempOrderInstallationListData = lsLaborListData

      if (lsLaborMaterialUpdate && lsAllLaborMaterialCarpetData && lsAllLaborMaterialCarpetData.labors && lsAllLaborMaterialCarpetData.labors.length > 0) {
        lsAllLaborMaterialCarpetData.labors.forEach(laborItem => {
          tempOrderInstallationListData.push({
            laborItemId: laborItem.laborItemId,
            laborId: laborItem.laborId,
            quantity: laborItem.quantity,
            linePrice: laborItem.linePrice,
            unitName: laborItem.unitName,
            unitPrice: laborItem.price,
            laborCategory: laborItem.laborCategory,
            labor: laborItem.laborName,
          });
        });
      }

      let updatedList = Array.from(
        tempOrderInstallationListData.reduce((map, item) => {
          map.set(item.laborItemId, item);
          return map;
        }, new Map()).values()
      );

      setLsLaborListData(updatedList);
  }
  }, [lsLaborMaterialUpdate,lsAllLaborMaterialCarpetData])

  // function to handle product decimal modification
  const handlePdtDecimalModif = (tempList) => {
    let pdtList = tempList[0]?.addOnProducts;
    let LabList = tempList[0]?.addOnLabors;
    pdtList.map((data) => {
      data.unitPrice = data?.unitPrice
        ? parseFloat(data?.unitPrice).toFixed(2)
        : "";
      if (data.kitMaterials && data.kitMaterials.length > 0) {
        data.kitMaterials.map((kitData) => {
          kitData.unitPrice = kitData.unitPrice
            ? parseFloat(kitData.unitPrice).toFixed(2)
            : "";
        });
      }
      if (data.kitLabors && data.kitLabors.length > 0) {
        data.kitLabors.map((kitData) => {
          kitData.unitPrice = kitData.unitPrice
            ? parseFloat(kitData.unitPrice).toFixed(2)
            : "";
        });
      }
    })
    LabList.map(data => {
      data.unitPrice = data?.unitPrice ? parseFloat(data?.unitPrice).toFixed(2) : ''
    })
  }
  
  // function to update the response data for rendering in UI
  function updateUnitPrice(responseData) {
    responseData.map(item => {
     if(constantIds.includes(item.productid)){if (item.unitPrice===""){item.unitPrice ="0.00" }}
     if(constantProducts.includes(item.privatename)){if (item.unitPrice===""){item.unitPrice ="0.00" }}
   });
   return responseData;
 }

  // handle product add on data
  useEffect(() => {
    if (orderAddOnDetails && orderAddOnDetails.length > 0) {
      let tempPdtList = orderAddOnDetails
      handlePdtDecimalModif(tempPdtList)
      setProductMaterialAddOn(updateUnitPrice(orderAddOnDetails[0]?.addOnProducts))
      setProductLaborAddOn(orderAddOnDetails[0]?.addOnLabors)
    }
  }, [orderAddOnDetails]);

  // handle labor add on data
  useEffect(() => {
    if (lsLaborListData && lsLaborListData.length > 0) {
      let tempUnitPrice = 0.0;
      let tempLinePrice = 0.0;
      lsLaborListData.map((data) => {
        tempUnitPrice += parseFloat(data.unitPrice)
          ? parseFloat(data.unitPrice)
          : 0.0;
        tempLinePrice += parseFloat(data.linePrice)
          ? parseFloat(data.linePrice)
          : 0.0;
      });
      setLaborAddOnUnitPrice(tempUnitPrice);
      setLaborAddOnLinePrice(tempLinePrice);
    }
  }, [lsLaborListData])

  useEffect(() => {
    if (orderShortageLabor && orderShortageLabor.length > 0) {
      let tempUnitPrice = 0.00
      let tempLinePrice = 0.00
      orderShortageLabor.map(data => {
        tempUnitPrice += parseFloat(data.unitPrice) ? parseFloat(data.unitPrice) : 0.00
        tempLinePrice += parseFloat(data.linePrice) ? parseFloat(data.linePrice) : 0.00
      })
      setOrderShortageLaborUnitPrice(tempUnitPrice)
      setOrderShortageLaborLinePrice(tempLinePrice)
    }
  }, [orderShortageLabor])

  useEffect(() => {
    // Iterate over each key in getAddOnData
    Object.keys(getAddOnData).forEach((key) => {
      const dataArray = getAddOnData[key];

      // Ensure dataArray is an array before calling map
      if (Array.isArray(dataArray)) {
        const colors = dataArray.map((item) => item.color);
        console.log(`Colors for key ${key}:`, colors);
      } else {
        console.warn(`Data for key ${key} is not an array or is undefined.`);
      }
    });
  }, [getAddOnData]);

  // handle material shortage data
  useEffect(() => {
    if (orderShortageMaterial && orderShortageMaterial.length > 0) {
      let tempUnitPrice = 0.00
      let tempLinePrice = 0.00
      orderShortageMaterial.map(data => {
        tempUnitPrice += parseFloat(data.unitPrice) ? parseFloat(data.unitPrice) : 0.00
        tempLinePrice += parseFloat(data.linePrice) ? parseFloat(data.linePrice) : 0.00
      })
      setOrderShortageMaterialUnitPrice(tempUnitPrice)
      setOrderShortageMaterialLinePrice(tempLinePrice)
    }
  }, [orderShortageMaterial])

  // handle material add on data
  useEffect(() => {
    if (lsMaterialListData && lsMaterialListData.length > 0) {
      let tempUnitPrice = 0.0;
      let tempLinePrice = 0.0;
      let AddOnUnitPrice = 0.0;
      let AddOnLinePrice = 0.0;

      Object.keys(getAddOnData).forEach((key) => {
        const dataAddon = getAddOnData[key];

        // Ensure dataAddon is an array before mapping
        if (Array.isArray(dataAddon)) {
          dataAddon.forEach((item) => {
            AddOnUnitPrice += parseFloat(item.unitPrice) || 0; // Accumulate unitPrice
            AddOnLinePrice += parseFloat(item.linePrice) || 0; // Accumulate linePrice
          });
        }
      });
      lsMaterialListData.map((data) => {
        tempUnitPrice += parseFloat(data.unitPrice)
          ? parseFloat(data.unitPrice)
          : 0.0;
        tempLinePrice += parseFloat(data.linePrice)
          ? parseFloat(data.linePrice)
          : 0.0;
      });
      setMaterialAddOnUnitPrice(tempUnitPrice + AddOnUnitPrice);
      setMaterialAddOnLinePrice(tempLinePrice + AddOnLinePrice);
    }
  }, [lsMaterialListData])

  // function to reset state
  const addOnResetState = () => {
    // setLaborAddOnData([])
    // setMaterialAddOnData([])
    setProductMaterialAddOn([])
    setProductLaborAddOn([])
    setMaterialAddOnUnitPrice([])
    setMaterialAddOnLinePrice([])
    setLaborAddOnUnitPrice([])
    setLaborAddOnLinePrice([])
  }

  // handle cancel click
  const handleAddOnCancelClick = () => {
    addOnResetState();
    handleAddOnCancel();
    fetchOrderInstallationMaterial(auth[0].token, jobNum);
    fetchOrderInstallationOrder(auth[0].token, jobNum);
  };

  // const for select values
  const rescheduleChoices = [
    { label: "Customer Requested", value: "Customer Requested" },
    { label: "Uncovered Labor", value: "Uncovered Labor" },
    { label: "Material Stockout", value: "Material Stockout" },
  ];

  // handle edit change on material listing
  // const handleAddOnMaterialEdit = (value, data, filterValue) => {
  //   if(materialListData && materialListData.length > 0) {
  //     let materialData = materialListData
  //     materialData.map(editedData => {
  //       if((data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId) && filterValue === 'quantity') {
  //         editedData.quantity = value
  //         editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
  //       }
  //       if (
  //         data.orderMaterialId === editedData.orderMaterialId &&
  //         data.orderProductId === editedData.orderProductId &&
  //         filterValue === "unitPrice"
  //       ) {
  //         editedData.unitPrice = value;
  //         editedData.linePrice = parseFloat(
  //           parseFloat(value) * parseFloat(editedData.quantity)
  //         ).toFixed(2);
  //       }
  //       if (
  //         data.orderMaterialId === editedData.orderMaterialId &&
  //         data.orderProductId === editedData.orderProductId &&
  //         filterValue === "linePrice"
  //       ) {
  //         editedData.linePrice = value;
  //         editedData.quantity = parseFloat(
  //           parseFloat(value) / parseFloat(editedData.unitPrice)
  //         ).toFixed(2);
  //       }
  //     })
  //     materialData = Object.values(materialData)
  //     setMaterialAddOnData(materialData)
  //   }
  // };

  const handleAddOnMaterialEdit = (value, data, filterValue, is_shortage) => {
    if (lsMaterialListData && lsMaterialListData.length > 0) {
      let materialData = lsMaterialListData
      materialData.map(editedData => {
        if ((data.orderMaterialId !== undefined || data.orderProductId !== undefined) && (data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId) && filterValue === 'quantity') {
          editedData.quantity = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
        }
        if ((data.orderMaterialId !== undefined || data.orderProductId !== undefined) && data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId &&
          filterValue === "unitPrice"
        ) {
          editedData.unitPrice = value;
          editedData.linePrice = parseFloat(
            parseFloat(value) * parseFloat(editedData.quantity)
          ).toFixed(2);
        }
        if ((data.orderMaterialId !== undefined || data.orderProductId !== undefined) && data.orderMaterialId === editedData.orderMaterialId && data.orderProductId === editedData.orderProductId &&
          filterValue === "linePrice"
        ) {
          editedData.linePrice = value;
          editedData.quantity = parseFloat(
            parseFloat(value) / parseFloat(editedData.unitPrice)
          ).toFixed(2);
        }

        if ((data.orderMaterialId === undefined || data.orderProductId === undefined) && data.productId === editedData.productId && filterValue === 'quantity') {
          editedData.quantity = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
        }
        if ((data.orderMaterialId === undefined || data.orderProductId === undefined) && data.productId === editedData.productId && filterValue === "unitPrice") {
          editedData.unitPrice = value;
          editedData.linePrice = parseFloat(
            parseFloat(value) * parseFloat(editedData.quantity)
          ).toFixed(2);
        }
        if ((data.orderMaterialId === undefined || data.orderProductId === undefined) && data.productId === editedData.productId && filterValue === "linePrice") {
          editedData.linePrice = value;
          editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice)).toFixed(2);
        }
      })
      materialData = Object.values(materialData)
      // setMaterialAddOnData(materialData)
      is_shortage ? setOrderShortageMaterial(materialData) : setLsMaterialListData(materialData)
    }
  };

  // handle material change
  const handleAddOnMaterialChange = (e, data, filterValue, is_shortage = false) => {
    handleAddOnMaterialEdit(e.target.value, data, filterValue, is_shortage)
  }

  // handle edit change on labor listing
  const handleAddOnLaborEdit = (value, data, filterValue, is_shortage) => {
    if (lsLaborListData && lsLaborListData.length > 0) {
      let laborData = lsLaborListData
      laborData.map(editedData => {
        if ((data.orderLaborId !== undefined || data.orderProductId !== undefined) && (data.orderLaborId === editedData.orderLaborId) && filterValue === 'quantity') {
          editedData.quantity = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
        } else if ((data.orderLaborId !== undefined || data.orderProductId !== undefined) && (data.orderLaborId === editedData.orderLaborId) && filterValue === 'unitPrice') {
          editedData.unitPrice = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.quantity)).toFixed(2)
        } else if ((data.orderLaborId !== undefined || data.orderProductId !== undefined) && (data.orderLaborId === editedData.orderLaborId) && filterValue === 'linePrice') {
          editedData.linePrice = value
          editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice)).toFixed(2)
        }

        if ((data.orderLaborId === undefined || data.orderProductId === undefined) && data.laborItemId === editedData.laborItemId && filterValue === 'quantity') {
          editedData.quantity = value
          editedData.linePrice = parseFloat(parseFloat(value) * parseFloat(editedData.unitPrice)).toFixed(2)
        }
        if ((data.orderLaborId === undefined || data.orderProductId === undefined) && data.laborItemId === editedData.laborItemId && filterValue === "unitPrice") {
          editedData.unitPrice = value;
          editedData.linePrice = parseFloat(
            parseFloat(value) * parseFloat(editedData.quantity)
          ).toFixed(2);
        }
        if ((data.orderLaborId === undefined || data.orderProductId === undefined) && data.laborItemId === editedData.laborItemId && filterValue === "linePrice") {
          editedData.linePrice = value
          editedData.quantity = parseFloat(parseFloat(value) / parseFloat(editedData.unitPrice)).toFixed(2)
        }
      })
      laborData = Object.values(laborData)
      setLsLaborListData(laborData)
      if(is_shortage){
        setOrderShortageLabor(laborData)
      }
    }
  };

  // handle labor change
  const handleAddOnLaborChange = (e, data, filterValue,is_shortage = false) => {
    handleAddOnLaborEdit(e.target.value, data, filterValue, is_shortage)
  }

  // unit constants
  const UnitsName = {
    SquareFoot: "SF",
    SquareYard: "SY",
    LinearFoot: "LF",
    EACH: "EA",
  };

  // function to handle unit conversion
  const unitConversion = (
    quantity,
    masterProductUnit,
    unit,
    masterProductCoverage = 1
  ) => {
    if (unit !== masterProductUnit) {
      if (
        masterProductUnit === UnitsName.SquareFoot &&
        unit === UnitsName.SquareYard
      ) {
        quantity = quantity / 9;
      } else if (
        masterProductUnit === UnitsName.SquareYard &&
        unit === UnitsName.SquareFoot
      ) {
        quantity = quantity * 9;
      } else {
        quantity = quantity;
      }
    }

    // return parseFloat(quantity.toFixed(2));
    return quantity;
  };

  // function to apply net area after waste
  const netAreaAfterApplyWaste = (netArea, wastePer) => {
    let totalQuantity = 0;

    if (netArea > 0) {
      if (wastePer > 0) {
        totalQuantity = netArea + (netArea * (parseFloat(wastePer) / 100));
      } else {
        totalQuantity = netArea;
      }
    } else {
      totalQuantity = netArea;
    }

    // return parseFloat(totalQuantity.toFixed(2));
    return totalQuantity;
  };

  // function to calculate quantity
  const calculateQuantity = (productQuantity, coverage) => {
    if (coverage > 0) {
      let quantity = (productQuantity / parseFloat(coverage)).toFixed(3);
      return Math.ceil(parseFloat(quantity));
    } else {
      return productQuantity;
    }
  };

  // function to calculate product quantity
  const calculateProductQuantity = (
    productStructureId,
    netArea,
    coverage,
    sfPerPiece,
    unit,
    waste,
    isMaterial,
    masterProductUnit
  ) => {
    // Net Area is in SF so converting SF to SY
    if (unit === UnitsName.SquareYard && !isMaterial) {
      netArea = netArea / 9;
    }
    if (isMaterial) {
      netArea = unitConversion(netArea, masterProductUnit, unit);
    }

    // Apply waste % on net area for master product
    const netAreaAfterWaste = isMaterial
      ? netArea
      : netAreaAfterApplyWaste(netArea, waste);

    // let quantity = 0;
    let quantity = parseFloat(netAreaAfterWaste);

    // Check if NetAreaAfterWaste greater than zero
    if (netAreaAfterWaste > 0) {
      quantity = netAreaAfterWaste;

      // Wallbase quantity calculation
      if (
        productStructureId === WALLBASE_ID_DB ||
        productStructureId === TRANSITION_ID_DB ||
        productStructureId === INSTALLATION_ACCESSORIES_ID_DB
      ) {
        if (productStructureId === WALLBASE_ID_DB) {
          // Calculate quantity based on wallbase Coverage unit
          if (masterProductUnit === UnitsName.LF) {
            quantity = calculateQuantity(netAreaAfterWaste, coverage);
          }

          // Check if wallbase unit is LF multiply CalculateQuantity to coverage
          if (unit === UnitsName.LF) {
            quantity = quantity * (coverage > 0 ? parseFloat(coverage) : 1);
            return quantity;
          } else if (unit === UnitsName.EA) {
            return quantity;
          }
        } else {
          quantity = calculateQuantity(netAreaAfterWaste, coverage);
          return quantity;
        }
      } else if (
        productStructureId === CARPET_ID_DB ||
        productStructureId === SHEET_VINYL_ID_DB
      ) {
        return quantity;
      } else if (productStructureId === VINYL_PLANK_ID_DB) {
        if (quantity > 0) {
          if (sfPerPiece > 0) {
            quantity = quantity / parseFloat(sfPerPiece);
            quantity = Math.ceil(parseFloat(quantity));
            quantity = parseFloat(
              (quantity * parseFloat(sfPerPiece)).toFixed(2)
            );
            return quantity;
          }
        }
      }
    }
    return quantity;
  };

  //function to handle product kits quantity
  const handleKitsQuantity = (editedData, value, filterValue) => {
    let tempEditedData = editedData;
    if (
      tempEditedData &&
      tempEditedData?.kitMaterials &&
      tempEditedData?.kitMaterials.length > 0
    ) {
      tempEditedData?.kitMaterials.map((data) => {
        if (filterValue === "quantity") {
          let productStructureId = data.productStructureId;
          let netArea = value;
          let coverage = data.coverage;
          let sfPerPiece = data.sfPerPiece;
          let unit = data.unit;
          let waste = 0;
          let isMaterial = true;
          let masterProductUnit = editedData.unit;

          let calculatedValue = calculateProductQuantity(
            productStructureId,
            netArea,
            coverage,
            sfPerPiece,
            unit,
            waste,
            isMaterial,
            masterProductUnit
          );
          data.quantity = calculatedValue;
          data.linePrice = calculatedValue * data.unitPrice;
        }
      });
    }
    if (
      tempEditedData &&
      tempEditedData?.kitLabors &&
      tempEditedData?.kitLabors.length > 0
    ) {
      tempEditedData?.kitLabors.map((data) => {
        if (filterValue === "quantity") {
          let productStructureId = editedData.productStructureId;
          let netArea = value;
          let coverage = data.coverage;
          let sfPerPiece = data.sfPerPiece;
          let unit = data.unit;
          let waste = 0;
          let isMaterial = true;
          let masterProductUnit = editedData.unit;

          let calculatedValue = calculateProductQuantity(
            productStructureId,
            netArea,
            coverage,
            sfPerPiece,
            unit,
            waste,
            isMaterial,
            masterProductUnit
          );

          data.quantity = calculatedValue;
          data.linePrice = calculatedValue * data.unitPrice;
        }
      });
    }
  };

  // handle edit change on material listing
  const handleMaterialListingEditChange = (value, data, filterValue) => {
    if (productMaterialAddOn && productMaterialAddOn.length > 0) {
      let tempProductMaterialData = productMaterialAddOn;
      tempProductMaterialData.map((editedData) => {
        if (data.productid === editedData.productid) {
          if (filterValue === "quantity") {
            editedData.quantity = value;
            editedData.linePrice = parseFloat(
              parseFloat(value) * parseFloat(editedData.unitPrice)
            ).toFixed(2);
            handleKitsQuantity(editedData, editedData.quantity, filterValue);
          } else if (filterValue === "unitPrice") {
            editedData.unitPrice = value;
            editedData.linePrice = parseFloat(
              parseFloat(value) * parseFloat(editedData.quantity)
            ).toFixed(2);
          } else if (filterValue === "linePrice") {
            editedData.linePrice = value;
            editedData.unitPrice = parseFloat(
              parseFloat(value) / parseFloat(editedData.quantity)
            ).toFixed(2);
          }
        }
      });
      tempProductMaterialData = Object.values(tempProductMaterialData);
      setProductMaterialAddOn(tempProductMaterialData);
    }
  };

  // function call on modifying material kit listing
  const handleProductMaterialChange = (e, data, filterValue) => {
    handleMaterialListingEditChange(e.target.value, data, filterValue);
  };

  // handle edit change on material listing
  const handleMaterialKitListingEditChange = (
    value,
    data,
    kitData,
    filterValue
  ) => {
    let tempProductMaterialData = productMaterialAddOn;
    if (tempProductMaterialData && tempProductMaterialData.length > 0) {
      tempProductMaterialData.map((materialData) => {
        if (materialData.productid === data.productid) {
          if (filterValue === "quantity") {
            materialData.quantity = value;
            materialData.linePrice = parseFloat(
              parseFloat(value) * parseFloat(materialData.unitPrice)
            ).toFixed(2);
          }
          if (
            materialData.kitMaterials &&
            materialData.kitMaterials.length > 0
          ) {
            materialData.kitMaterials.map((kitMaterialData) => {
              if (
                kitMaterialData.materialId === kitData.materialId &&
                filterValue === "quantity"
              ) {
                kitMaterialData.quantity = value;
                kitMaterialData.linePrice = parseFloat(
                  parseFloat(kitMaterialData.unitPrice) * parseFloat(value)
                ).toFixed(2);
              } else if (
                kitMaterialData.materialId === kitData.materialId &&
                filterValue === "unitPrice"
              ) {
                kitMaterialData.unitPrice = value;
                kitMaterialData.linePrice = parseFloat(
                  parseFloat(value) * parseFloat(kitMaterialData.quantity)
                ).toFixed(2);
              } else if (
                kitMaterialData.materialId === kitData.materialId &&
                filterValue === "linePrice"
              ) {
                kitMaterialData.linePrice = value;
                kitMaterialData.unitPrice = parseFloat(
                  parseFloat(value) / parseFloat(kitMaterialData.quantity)
                ).toFixed(2);
              }
            });
          }
        }
      });
      tempProductMaterialData = Object.values(tempProductMaterialData);
      setProductMaterialAddOn(tempProductMaterialData);
    }
  };

  // function call on modifying material kit listing
  const handleProductKitMaterialChange = (e, data, kitData, filterValue) => {
    handleMaterialKitListingEditChange(
      e.target.value,
      data,
      kitData,
      filterValue
    );
  };

  // handle edit change on material listing
  const handleMaterialKitLaborEditChange = (
    value,
    data,
    kitData,
    filterValue
  ) => {
    let tempProductMaterialData = productMaterialAddOn;
    if (tempProductMaterialData && tempProductMaterialData.length > 0) {
      tempProductMaterialData.map((laborData) => {
        if (laborData.productid === data.productid) {
          if (filterValue === 'quantity') {
            let tempQuant = parseFloat(Math.ceil(parseFloat(value) / parseFloat(laborData.coverage))).toFixed(2)
            laborData.quantity = tempQuant
            laborData.linePrice = parseFloat(parseFloat(tempQuant) * parseFloat(laborData.unitPrice)).toFixed(2)
          }
          if (laborData.kitLabors && laborData.kitLabors.length > 0) {
            laborData.kitLabors.map((kitlaborData) => {
              if (
                kitlaborData.laborItemId === kitData.laborItemId &&
                filterValue === "quantity"
              ) {
                kitlaborData.quantity = value;
                kitlaborData.linePrice = parseFloat(
                  parseFloat(value) * parseFloat(kitlaborData.unitPrice)
                ).toFixed(2);
              } else if (
                kitlaborData.laborItemId === kitData.laborItemId &&
                filterValue === "unitPrice"
              ) {
                kitlaborData.unitPrice = value;
                kitlaborData.linePrice = parseFloat(
                  parseFloat(value) * parseFloat(kitlaborData.quantity)
                ).toFixed(2);
              } else if (
                kitlaborData.laborItemId === kitData.laborItemId &&
                filterValue === "linePrice"
              ) {
                kitlaborData.linePrice = value;
                kitlaborData.unitPrice = parseFloat(
                  parseFloat(value) / parseFloat(kitlaborData.quantity)
                ).toFixed(2);
              }
            });
          }
        }
      });
      tempProductMaterialData = Object.values(tempProductMaterialData);
      setProductMaterialAddOn(tempProductMaterialData);
    }
  };

  // function call on modifying material kit listing
  const handleProductKitLaborChange = (e, data, kitData, filterValue) => {
    handleMaterialKitLaborEditChange(
      e.target.value,
      data,
      kitData,
      filterValue
    );
  };

  // function to handle product labor change
  const handleProductLaborItemEditChange = (value, data, filterValue) => {
    let tempProductLaborData = productLaborAddOn;
    if (tempProductLaborData && tempProductLaborData.length > 0) {
      tempProductLaborData.map((laborData) => {
        if (laborData.laborItemId === data.laborItemId) {
          if (filterValue === "quantity") {
            laborData.quantity = value;
            laborData.linePrice = parseFloat(
              parseFloat(value) * parseFloat(laborData.unitPrice)
            ).toFixed(2);
            handleKitsQuantity(laborData, laborData.quantity, filterValue);
          } else if (filterValue === "unitPrice") {
            laborData.unitPrice = value;
            laborData.linePrice = parseFloat(
              parseFloat(value) * parseFloat(laborData.quantity)
            ).toFixed(2);
          } else if (filterValue === "linePrice") {
            laborData.linePrice = value;
            laborData.quantity = parseFloat(
              parseFloat(value) / parseFloat(laborData.unitPrice)
            ).toFixed(2);
          }
        }
      });
      tempProductLaborData = Object.values(tempProductLaborData);
      setProductLaborAddOn(tempProductLaborData);
    }
  };

  // function call for handle product labor change
  const handleProductLaborItemChange = (e, data, filterValue) => {
    handleProductLaborItemEditChange(e.target.value, data, filterValue)
  }

  let validationShown = false;

  // function to filter data on submit
  const handleCommonAddOn = (AddOnData, tempList, tempPdtList) => {
    if (AddOnData.length > 0) {
      AddOnData.map(data => {
        if (data.quantity && data.unitPrice && data.linePrice) {
          if (data.materialId) {
            tempList.push({
              "MaterialId": data.materialId,
              "Quantity": data.quantity,
              "UnitPrice": data.unitPrice,
              "LinePrice": data.linePrice
            })
          } else if (data.laborItemId) {
            tempList.push({
              LaborItemId: data.laborItemId,
              Quantity: data.quantity,
              UnitPrice: data.unitPrice,
              LinePrice: data.linePrice,
            });
          } else if (data.productId) {
            tempPdtList.push({
              "ProductId": data.productId,
              "Quantity": data.quantity,
              "UnitPrice": data.unitPrice,
              "LinePrice": data.linePrice,
              "IsOrderAddOnProduct": data.orderProductId ? false : true,
              "orderProductId": data.orderProductId
            })
          }
        } else if (!validationShown && data.quantity && (!data.unitPrice || data.unitPrice === undefined || !data.linePrice || data.linePrice === undefined || isNaN(parseFloat(data.quantity)) || isNaN(parseFloat(data.unitPrice)) || isNaN(parseFloat(data.linePrice)))) {
          toast.error("Please fill the complete line of the Addon");
          validationShown = true;
        }
      });
      return tempList;
    } else {
      return [];
    }
  };

  // function to handle common data for payload
  const handleCommonAddOnDetails = (commonAddOn, tempProductAddOn, tempMaterialAddOn, tempLaborAddOn) => {
    if (commonAddOn && commonAddOn.length > 0) {
      commonAddOn.map(data => {
        if (data.quantity && data.unitPrice && data.linePrice && !isNaN(parseFloat(data.quantity)) && !isNaN(parseFloat(data.unitPrice)) && !isNaN(parseFloat(data.linePrice))) {
          tempProductAddOn.push({
            "ProductId": data.productid,
            "OrderProductId": 0,
            "Quantity": data.quantity,
            "UnitPrice": data.unitPrice,
            "LinePrice": data.linePrice
          })
          if (data.kitMaterials && data.kitMaterials.length > 0) {
            data.kitMaterials.map(materialdata => {
              if (materialdata.quantity && materialdata.unitPrice && materialdata.linePrice && !isNaN(parseFloat(materialdata.quantity))&& !isNaN(parseFloat(materialdata.unitPrice)) && !isNaN(parseFloat(materialdata.linePrice))) {
                if (materialdata.materialId) {
                  tempMaterialAddOn.push({
                    "MaterialId": materialdata.materialId,
                    "Quantity": materialdata.quantity,
                    "UnitPrice": materialdata.unitPrice,
                    "LinePrice": materialdata.linePrice
                  })
                } else if (materialdata.productId) {
                  tempProductAddOn.push({
                    "ProductId": materialdata.productId,
                    "Quantity": materialdata.quantity,
                    "UnitPrice": materialdata.unitPrice,
                    "LinePrice": materialdata.linePrice
                  })
                }
              } else {
                toast.error("Please fill the complete line of the Addon")
                  validationShown = true;
              }
            })
          }
          if (data.kitLabors && data.kitLabors.length > 0) {
            data.kitLabors.map(labordata => {
              if (labordata.quantity && labordata.unitPrice && labordata.linePrice && !isNaN(parseFloat(labordata.quantity)) && !isNaN(parseFloat(labordata.unitPrice)) && !isNaN(parseFloat(labordata.linePrice))) {
                tempLaborAddOn.push({
                  "LaborItemId": labordata.laborItemId,
                  "Quantity": labordata.quantity,
                  "UnitPrice": labordata.unitPrice,
                  "LinePrice": labordata.linePrice
                })
              } else if (!validationShown &&(!labordata.quantity || !labordata.unitPrice || !labordata.linePrice || isNaN(parseFloat(labordata.quantity)) || isNaN(parseFloat(labordata.unitPrice)) || isNaN(parseFloat(labordata.linePrice)))) {
                toast.error("Please fill the complete line of the Addon")
                  validationShown = true;
              }
            })
          }
        } else if (!validationShown && data.quantity  && (!data.unitPrice||data.unitPrice === undefined || !data.linePrice || data.linePrice===undefined || isNaN(parseFloat(data.quantity)) || isNaN(parseFloat(data.unitPrice)) || isNaN(parseFloat(data.linePrice)))) {
          toast.error("Please fill the complete line of the Addon")
          validationShown = true;
        }
      })
    }
  }

  //cancel the shortage modal
  const handleShortageCancel = () => {
    setOrderShortageDetail({"calledInBy":"","description":"","enteredBy":""})
    handleAddOnCancel()
  }

  //function to handle add on submit
  const handleAddOnDataSubmit = () => {
    validationShown = false;
    let tempMaterialAddOn = []
    let tempLaborAddOn = []
    let tempProductAddOn = []
    handleCommonAddOn(lsMaterialListData, tempMaterialAddOn, tempProductAddOn)
    handleCommonAddOn(lsLaborListData, tempLaborAddOn)
    handleCommonAddOn(productLaborAddOn, tempLaborAddOn)

    handleCommonAddOnDetails(
      productMaterialAddOn,
      tempProductAddOn,
      tempMaterialAddOn,
      tempLaborAddOn
    );
    if (!validationShown) {
      let payload = {
        "addOnProducts": tempProductAddOn,
        "addOnMaterials": tempMaterialAddOn,
        "addOnLabors": tempLaborAddOn
      }
      // console.log("payload== : ",payload)
      handleAddOnSubmit(payload)
    }
  }

  // function to remove pdouct data of add on
  const handleAddOnProductDelete = (data, removeData) => {
    return data.filter(item => item.productid !== removeData.productid);
  }

    // validate shortage form
    const validateForm = (checkorderShortageDetail, checktempOrderShortageProduct, checktempOrderShortageMaterial,  checktempOrderShortageLabor, checkorderShortageReasons ) => {
      if (!validationShown) {
        if (checkorderShortageReasons && checkorderShortageReasons.length > 0) {
          const oneTrue = checkorderShortageReasons.some(
            (reason) => reason.isSelected
          );
          if (!oneTrue) {
            toast.error("Please select at least one reason");
            validationShown = true;
            return;
          }
        }
        if (checkorderShortageDetail && checkorderShortageDetail.calledInBy === "") {
          toast.error("Please fill Call in By field");
          validationShown = true;
          return;
        }
        if (
          checktempOrderShortageProduct &&
          checktempOrderShortageProduct.length === 0 &&
          checktempOrderShortageMaterial &&
          checktempOrderShortageMaterial.length === 0 &&
          checktempOrderShortageLabor &&
          checktempOrderShortageLabor.length === 0
        ) {
          toast.error("Please fill at least one Material or Labor");
          validationShown = true;
          return;
        }
        validationShown = false;
      }
    };
  
    // handle submission of order shortage detail
    const handleSubmitOrderShortageDetail = () => {
      validationShown = false;
      let tempOrderShortageProduct = [];
      let tempOrderShortageMaterial = [];
      let tempOrderShortageLabor = [];
  
      handleCommonAddOn(orderShortageMaterial, tempOrderShortageMaterial, tempOrderShortageProduct);
      handleCommonAddOn(orderShortageLabor, tempOrderShortageLabor, tempOrderShortageProduct);
      validateForm(orderShortageDetail,tempOrderShortageProduct,tempOrderShortageMaterial,tempOrderShortageLabor,orderShortageReasons)
  
      // Check if validation failed (validationShown is true)
      if (!validationShown) {
        let payload = {
          "jobNo": parseInt(jobNum),
          "orderShortageDetail": orderShortageDetail,
          "orderShortageProduct": tempOrderShortageProduct,
          "orderShortageMaterial": tempOrderShortageMaterial,
          "orderShortageLabor": tempOrderShortageLabor,
          "orderShortageReasons": orderShortageReasons,
        };
        handleorderShortageSubmit(payload);  // Call the submit function only if validation passed
      }
    };
  
  // function to remove data
  const handleRemoveData = (pdtList) => {
    let tempList = productMaterialAddOn;
    tempList.map((data) => {
      if (
        data.productid === pdtList.productid &&
        (data.kitLabors.length > 0 || data.kitMaterials.length > 0)
      ) {
        toast.error(
          `Remove kits data first for ${pdtList.privatename ? pdtList.privatename : pdtList.style
          }`
        );
      } else if (
        data.productid === pdtList.productid &&
        data.kitLabors.length < 1 &&
        data.kitMaterials.length < 1
      ) {
        let filteredList = handleAddOnProductDelete(tempList, pdtList);
        setProductMaterialAddOn(filteredList);
        handleStateChange();
      }
    });
  };

  // function to handle delete functionality for product
  const handleProductDelete = (e, productData) => {
    handleRemoveData(productData);
  };

  // function to remove material add on  data
  function removeMatData(data, removeData) {
    return data.map((product) => {
      product.kitMaterials = product.kitMaterials.filter((material) => {
        return !Object.keys(removeData).every(
          (key) => material[key] === removeData[key]
        );
      });
      return product;
    });
  }

  // function to handle delete functionality for product kit
  const handleProductMatDelete = (e, productData, productKitMaterialData) => {
    let tempList = productMaterialAddOn;
    removeMatData(tempList, productKitMaterialData);
    setProductMaterialAddOn(tempList);
    handleStateChange();
  };

  // function to remove labor add on  data
  function removeLaborData(data, removeData) {
    return data.map((product) => {
      product.kitLabors = product.kitLabors.filter((laborData) => {
        return !Object.keys(removeData).every(
          (key) => laborData[key] === removeData[key]
        );
      });
      return product;
    });
  }

  // Removes an object from an array of objects based on a specific property value
  const removeObjectFromArray = (data, removeData) => {
    return data.filter((item) => item.laborItemId !== removeData.laborItemId);
  };

  // function to handle delete functionality for product kit
  const handleProductLabDelete = (e, productData, productKitLaborData) => {
    let tempList = productMaterialAddOn;
    removeLaborData(tempList, productKitLaborData);
    setProductMaterialAddOn(tempList);
    handleStateChange();
  };

  // function handle material labor add on delete
  const handleProductLabAddOnDelete = (e, laborItemData) => {
    let tempList = productLaborAddOn;
    let filteredList = removeObjectFromArray(tempList, laborItemData);
    setProductLaborAddOn(filteredList);
    handleStateChange();
  };

  // Removes an object from an array of objects based on a specific property value
  const removeObjectFromArr = (data, removeData) => {
    return data.filter(
      (item) =>
        item.orderProductId !== removeData.orderProductId ||
        item.orderMaterialId !== removeData.orderMaterialId
        || item.productId !== removeData.productId
    );
  };

  // function handle material labor add on delete
  const handleMaterialAddOnDelete = (e, laborItemData, is_shortage = false) => {
    // let tempList = materialAddOnData
    let tempList = lsMaterialListData
    let filteredList = removeObjectFromArr(tempList, laborItemData)
    // setMaterialAddOnData(filteredList)
    setLsMaterialListData(filteredList)
    if(is_shortage){
      setOrderShortageMaterial(filteredList)
    }
    handleStateChange()
  }

  // function to remove labor add on data
  const removeAddOnData = (data, removeData) => {
    return data.filter((item) => item.orderLaborId !== removeData.orderLaborId || item.laborItemId !== removeData.laborItemId);
  };

  // function handle material labor add on delete
  const handleLaborAddOnDelete = (e, laborItemData, is_shortage = false) => {
    let tempList = lsLaborListData
    let filteredList = removeAddOnData(tempList, laborItemData)
    // setLaborAddOnData(filteredList)
    setLsLaborListData(filteredList)
    if(is_shortage){
      setOrderShortageLabor(filteredList)
    }
    handleStateChange()
  }

  
  // open modal for property list
  const handlePropertyModalOpen = () => {
    setIsPropertyModalOpen(true)
  }

  // close modal for property list
  const handlePropertyModalClose = () => {
    setIsPropertyModalOpen(false)
  }

  return (
    <Fragment>
      <Modal
        show={handleMakeChangeModalOpen}
        // className={`jobChangeModal ${changeJobHeader === 'Reschedule' ? 'reschedule-content' : ''}`}
        className="jobChangeModal reschedule-content"
        onHide={handleMakeChangeClose}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body backdrop="static">
          <div className="jobChangeModal-content py-4">
            <div className="text-center mb-4">
              <h5 className="m-0">{changeJobHeader}</h5>
            </div>
            {changeJobHeader === "Change Job" && (
              <div className="button-change-group-vertical d-flex gap-4 flex-column">
                <Button variant="primary" onClick={handleAddOn}>Add On</Button>
                <Button variant="primary" onClick={handleQuality}>Quality</Button>
                <Button variant="primary" onClick={handleShortage}>Shortage</Button>
                <Button variant="primary" onClick={handleReschedule}>Reschedule</Button>
                <Button variant="primary" onClick={handleMakeChangeClose}>Cancel</Button>
              </div>
            )}
            {changeJobHeader === "Reschedule" && (
              <>
                <Form.Group className="row mb-3">
                  <div className="col-md-4">
                    <Form.Label for="changeJobNotes" className="form-label">
                      Why is job being Rescheduled ?{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Select
                      ids="RescheduleChoicesId"
                      handleChange={handleRescheduleChange}
                      value={rescheduleData.RescheduleChoicesId}
                      result={rescheduleChoices}
                      validate={true}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row mb-3">
                  <div className="col-md-4">
                    <Form.Label className="form-label">Notes</Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      value={rescheduleData.changeJobNotes}
                      onChange={handleRescheduleChange}
                      name="changeJobNotes"
                      id="changeJobNotes"
                      row={5}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <DatePicker
                      className="mb-3"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date()}
                      isClearable
                      placeholderText="--/--/----"
                    />
                    <div className="d-flex gap-2">
                      <Button
                        variant="primary"
                        onClick={handleMakeChangeModalClose}
                      >
                        Confirm New Date
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleRescheduleCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              </>
            )}
            {changeJobHeader === "Add On" && (
              <>
                <Form.Group className="row mb-3">
                  <div className="col-md-4">
                    <Form.Label for="changeJobNotes" className="form-label">
                      Approved By ? <span className="text-danger">*</span>
                    </Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      type="text"
                      name="approvedBy"
                      id="approvedBy"
                      onChange={handleAddOnFormChange}
                      value={addOnData.approvedBy}
                      placeholder=""
                    />
                  </div>
                </Form.Group>

                <Form.Group className="row mb-3">
                  <div className="col-md-4">
                    <Form.Label className="form-label">
                      Description (Include Areas)
                    </Form.Label>
                  </div>
                  <div className="col-md-8">
                    <Form.Control
                      as="textarea"
                      name="description"
                      id="description"
                      row={3}
                      onChange={handleAddOnFormChange}
                      value={addOnData.description}
                    />
                  </div>
                </Form.Group>

                <div className="my-4">
                  <h5 className="m-0">Common Add Ons</h5>
                </div>
                {productMaterialAddOn &&
                  productMaterialAddOn.length > 0 &&
                  productMaterialAddOn.map(
                    (productMaterialData, productMaterialIndex) => (
                      <div key={productMaterialIndex}>
                        <h6>
                          {productMaterialData?.privatename
                            ? productMaterialData?.privatename
                            : productMaterialData?.style}
                        </h6>
                        <Table bordered responsive className="addons">
                          <thead>
                            <tr>
                              <th width="100">Qty</th>
                              <th width="100">Unit</th>
                              <th colSpan="3"></th>
                              <th width="100">Unit Price</th>
                              <th width="100">Line Price</th>
                              <th width="75" align="center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Form.Control
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby=""
                                  onChange={(e) =>
                                    handleProductMaterialChange(
                                      e,
                                      productMaterialData,
                                      "quantity"
                                    )
                                  }
                                  value={
                                    productMaterialData.quantity
                                      ? productMaterialData.quantity
                                      : ""
                                  }
                                  disabled={
                                    (constantIds.includes(
                                      productMaterialData.productid
                                    ) || constantProducts.includes(
                                      productMaterialData.privatename
                                    ))
                                      ? true
                                      : false
                                  }
                                />
                              </td>
                              <td>{productMaterialData?.unit}</td>
                              <td>{productMaterialData?.privatename}</td>
                              <td>{productMaterialData?.style}</td>
                              <td>{productMaterialData?.color}</td>
                              <td>
                                <Form.Control
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby=""
                                  onChange={(e) =>
                                    handleProductMaterialChange(
                                      e,
                                      productMaterialData,
                                      "unitPrice"
                                    )
                                  }
                                  value={
                                    productMaterialData.unitPrice
                                      ? productMaterialData.unitPrice
                                      : ""
                                  }
                                />
                              </td>
                              <td>
                                <Form.Control
                                  placeholder=""
                                  aria-label=""
                                  aria-describedby=""
                                  onChange={(e) =>
                                    handleProductMaterialChange(
                                      e,
                                      productMaterialData,
                                      "linePrice"
                                    )
                                  }
                                  value={
                                    productMaterialData.linePrice
                                      ? productMaterialData.linePrice
                                      : ""
                                  }
                                />
                              </td>
                              <td align="center">
                                <Icon
                                  className="text-danger changeJob-addOn-delete"
                                  size={24}
                                  icon={ic_delete_outline}
                                  onClick={(e) =>
                                    handleProductDelete(e, productMaterialData)
                                  }
                                />
                              </td>
                            </tr>
                            {productMaterialData?.kitMaterials &&
                              productMaterialData?.kitMaterials.length > 0 &&
                              productMaterialData?.kitMaterials.map(
                                (
                                  productKitMaterialData,
                                  productKitMaterialIndex
                                ) => (
                                  <tr key={productKitMaterialIndex}>
                                    <td>
                                      <Form.Control
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        onChange={(e) =>
                                          handleProductKitMaterialChange(
                                            e,
                                            productMaterialData,
                                            productKitMaterialData,
                                            "quantity"
                                          )
                                        }
                                        value={
                                          productKitMaterialData.quantity
                                            ? productKitMaterialData.quantity
                                            : ""
                                        }
                                        disabled={
                                          (constantIds.includes(
                                            productMaterialData.productid
                                          ) || constantProducts.includes(
                                            productMaterialData.privatename
                                          ))
                                            ? false
                                            : true
                                        }
                                      />
                                    </td>
                                    <td>{productKitMaterialData?.unit}</td>
                                    <td>
                                      {productKitMaterialData?.privatename}
                                    </td>
                                    <td>{productKitMaterialData?.style}</td>
                                    <td>{productKitMaterialData?.color}</td>
                                    <td>
                                      <Form.Control
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        onChange={(e) =>
                                          handleProductKitMaterialChange(
                                            e,
                                            productMaterialData,
                                            productKitMaterialData,
                                            "unitPrice"
                                          )
                                        }
                                        value={
                                          productKitMaterialData.unitPrice
                                            ? productKitMaterialData.unitPrice
                                            : ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        onChange={(e) =>
                                          handleProductKitMaterialChange(
                                            e,
                                            productMaterialData,
                                            productKitMaterialData,
                                            "linePrice"
                                          )
                                        }
                                        value={
                                          productKitMaterialData.linePrice
                                            ? productKitMaterialData.linePrice
                                            : ""
                                        }
                                      />
                                    </td>
                                    <td align="center">
                                      <Icon
                                        className="text-danger changeJob-addOn-delete"
                                        size={24}
                                        icon={ic_delete_outline}
                                        onClick={(e) =>
                                          handleProductMatDelete(
                                            e,
                                            productMaterialData,
                                            productKitMaterialData
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            {productMaterialData?.kitLabors &&
                              productMaterialData?.kitLabors.length > 0 &&
                              productMaterialData?.kitLabors.map(
                                (productKitLaborData, productKitLaborIndex) => (
                                  <tr key={productKitLaborIndex}>
                                    <td>
                                      <Form.Control
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        onChange={(e) =>
                                          handleProductKitLaborChange(
                                            e,
                                            productMaterialData,
                                            productKitLaborData,
                                            "quantity"
                                          )
                                        }
                                        value={
                                          productKitLaborData.quantity
                                            ? productKitLaborData.quantity
                                            : ""
                                        }
                                        disabled={
                                          (constantIds.includes(
                                            productMaterialData.productid
                                          ) || constantProducts.includes(
                                            productMaterialData.privatename
                                          ))
                                            ? false
                                            : true
                                        }
                                      />
                                    </td>
                                    <td>{productKitLaborData?.unit}</td>
                                    <td>{productKitLaborData?.laborName}</td>
                                    <td>{productKitLaborData?.category}</td>
                                    <td></td>
                                    <td>
                                      <Form.Control
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        onChange={(e) =>
                                          handleProductKitLaborChange(
                                            e,
                                            productMaterialData,
                                            productKitLaborData,
                                            "unitPrice"
                                          )
                                        }
                                        value={
                                          productKitLaborData.unitPrice
                                            ? productKitLaborData.unitPrice
                                            : ""
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        onChange={(e) =>
                                          handleProductKitLaborChange(
                                            e,
                                            productMaterialData,
                                            productKitLaborData,
                                            "linePrice"
                                          )
                                        }
                                        value={
                                          productKitLaborData.linePrice
                                            ? productKitLaborData.linePrice
                                            : ""
                                        }
                                      />
                                    </td>
                                    <td align="center">
                                      <Icon
                                        className="text-danger changeJob-addOn-delete"
                                        size={24}
                                        icon={ic_delete_outline}
                                        onClick={(e) =>
                                          handleProductLabDelete(
                                            e,
                                            productMaterialData,
                                            productKitLaborData
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </div>
                    )
                  )}

                {productLaborAddOn &&
                  productLaborAddOn.length > 0 &&
                  productLaborAddOn.map((laborItemData, laborItemIndex) => (
                    <div key={laborItemIndex}>
                      <h6>{laborItemData?.laborName}</h6>
                      <Table bordered responsive className="addons">
                        <thead>
                          <tr>
                            <th width="100">Qty</th>
                            <th width="100">Unit</th>
                            <th colSpan="3"></th>
                            <th width="100">Unit Price</th>
                            <th width="100">Line Price</th>
                            <th width="75" align="center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Form.Control
                                placeholder=""
                                aria-label=""
                                aria-describedby=""
                                onChange={(e) =>
                                  handleProductLaborItemChange(
                                    e,
                                    laborItemData,
                                    "quantity"
                                  )
                                }
                                value={
                                  laborItemData.quantity
                                    ? laborItemData.quantity
                                    : ""
                                }
                              />
                            </td>
                            <td>{laborItemData?.unit}</td>
                            <td>{laborItemData?.laborName}</td>
                            <td>{laborItemData?.category}</td>
                            {/* <td>{laborItemData?.color}</td> */}
                            <td></td>
                            <td>
                              <Form.Control
                                placeholder=""
                                aria-label=""
                                aria-describedby=""
                                onChange={(e) =>
                                  handleProductLaborItemChange(
                                    e,
                                    laborItemData,
                                    "unitPrice"
                                  )
                                }
                                value={
                                  laborItemData.unitPrice
                                    ? laborItemData.unitPrice
                                    : ""
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                placeholder=""
                                aria-label=""
                                aria-describedby=""
                                onChange={(e) =>
                                  handleProductLaborItemChange(
                                    e,
                                    laborItemData,
                                    "linePrice"
                                  )
                                }
                                value={
                                  laborItemData.linePrice
                                    ? laborItemData.linePrice
                                    : ""
                                }
                              />
                            </td>
                            <td align="center">
                              <Icon
                                className="text-danger changeJob-addOn-delete"
                                size={24}
                                icon={ic_delete_outline}
                                onClick={(e) =>
                                  handleProductLabAddOnDelete(e, laborItemData)
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}

                <hr />

                <h5 className="mt-4">Material</h5>
                <Table bordered responsive className="addons fixTableHead">
                  <thead>
                    <tr>
                      <th width="100">Qty</th>
                      <th width="100">Unit</th>
                      <th>Private Name</th>
                      <th>Style</th>
                      <th>Color</th>
                      <th width="100">Unit Price</th>
                      <th width="100">Line Price</th>
                      <th width="75" align="center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lsMaterialListData && lsMaterialListData.length > 0 && lsMaterialListData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            name="quantity"
                            id="quantity"
                            onChange={e => handleAddOnMaterialChange(e, data, 'quantity')}
                            value={data.quantity ? data.quantity : null}
                          />
                        </td>
                        <td>{_.get(data, "unitName", "")}</td>
                        <td>{_.get(data, "privateName", "")}</td>
                        <td>{_.get(data, "product", "")}</td>
                        <td>{_.get(data, "color", "")}</td>
                        <td>
                          <Form.Control
                            name="unitPrice"
                            id="unitPrice"
                            onChange={e => handleAddOnMaterialChange(e, data, 'unitPrice')}
                            value={data.unitPrice ? data.unitPrice : null}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name="linePrice"
                            id="linePrice"
                            onChange={e => handleAddOnMaterialChange(e, data, 'linePrice')}
                            value={data.linePrice ? data.linePrice : null}
                          />
                        </td>
                        <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e => handleMaterialAddOnDelete(e, data)} /></td>
                      </tr>
                    ))}
                    {/* {localStorageData && Object.keys(localStorageData).map((key) => (
                      localStorageData[key].length > 0 && localStorageData[key].map((data, index) => (
                        <tr key={index}>
                          <td width="110">
                            <Form.Control
                              name={`materialQuantityItem${index}`}
                              id={`materialQuantityItem${index}`}
                              onChange={e=>handleAddOnMaterialChange(e, data, 'quantity')}
                              value={data.quantity ? data.quantity : ""}
                            />
                          </td>
                          <td>{data.unit}</td>
                          <td>{data.pvtName}</td>
                          <td>{data.style}</td>
                          <td>{data.color}</td>
                          <td width="110">
                            <Form.Control
                              name={`materialUnitPriceItem${index}`}
                              id={`materialUnitPriceItem${index}`}
                              onChange={e=>handleAddOnMaterialChange(e, data, 'unitPrice')}
                              value={data.unitPrice ? data.unitPrice : ""}
                            />
                          </td>
                          <td width="110">
                            <Form.Control
                              name={`materialLinePriceItem${index}`}
                              id={`materialLinePriceItem${index}`}
                              onChange={e=>handleAddOnMaterialChange(e, data, 'linePrice')}
                              value={data.linePrice ? data.linePrice : ""}
                            />
                          </td>
                          <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e=>handleMaterialAddOnDelete(e, data)} /></td>
                        </tr>
                      ))
                    ))} */}

                    <tr>
                      <td colSpan={'5'} className="text-end"><strong>Total</strong></td>
                      <td>{materialAddOnUnitPrice ? handleFormat(parseFloat(materialAddOnUnitPrice)) : parseFloat(0.00).toFixed(2)}</td>
                      <td>{materialAddOnLinePrice ? handleFormat(parseFloat(materialAddOnLinePrice)) : parseFloat(0.00).toFixed(2)}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>

                <div className="text-center">
                  <Button
                    className="mx-auto"
                    onClick={handleClickNewMaterialItem}
                  >
                    Add New Material Item
                  </Button>
                </div>

                <hr />

                <h5 className="mt-4">Labor</h5>
                <Table bordered responsive className="addons fixTableHead">
                  <thead>
                    <tr>
                      <th width="100">Qty</th>
                      <th width="100">Unit</th>
                      <th>Labor Category</th>
                      <th>Labor Name</th>
                      <th width="100">Unit Price</th>
                      <th width="100">Line Price</th>
                      <th width="75" align="center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* { laborAddOnData && laborAddOnData.length > 0 && laborAddOnData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            name="quantity"
                            id="quantity"
                            onChange={e=>handleAddOnLaborChange(e, data, 'quantity')}
                            value={data.quantity ? data.quantity : null}
                          />
                        </td>
                        <td>{_.get(data, "unitName", "")}</td>
                        <td>{_.get(data, "laborCategory", "")}</td>
                        <td>{_.get(data, "labor", "")}</td>
                        <td>
                          <Form.Control
                            name="unitPrice"
                            id="unitPrice"
                            onChange={e=>handleAddOnLaborChange(e, data, 'unitPrice')}
                            value={data.unitPrice ? data.unitPrice : null}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name="linePrice"
                            id="linePrice"
                            onChange={e=>handleAddOnLaborChange(e, data, 'linePrice')}
                            value={data.linePrice ? data.linePrice : null}
                          />
                        </td>
                        <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e=>handleLaborAddOnDelete(e, data)} /></td>
                      </tr>
                    ))} */}
                    {lsLaborListData && lsLaborListData.length > 0 && lsLaborListData.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            name="quantity"
                            id="quantity"
                            onChange={e => handleAddOnLaborChange(e, data, 'quantity')}
                            value={data.quantity ? data.quantity : null}
                          />
                        </td>
                        <td>{_.get(data, "unitName", "")}</td>
                        <td>{_.get(data, "laborCategory", "")}</td>
                        <td>{_.get(data, "labor", "")}</td>
                        <td>
                          <Form.Control
                            name="unitPrice"
                            id="unitPrice"
                            onChange={e => handleAddOnLaborChange(e, data, 'unitPrice')}
                            value={data.unitPrice ? data.unitPrice : null}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name="linePrice"
                            id="linePrice"
                            onChange={e => handleAddOnLaborChange(e, data, 'linePrice')}
                            value={data.linePrice ? data.linePrice : null}
                          />
                        </td>
                        <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e => handleLaborAddOnDelete(e, data)} /></td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={"4"} className="text-end">
                        <strong>Total</strong>
                      </td>
                      <td>
                        {laborAddOnUnitPrice
                          ? handleFormat(parseFloat(laborAddOnUnitPrice))
                          : parseFloat(0.0).toFixed(2)}
                      </td>
                      <td>
                        {laborAddOnLinePrice
                          ? handleFormat(parseFloat(laborAddOnLinePrice))
                          : parseFloat(0.0).toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>

                <div className="text-center">
                  <Button
                    className="mx-auto"
                    onClick={handleClickNewLabourItem}
                  >
                    Add New Labor Item
                  </Button>
                </div>

                <hr />

                <div className="text-center mt-3">
                  <Button
                    className="mx-auto me-2"
                    onClick={handleAddOnDataSubmit}
                  >
                    Submit
                  </Button>
                  <Button className="mx-auto" onClick={handleAddOnCancelClick}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
            {changeJobHeader === "Shortage" && (
              <Container>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label className="form-label">
                        Reason for Shortage
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Row>
                      {ShortageReasonsComponent(orderShortageReasons ? orderShortageReasons : [])}
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label className="form-label">
                        Called In By
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Control type="text" name="calledInBy" onChange={(e) => handleOrderShortageDetailChange(e)} value={orderShortageDetail.calledInBy} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label className="form-label">
                        Description (Include Areas)
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Control as="textarea" name="description" rows={3} onChange={(e) => handleOrderShortageDetailChange(e)} value={orderShortageDetail.description} />
                  </Col>
                </Row>

                <h5 className="mt-4">Material</h5>
                <Table bordered responsive className="addons fixTableHead">
                  <thead>
                    <tr>
                      <th width="100">Qty</th>
                      <th width="100">Unit</th>
                      <th>Private Name</th>
                      <th>Style</th>
                      <th>Color</th>
                      <th width="100">Unit Price</th>
                      <th width="100">Line Price</th>
                      <th width="75" align="center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lsMaterialListData && lsMaterialListData.length > 0 && lsMaterialListData.map((data, index) => (
                      <tr id={`${(data.orderProductId !== (0 || "")) ? data.orderProductId : data.orderMaterialId}`} key={`${(data.orderProductId !== (0 || "")) ? data.orderProductId : data.orderMaterialId}`}>
                        <td>
                          <Form.Control
                            name="quantity"
                            id="quantity"
                            onChange={e => handleAddOnMaterialChange(e, data, 'quantity', true)}
                            value={data.quantity ? data.quantity : ''}
                          />
                        </td>
                        <td>{_.get(data, "unitName", "")}</td>
                        <td>{_.get(data, "privateName", "")}</td>
                        <td>{_.get(data, "product", "")}</td>
                        <td>{_.get(data, "color", "")}</td>
                        <td>
                          <Form.Control
                            name="unitPrice"
                            id="unitPrice"
                            onChange={e => handleAddOnMaterialChange(e, data, 'unitPrice', true)}
                            value={data.unitPrice ? data.unitPrice : ''}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name="linePrice"
                            id="linePrice"
                            onChange={e => handleAddOnMaterialChange(e, data, 'linePrice', true)}
                            value={data.linePrice ? data.linePrice : ''}
                          />
                        </td>
                        <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e => handleMaterialAddOnDelete(e, data, true)} /></td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={'5'} className="text-end"><strong>Total</strong></td>
                      <td>{orderShortageMaterialUnitPrice ? handleFormat(parseFloat(orderShortageMaterialUnitPrice)) : parseFloat(0.00).toFixed(2)}</td>
                      <td>{orderShortageMaterialLinePrice ? handleFormat(parseFloat(orderShortageMaterialLinePrice)) : parseFloat(0.00).toFixed(2)}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>

                <hr />

                <h5 className="mt-4">Labor</h5>
                <Table bordered responsive className="addons fixTableHead">
                  <thead>
                    <tr>
                      <th width="100">Qty</th>
                      <th width="100">Unit</th>
                      <th>Labor Category</th>
                      <th>Labor Name</th>
                      <th width="100">Unit Price</th>
                      <th width="100">Line Price</th>
                      <th width="75" align="center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* { laborAddOnData && laborAddOnData.length > 0 && laborAddOnData.map((data, index) => (
                      <tr id={`${data.orderLaborId}`} key={data.orderLaborId}>
                        <td>
                          <Form.Control
                            name="quantity"
                            id="quantity"
                            onChange={e=>handleAddOnLaborChange(e, data, 'quantity',true)}
                            value={data.quantity ? data.quantity : ''}
                          />
                        </td>
                        <td>{_.get(data, "unitName", "")}</td>
                        <td>{_.get(data, "laborCategory", "")}</td>
                        <td>{_.get(data, "labor", "")}</td>
                        <td>
                          <Form.Control
                            name="unitPrice"
                            id="unitPrice"
                            onChange={e=>handleAddOnLaborChange(e, data, 'unitPrice',true)}
                            value={data.unitPrice ? data.unitPrice : ''}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name="linePrice"
                            id="linePrice"
                            onChange={e=>handleAddOnLaborChange(e, data, 'linePrice',true)}
                            value={data.linePrice ? data.linePrice : ''}
                          />
                        </td>
                        <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e=>handleLaborAddOnDelete(e, data, true)} /></td>
                      </tr>
                    ))} */}
                    {lsLaborListData && lsLaborListData.length > 0 && lsLaborListData.map((data, index) => (
                      <tr id={`${data.orderLaborId}`} key={data.orderLaborId}>
                        <td>
                          <Form.Control
                            name="quantity"
                            id="quantity"
                            onChange={e => handleAddOnLaborChange(e, data, 'quantity', true)}
                            value={data.quantity ? data.quantity : ''}
                          />
                        </td>
                        <td>{_.get(data, "unitName", "")}</td>
                        <td>{_.get(data, "laborCategory", "")}</td>
                        <td>{_.get(data, "labor", "")}</td>
                        <td>
                          <Form.Control
                            name="unitPrice"
                            id="unitPrice"
                            onChange={e => handleAddOnLaborChange(e, data, 'unitPrice', true)}
                            value={data.unitPrice ? data.unitPrice : ''}
                          />
                        </td>
                        <td>
                          <Form.Control
                            name="linePrice"
                            id="linePrice"
                            onChange={e => handleAddOnLaborChange(e, data, 'linePrice', true)}
                            value={data.linePrice ? data.linePrice : ''}
                          />
                        </td>
                        <td align="center"><Icon className="text-danger changeJob-addOn-delete" size={24} icon={ic_delete_outline} onClick={e => handleLaborAddOnDelete(e, data, true)} /></td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={'4'} className="text-end"><strong>Total</strong></td>
                      <td>{orderShortageLaborUnitPrice ? handleFormat(parseFloat(orderShortageLaborUnitPrice)) : parseFloat(0.00).toFixed(2)}</td>
                      <td>{orderShortageLaborLinePrice ? handleFormat(parseFloat(orderShortageLaborLinePrice)) : parseFloat(0.00).toFixed(2)}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="text-center mt-3">
                  <Button className="mx-auto me-2" onClick={handleSubmitOrderShortageDetail}>Submit</Button>
                  <Button className="mx-auto" onClick={handleShortageCancel}>Cancel</Button>
                </div>
              </Container>
            )}
            { changeJobHeader === "Quality" && (<><Quality qualityReasons={qualityReasons} auth={auth} jobNum={jobNum} saveOrderQualityDetailRequest={saveOrderQualityDetailRequest} saveOrderQualityDetailData={saveOrderQualityDetailData} handleMakeChangeClose={handleMakeChangeClose} handleOrdering={handleOrdering} /></>)}
            {/* need to add ordering modal box*/}
            {changeJobHeader === "Ordering" && 
              <PropertyList
                handlePropertyModalOpen={handlePropertyModalOpen}
                handlePropertyModalClose={handlePropertyModalClose}
                orderingUrl={orderingUrl}
              />
            }
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ChangeJob;
