import React, { useState, useEffect, useMemo } from "react";
import '../../../assets/css/order.css';
import { Icon } from "react-icons-kit";
import { Link, useNavigate } from "react-router-dom";
import { ic_arrow_back } from "react-icons-kit/md";
import MainWrapper from "../../MainWrapper";
import Title from "../../elements/Title";
import CustomButton from "../../elements/CustomButton";
import CreateProductCatalog from "./CreateProductCatalog";
import { connect } from "react-redux";
import {
	PRODUCT_TYPE_REQUEST,
	UNIT_REQUEST,
	PRODUCT_CATALOG_CREATE,
	PRODUCT_CATALOG_STATE_CHANGE,
	PRODUCT_CATALOG_REQUEST,
	PRODUCT_CATALOG_EDIT,
	PRODUCT_CATALOG_DELETE,
	PRODUCT_CATALOG_UPDATE,
	GET_SUB_PRODUCT_REQUEST
} from "../../../_utils/constants/ProductCatalog";
import { VENDOR_REQUEST } from "../../../_utils/constants/ValueList";
import {
	STORE_INFO_REQUEST,
} from "../../../_utils/constants/admin/StoreInfo";
import { toast } from 'react-toastify';
import ProductCatalogList from "./ProductCatalogList";
import _ from "lodash";
import {
  WALLBASE_ID_DB,
  INSTALLATION_ACCESSORIES_ID_DB,
  TRANSITION_ID_DB
} from "../../../_utils/constants/ProductCatalog";

const ProductCatalog = ({
	auth: { auth, isAuthenticated },
	ProductType,
	unit,
	valueList: { valueList },
	StoreInfo: { storeInfo },
	ProductCatalog: { products, isCreated, isEdited, isDeleted, totalCount, isLoading, errors, product, isEditedData },
	ProductType: { getSubProductData },
	fetchProductType,
	fetchUnit,
	fetchStoreInfo,
	fetchVendor,
	createProduct,
	changeReducerState,
	fetchProducts,
	updateProduct,
	deleteProduct,
	updatePartial,
	fetchSubProductRequest
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [sort, setSort] = useState("asc");
	const [page, setPage] = useState(1);
	const [paginate, setPaginate] = useState(20);
	const [isEdit, setIsEdit] = useState(false);
	const [ids, setIds] = useState("");
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleteProductId, setDeleteProductId] = useState('');
	const [selectedId, setSelectedId] = useState([]);
	const [stockSelectedId, setStockSelectedId] = useState([]);
	const [numberValidation, setNumberValidation] = useState("");
	const [numberValidationCons, setNumberValidationCons] = useState("");
	const [piecesNumberValidation, setPiecesNumberValidation] = useState("");
	const [coverageNum, setCoverageNum] = useState("");
	const [colorCodeList, setColorCodeList] = useState();
	const [updateState, setUpdateState] = useState({
		"futureCost1": 0,
		"futureFreight1": 0,
		"IncreaseDate1": "",
		"futureCost2": 0,
		"futureFreight2": 0,
		"IncreaseDate2": "",
		"IsStock": false,
		"IsRecurringStock": false,
	})
	const [filterState, setFilterState] = useState({
		"sortOn": "",
		"ProductId": 0,
		"ProductTypeId": 0,
		"ProductStructureId": 0,
		"StoreId": 0,
		"PrivateName": "",
		"Manufacturer": "",
		"Style": "",
		"Color": "",
		"CurrentCost": "",
		"CurrentFreight": "",
		"futureCost1": "",
		"futureFreight1": "",
		"futureCost2": "",
		"futureFreight2": "",
		"IncreaseDate1": "",
		"IncreaseDate2": "",
		"IsStock": false,
		"IsRecurringStock": false,
		"subProductTypeId": 0,
	})
	const [state, setState] = useState({
		"ProductTypeId": "",
		"ProductId": "",
		"KitId": "",
		"StoreId": "",
		"Files": "",
		"VendorId": "",
		"PrivateName": "",
		"Manufacturer": "",
		"Style": "",
		"Color": "",
		"UnitId": "",
		"Length": "",
		"Width": "",
		"FaceWidth": "",
		"ManufacturerSKU": "",
		"CurrentCost": 0,
		"CurrentFreight": "",
		"Guage": 0,
		"Thickness": 0,
		"BoxQTY": "",
		"Fiber": "",
		"PhotoPath": "",
		"coverage": "",
		"coveragePerUnit": "",
		"coverageUnit": "",
		"wearLayer": "",
		"backingType": "",
		"piecesPerBox": "",
		"sfPerPiece": "",
		"subProductTypeId": "",
		"customColor": "#FF0000",
		"customColorCode": "#FF0000",
		"isAddOn": false
	})
	const [validated, setValidated] = useState(false);
	const [copyFetch, setCopyFetch] = useState(false)
	const [product_id, setProduct_id] = useState("")
	const resetState = () => {
		setState({
			"ProductTypeId": "",
			"ProductId": "",
			"KitId": "",
			"StoreId": "",
			"Files": "",
			"VendorId": "",
			"PrivateName": "",
			"Manufacturer": "",
			"Style": "",
			"Color": "",
			"UnitId": "",
			"Length": "",
			"Width": "",
			"FaceWidth": "",
			"ManufacturerSKU": "",
			"CurrentCost": "",
			"CurrentFreight": "",
			"Guage": "",
			"Thickness": "",
			"BoxQTY": "",
			"Fiber": "",
			"PhotoPath": "",
			"coverage": "",
			"coveragePerUnit": "",
			"coverageUnit": "",
			"wearLayer": "",
			"backingType": "",
			"piecesPerBox": "",
			"sfPerPiece": "",
			"subProductTypeId": "",
			"customColor": "#FF0000",
			"customColorCode": "#FF0000",
			"isAddOn": false
		})
	}

	const navigate = useNavigate();

  // handle user auth
  useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

	const handleModal = () => {
		resetState()
		setIsOpen(!isOpen)
		setValidated(false)
		setIds("")
	}
	const hideModel = () => {
		resetState()
		setIsOpen(false)
		setValidated(false)
		setIsEdit(false)
	}

	useEffect(() => {
		if (isAuthenticated) {
			fetchProductType(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	useEffect(() => {
		if (isAuthenticated) {
			fetchUnit(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	useEffect(() => {
		if (isAuthenticated) {
			fetchStoreInfo(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	useEffect(() => {
		if (isAuthenticated) {
			fetchVendor(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	useEffect(() => {
		if (isAuthenticated) {
			fetchProducts(auth[0].token, sort, page, paginate, filterState)
		}
	}, [auth, isAuthenticated, sort, page, paginate, filterState, copyFetch])
	//handle products listing color for wall base
	useEffect(() => {
		if(products && products.length > 0) {
			let hexColorCodeList = []
			products.map(data => {
				if(data.ProductStructureId === WALLBASE_ID_DB && data.CustomColor) {
					hexColorCodeList.push(data.CustomColor)
				}
			})
			setColorCodeList(hexColorCodeList)
		}
  }, [products])
	useEffect(() => {
		if (typeof errors === 'string' && errors.length > 0) {
			toast.error(errors)
		}
	}, [errors])
	const productStructure = useMemo(() => {
		if (ProductType)
			return ProductType.valueList.map(v => ({ label: v.productStructure, value: v.productStructureId }))
	}, [ProductType])
	const unitList = useMemo(() => {
		if (unit.valueList)
			return unit.valueList.map(v => ({ label: v.unitName, value: v.unitId }))
	}, [unit])
	const storeList = useMemo(() => {
		if (storeInfo)
			return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
	}, [storeInfo])
	const vendorList = useMemo(() => {
		if (valueList)
			return valueList.map(v => ({ label: v.companyName, value: v.vendorId }))
	}, [valueList])
	const productStruct = useMemo(() => {
		if (ProductType) {
			var defaultV = ProductType.valueList.filter((p) => p.isDefault === true);
			return defaultV.map(v => ({ label: v.productStructure, value: v.productStructureId }))
		}
	}, [ProductType])
	const otherProductStructure = useMemo(() => {
		if (ProductType) {
			var other = ProductType.valueList.filter((p) => p.isDefault !== true);
			return other.map(v => ({ label: v.productStructure, value: v.productStructureId }))
		}
	}, [ProductType])
	// handle sub product structure response data
	const productSubStructure = useMemo(() => {
		if(getSubProductData) {
			return getSubProductData.map(v => ({ label: v.productStructure, value: v.productStructureId }))
		}
	}, [getSubProductData])
	const handleChange = (e) => {
		const { id, value, checked } = e.target;
		if(parseInt(value) === WALLBASE_ID_DB || parseInt(value) === INSTALLATION_ACCESSORIES_ID_DB || parseInt(value) === TRANSITION_ID_DB) {
			fetchSubProductRequest({
				"productStructureId": parseInt(value),
				"token": auth[0].token
			});
		}
		const re = /^\d*\.?\d*$/;
		const regInt = /^\d+$/;
		if (id !== 'piecesPerBox') {
			if (state.piecesPerBox || regInt.test(state.piecesPerBox)) {
				setPiecesNumberValidation("");
			}
		}
		if (id === 'Width') {
			if (e.target.value === '' || re.test(e.target.value)) {
				setState(
					{
						...state,
						[id]: value
					}
				)
				setNumberValidation("");
			} else {
				//	toast.error("Width must be a digit from 0-9");
				setNumberValidation("Width must be a digit from 0-9")
			}
		} else if (id === 'CurrentFreight') {
			if (e.target.value === '' || re.test(e.target.value)) {
				setState(
					{
						...state,
						[id]: value
					}
				)
				setNumberValidationCons("");
			} else {
				//	toast.error("Width must be a digit from 0-9");
				setNumberValidationCons("Current Freight must be a digit from 0-9")
			}
		} else if (id === 'coverage') {
			if (e.target.value === '' || re.test(e.target.value)) {
				setState(
					{
						...state,
						[id]: value
					}
				)
				setCoverageNum("");
			} else {
				//	toast.error("Width must be a digit from 0-9");
				setCoverageNum("Coverage must be a digit from 0-9")
			}
		} else if (id === 'BoxQTY') {
			setState(
				{
					...state,
					[id]: value ? parseFloat(value) : 0,
					sfPerPiece : (state.piecesPerBox ? (value / (state.piecesPerBox)).toFixed(4) : null)
				}
			)
		} else if (id === 'piecesPerBox') {
			if (e.target.value === '' || regInt.test(e.target.value)) {
				setState(
					{
						...state,
						[id]: value ? parseInt(value) : 0,
						sfPerPiece : (state.BoxQTY ? ((state.BoxQTY) / parseInt(value)).toFixed(4) : null)
					}
				)
				setPiecesNumberValidation("");
			} else {
				setPiecesNumberValidation("Pieces must be a digit from 0-9")
			}
		} else {
			if (id === 'isAddOn') {
				setState(
					{
						...state,
						[id]: checked
					}
				)
			} else {
				setState(
					{
						...state,
						[id]: value
					}
				)
			}
			setNumberValidation("");
			setCoverageNum("");
			setNumberValidationCons("");
		}
	}
	const handleChangeCurrency = (value, name) => {
		setState(
			{
				...state,
				[name]: value
			}
		)
		const regInt = /^\d+$/;
		if (state.piecesPerBox || regInt.test(state.piecesPerBox)) {
			setPiecesNumberValidation("");
		}
	}
	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		if(parseInt(value) === WALLBASE_ID_DB || parseInt(value) === INSTALLATION_ACCESSORIES_ID_DB || parseInt(value) === TRANSITION_ID_DB) {
			fetchSubProductRequest({
				"productStructureId": parseInt(value),
				"token": auth[0].token
			});
		}
		// if(state?.ProductStructureId !== parseInt(value)) { // commented on 25 nov to resovle filter issue
		if(id === 'ProductStructureId' && filterState?.ProductStructureId !== parseInt(value)){
			setFilterState(
				{
					...filterState,
					subProductTypeId: 0,
					[id]: value
				}
			)	
		} else {
			setFilterState(
				{
					...filterState,
					[id]: value
				}
			)
		}
	}
	const handleCurrencyChange = (value, name) => {
		setFilterState({
			...filterState,
			[name]: value ? value : ""
		})
	}
	const handleUpdateChange = (e, prod) => {
		const { id, value } = e.target;
		setProduct_id(prod.ProductId);
		var dataUpdate =
		{
			"ProductId": prod.ProductId,
			"futureCost1": prod.futureCost1 ? parseFloat(prod.futureCost1).toFixed(2) : prod.futureCost1,
			"futureFreight1": prod.futureFreight1 ? parseFloat(prod.futureFreight1).toFixed(2) : prod.futureFreight1,
			"IncreaseDate1": prod.IncreaseDate1,
			"futureCost2": prod.futureCost2 ? parseFloat(prod.futureCost2).toFixed(2) : prod.futureCost2,
			"futureFreight2": prod.futureFreight2 ? parseFloat(prod.futureFreight2).toFixed(2) : prod.futureFreight2,
			"IncreaseDate2": prod.IncreaseDate2,
			"IsStock": prod.IsStock,
			"IsRecurringStock": prod.IsRecurringStock,
		}
		dataUpdate[id] = value;
		setUpdateState(dataUpdate)
		updatePartial(auth[0].token, dataUpdate);
	}
	const handleCurrencyUpdateChange = (value, name, prod) => {
		setProduct_id(prod.ProductId);
		var dataUpdate =
		{
			"ProductId": prod.ProductId,
			"futureCost1": prod.futureCost1 ? parseFloat(prod.futureCost1).toFixed(2) : prod.futureCost1,
			"futureFreight1": prod.futureFreight1 ? parseFloat(prod.futureFreight1).toFixed(2) : prod.futureFreight1,
			"IncreaseDate1": prod.IncreaseDate1,
			"futureCost2": prod.futureCost2 ? parseFloat(prod.futureCost2).toFixed(2) : prod.futureCost2,
			"futureFreight2": prod.futureFreight2 ? parseFloat(prod.futureFreight2).toFixed(2) : prod.futureFreight2,
			"IncreaseDate2": prod.IncreaseDate2,
			"IsStock": prod.IsStock,
			"IsRecurringStock": prod.IsRecurringStock,
		}
		dataUpdate[name] = value;
		setUpdateState(dataUpdate)
		updatePartial(auth[0].token, dataUpdate);
	}
	const handleFileChange = (e) => {
		const { id, files } = e.target;
		setState({ ...state, [id]: files[0] });
	}
	// handle custom color change
	const handleChangeColor = (e) => {
		const { id, value } = e.target;
		if(colorCodeList.includes(value)) {
			toast.error("Color already exists for wallbase");
		} else {
			if(id === "customColor") {
				setState({
					...state,
					customColorCode: value,
					 [id]: value
				})
			} else {
				setState({
					...state,
					customColor: value,
					[id]: value
				})
			}
		}
	}

	const handleDeleteFile = (isDelete) => {
		setState({ ...state, Files: null})
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			const data = new FormData;
			data.append("ProductStructureId", parseInt(state.ProductTypeId));
			data.append("SubProductStructureId", state.subProductTypeId ? parseInt(state.subProductTypeId) : 0);
			data.append("StoreId", parseInt(state.StoreId));
			data.append("Files", state.Files);
			data.append("VendorId", 1);
			data.append("PrivateName", state.PrivateName ? state.PrivateName : "");
			data.append("Manufacturer", state.Manufacturer);
			data.append("Style", state.Style);
			data.append("Color", state.Color);
			data.append("UnitId", parseInt(state.UnitId));
			data.append("Length", state.Length ? parseFloat(state.Length).toFixed(2) : 0);
			data.append("Width", state.Width ? parseFloat(state.Width) : 0);
			data.append("FaceWidth", state.FaceWidth ? parseFloat(state.FaceWidth).toFixed(2) : 0.00);
			data.append("ManufacturerSKU", state.ManufacturerSKU);
			data.append("CurrentCost", parseFloat(state.CurrentCost).toFixed(2) ?? 0);
			data.append("CurrentFreight", parseFloat(state.CurrentFreight).toFixed(2) ?? 0);
			data.append("Guage", state.Guage ? parseFloat(state.Guage).toFixed(2) : 0);
			data.append("Thickness", state.Thickness ? parseFloat(state.Thickness).toFixed(2) : 0);
			data.append("BoxQTY", state.BoxQTY ? state.BoxQTY : "");
			data.append("Fiber", state.Fiber ? state.Fiber : "");
			data.append("Coverage", !_.isUndefined(state.coverage) && state.coverage ? state.coverage : 0);
			data.append("CoveragePerUnit", state.coveragePerUnit ? state.coveragePerUnit : "");
			data.append("CoverageUnit", state.coverageUnit ? state.coverageUnit : "");
			data.append("WearLayer", state.wearLayer ? parseFloat(state.wearLayer).toFixed(2) : "");
			data.append("BackingType", state.backingType ? state.backingType : "");
			data.append("PiecesPerBox", state.piecesPerBox ? state.piecesPerBox : "");
			data.append("SFPerPiece", state.sfPerPiece ? state.sfPerPiece : "");
			data.append("IsAddon", state.isAddOn ? state.isAddOn : false);
			data.append("KitId", state.KitId ? parseInt(state.KitId) : 0);
			let tempColor = null
			if (parseInt(state.ProductTypeId) === WALLBASE_ID_DB || parseInt(state.ProductTypeId) === TRANSITION_ID_DB) {
				tempColor = state.customColorCode && state.customColorCode.length > 0 ? state.customColorCode : "#FF0000"
			} else {
				tempColor = "N/A"
			}
			data.append("CustomColor", tempColor);
			if (isEdit) {
				data.append("ProductId", ids ?? 0);
				updateProduct(auth[0].token, data);
			} else {
				data.append("CreatedBy", auth[0].userId);
				createProduct(auth[0].token, data);
			}
		}
		setValidated(true);
	};
	useEffect(() => {
		if (isAuthenticated) {
			if (isCreated === true) {
				toast.success("Product created successfully in Nancy");
				if (product && product.length > 0) {
					toast.success(product[0]['d365BCStatus']);
				}
				fetchProducts(auth[0].token, sort, page, paginate, filterState)
				setValidated(false);
				hideModel();
				setIsEdit(false);
				changeReducerState();
			}
			if (isEdited) {
				fetchProducts(auth[0].token, sort, page, paginate, filterState)
				toast.success("Product updated successfully in Nancy");
				if (isEditedData && isEditedData.length > 0) {
					toast.success(isEditedData[0]['d365BCStatus']);
				}
				setValidated(false);
				hideModel();
				setIsEdit(false);
				setIds("");
				changeReducerState();
			}
			if (isDeleted === true) {
				toast.success("Product deleted successfully.");
				fetchProducts(auth[0].token, sort, page, paginate, filterState)
				changeReducerState();
			}
		}
	}, [isCreated, isEdited, isDeleted, auth, isAuthenticated]);
	const handleEdit = (e, data) => {
		e.preventDefault();
		setIds(data.ProductId);
		if(data?.ProductStructureId === WALLBASE_ID_DB || data?.ProductStructureId === INSTALLATION_ACCESSORIES_ID_DB || data?.ProductStructureId === TRANSITION_ID_DB) {
			fetchSubProductRequest({
				"productStructureId": data?.ProductStructureId,
				"token": auth[0].token
			});
		}

		setState({
			"ProductTypeId": data.ProductStructureId,
			"ProductId": data.ProductId,
			"KitId": data.KitId,
			"StoreId": data.StoreId,
			"Files": data.Files,
			"VendorId": data.VendorId,
			"PrivateName": data.PrivateName,
			"Manufacturer": data.Manufacturer,
			"Style": data.Style,
			"Color": data.Color,
			"UnitId": data.UnitId,
			"Length": data.Length,
			"Width": parseFloat(data.Width).toFixed(2),
			"FaceWidth": parseFloat(data.FaceWidth).toFixed(2),
			"ManufacturerSKU": data.ManufacturerSKU,
			"CurrentCost": parseFloat(data.CurrentCost).toFixed(2),
			"CurrentFreight": parseFloat(data.CurrentFreight).toFixed(2),
			"Guage": parseFloat(data.Guage).toFixed(2),
			"Thickness": parseFloat(data.Thickness).toFixed(2),
			"BoxQTY": data.BoxQTY,
			"Fiber": data.Fiber,
			"PhotoPath": data.PhotoPath,
			"coverage": data.coverage,
			"coveragePerUnit": parseInt(data.coveragePerUnit),
			"coverageUnit": parseInt(data.coverageUnit),
			"wearLayer": (data.wearLayer ? parseFloat(data.wearLayer).toFixed(2) : ""),
			"piecesPerBox": (data.piecesPerBox ? parseInt(data.piecesPerBox) : ""),
			"sfPerPiece": (data.sfPerPiece ? parseFloat(data.sfPerPiece).toFixed(4) : ""),
			"subProductTypeId": data.SubProductStructureId,
			"customColor": data.CustomColor,
			"customColorCode": data.CustomColor,
			"isAddOn": data.isAddon
		})
		//setState(data);
		setIsEdit(true);
		setIsOpen(true);
	}
	const showConfirmDeleteModal = (id) => {
		setDeleteProductId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteProductId('');
		setConfirmDeleteModal(false);
	};
	// function called for delete sales stage delete api
	const handleConfirmDeleteSubmit = () => {
		deleteProduct({
			"ProductId": deleteProductId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}
	const handleMultiSelect = e => {
		if (e.target.checked) {
			// setSelectedId(
			// 	products.reduce((ids, is) => {
			// 		return [...ids, is.ProductId];
			// 	}, [])
			// );
			setFilterState({
				...filterState,
				"IsStock": true
			})
		} else {
			setFilterState({
				...filterState,
				"IsStock": false
			});
		}
	};
	const handleSingleSelect = (e, prod) => {
		if (!e.target.checked) {
			setSelectedId(selectedId => selectedId.filter(d => d !== prod.ProductId));
			var dataUpdate =
			{
				"ProductId": prod.ProductId,
				"futureCost1": prod.futureCost1,
				"futureFreight1": prod.futureFreight1,
				"IncreaseDate1": prod.IncreaseDate1,
				"futureCost2": prod.futureCost2,
				"futureFreight2": prod.futureFreight2,
				"IncreaseDate2": prod.IncreaseDate2,
				"IsStock": false,
				"IsRecurringStock": prod.IsRecurringStock,
			}
			updatePartial(auth[0].token, dataUpdate);
		} else {
			var dataUpdate =
			{
				"ProductId": prod.ProductId,
				"futureCost1": prod.futureCost1,
				"futureFreight1": prod.futureFreight1,
				"IncreaseDate1": prod.IncreaseDate1,
				"futureCost2": prod.futureCost2,
				"futureFreight2": prod.futureFreight2,
				"IncreaseDate2": prod.IncreaseDate2,
				"IsStock": true,
				"IsRecurringStock": prod.IsRecurringStock,
			}
			updatePartial(auth[0].token, dataUpdate);
			setSelectedId(selectedId => [...selectedId, prod.ProductId]);
		}
	};
	const handleStockMultiSelect = e => {
		if (e.target.checked) {
			setFilterState({
				...filterState,
				"IsRecurringStock": true
			})
		} else {
			setFilterState({
				...filterState,
				"IsRecurringStock": false
			})
		}
	};
	const handleStockSingleSelect = (e, prod) => {
		if (!e.target.checked) {
			var dataUpdate =
			{
				"ProductId": prod.ProductId,
				"futureCost1": prod.futureCost1,
				"futureFreight1": prod.futureFreight1,
				"IncreaseDate1": prod.IncreaseDate1,
				"futureCost2": prod.futureCost2,
				"futureFreight2": prod.futureFreight2,
				"IncreaseDate2": prod.IncreaseDate2,
				"IsStock": prod.IsStock,
				"IsRecurringStock": false,
			}
			updatePartial(auth[0].token, dataUpdate);
			setStockSelectedId(stockSelectedId => stockSelectedId.filter(d => d !== prod.ProductId));
		} else {
			var dataUpdate =
			{
				"ProductId": prod.ProductId,
				"futureCost1": prod.futureCost1,
				"futureFreight1": prod.futureFreight1,
				"IncreaseDate1": prod.IncreaseDate1,
				"futureCost2": prod.futureCost2,
				"futureFreight2": prod.futureFreight2,
				"IncreaseDate2": prod.IncreaseDate2,
				"IsStock": prod.IsStock,
				"IsRecurringStock": true,
			}
			updatePartial(auth[0].token, dataUpdate);
			setStockSelectedId(stockSelectedId => [...stockSelectedId, prod.ProductId]);
		}
	};
	return (
		<MainWrapper>

			<div className="sub-header mt-5 mb-3">
				<Title>Product Catalog</Title>
				<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
				<CustomButton handleClick={handleModal} variant="primary">Add New Product</CustomButton>
			</div>
			<ProductCatalogList
				products={products}
				totalCount={totalCount}
				setSort={setSort}
				setPaginate={setPaginate}
				setPage={setPage}
				page={page}
				paginate={paginate}
				handleEdit={handleEdit}
				showConfirmDeleteModal={showConfirmDeleteModal}
				hideConfirmDeleteModal={hideConfirmDeleteModal}
				handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
				confirmDeleteModal={confirmDeleteModal}
				handleSingleSelect={handleSingleSelect}
				handleMultiSelect={handleMultiSelect}
				selectedId={selectedId}
				storeList={storeList}
				productStructure={productStructure}
				handleStockMultiSelect={handleStockMultiSelect}
				handleStockSingleSelect={handleStockSingleSelect}
				stockSelectedId={stockSelectedId}
				handleFilterChange={handleFilterChange}
				filterState={filterState}
				handleUpdateChange={handleUpdateChange}
				updateState={updateState}
				product_id={product_id}
				setSelectedId={setSelectedId}
				setStockSelectedId={setStockSelectedId}
				handleCurrencyChange={handleCurrencyChange}
				handleCurrencyUpdateChange={handleCurrencyUpdateChange}
				productStruct={productStruct}
				otherProductStructure={otherProductStructure}
				setCopyFetch={setCopyFetch}
				productSubStructure={productSubStructure}
			/>
			<CreateProductCatalog
				isOpen={isOpen}
				size={`xl`}
				state={state}
				hideModel={hideModel}
				storeList={storeList}
				productStructure={productStructure}
				units={unitList}
				handleChange={handleChange}
				validated={validated}
				handleSubmit={handleSubmit}
				handleFileChange={handleFileChange}
				vendorList={vendorList}
				handleChangeCurrency={handleChangeCurrency}
				numberValidation={numberValidation}
				numberValidationCons={numberValidationCons}
				coverageNum={coverageNum}
				isLoading={isLoading}
				ids={ids}
				piecesNumberValidation={piecesNumberValidation}
				productSubStructure={productSubStructure}
				handleChangeColor={handleChangeColor}
				handleDeleteFile={handleDeleteFile}
			/>
		</MainWrapper>
	);
}
const mapStateToProps = ({ auth, ProductType, unit, StoreInfo, valueList, ProductCatalog }) => {
	return {
		auth, ProductType, unit, StoreInfo, valueList, ProductCatalog
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		fetchVendor: (token) => dispatch({ type: VENDOR_REQUEST, token }),
		fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
		fetchUnit: (token) => dispatch({ type: UNIT_REQUEST, token }),
		fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
		createProduct: (token, data) => dispatch({ type: PRODUCT_CATALOG_CREATE, token, data }),
		updateProduct: (token, data) => dispatch({ type: PRODUCT_CATALOG_EDIT, token, data }),
		deleteProduct: (data) => dispatch({ type: PRODUCT_CATALOG_DELETE, data }),
		changeReducerState: () => dispatch({ type: PRODUCT_CATALOG_STATE_CHANGE }),
		fetchProducts: (token, sort, page, paginate, filterState) => dispatch({ type: PRODUCT_CATALOG_REQUEST, token, sort, page, paginate, filterState }),
		updatePartial: (token, data) => dispatch({ type: PRODUCT_CATALOG_UPDATE, token, data }),
		fetchSubProductRequest: (data) => dispatch({ type: GET_SUB_PRODUCT_REQUEST, data })
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalog);